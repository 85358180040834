/* eslint-disable */
/* 当前文件对应的国际化词库版本号: 7.9.2.202503251532 */
export default {
About: "≈",
AboutUs: "会社概要",
AccordingTo12306TicketsCanOnlyBePurchasedAfterThePhoneNumberHasBeenVerified: "12306によると、電話番号が確認された後にチケットを購入できます",
AccordingToYourCompanysTravelPolicy: "貴社の規定によると、最低価格の船室フライトを選択していません",
Account: "マイHOMSOM",
AccountLogin: "アカウントログイン",
AccountName: "口座名義",
AccountNotExist: "ユーザー名が存在しない",
AccountNumber: "口座番号",
AccountNumber_1: "口座番号",
AccountProtocol: "《アカウント認証協定》",
AccountProtocol_1: "読みましたか同意します《アカウント認証協定》",
AccountProtocol_2: "お客様が提供する名前、携帯電話番号、身分証明書番号などの情報は、12306アカウントのパスワードの回収とさらなる認証を行う便利なサービスに使用されます。詳細は《アカウント認証協定》",
AccountProtocol_2_x: "お客様が提供する名前、携帯電話番号、身分証明書番号などの情報は、12306アカウントのパスワードの回収とさらなる認証を行う便利なサービスに使用されます。詳細は%a$",
AccountVerification12306: "12306アカウントの検証",
AccountVerification12306Desc: "鉄道局はチケット購入には実名登録が必要であると規定しており、以下の方法を選択して検証してください",
AccuratelyFillInTheAgeOfEachChildForSpecialOffers: "お子様の年齢を正確にご記入いただくと、最適なお部屋タイプや特典をご案内いたします。",
AContactAlreadyExistsWithTheSamePhoneNumber: "同じ電話番号を持つ連絡先が既に存在しています",
ActivityNotice: "アクティビティ通知",
ActivityNoticeDesc: "割引活動のリマインダー",
ActualPayment: "実際の支払い",
ActualTotalPrice_x: "実際の費用%a$",
Add: "追加",
AddAnotherTrip: "他の旅行を追加",
AddBusiness: "ビジネスの追加",
AddContact: "連絡先を追加",
AddCostCenter: "コストセンターの追加",
AddDay_x: "+%a$日",
AddDesignatedApprover: "指定承認者の追加",
Added: "追加済み",
AddFailed: "追加に失敗しました",
AddFlight: "別のフライトを追加",
AddFrequentContacts: "よく連絡する相手を追加",
AddFrequentTraveller: "よく旅行する利用者として追加",
AddInvoiceHeader: "請求書のヘッダーを追加",
AdditionalTaxesFees: "別途納税",
AddManually: "手動で追加",
AddPassenger: "乗客を追加",
AddPersonal: "個人用の追加",
Address: "住所",
AddressDistrict: "住所の地区",
AddReturnTrip: "帰りの旅行を追加",
AddSecurityExplain: "家族の安心のためにセキュリティを追加",
AddSecurityExplain_1: "安心して旅行し、家族の安全を守る",
AddSucceed: "追加に成功しました",
AddTiedList: "結びつけリストに追加",
AddTransitCity: "出張の途中で訪れる都市を目的地に追加",
AddTravelCard: "トラベルカードを追加",
AddTraveler: "追加 +",
AdministrativeDistrict: "行政区",
AdmissionPolicy: "入場ポリシー",
Adults: "大人",
Adults_1_x: "%a$人の大人",
Adults_Children_x_x: "%a$大人 %b$子供",
Adults_x: "大人%a$人",
AdultsDesc: ">12歳(含む)",
AfterClickingGoToPay: "「支払いに進む」をクリックした後、携帯のWeChatを開き、QRコードをスキャンして支払いを完了してください",
AfterClickingGoToPay_1: "「支払いに進む」をクリックした後、携帯のアリペイを開き、QRコードをスキャンして支払いを完了してください",
AfterSendingTextMessage: "SMSを送信した後、12306から6桁の認証コードが届きますので、そのコードを入力してください",
Age_x: "%a$歳",
Agree: "同意する",
AgreeAndContinue: "同意して続ける",
Agreement: "契約",
AgreementDesc: "契約の説明",
AgreementHotels: "契約ホテル",
AgreementHotelStarRating: "契約ホテルの星評価",
AgreementHotelTotal: "契約ホテルの合計金額",
AgreementHotelTotalRoomNight: "契約ホテルの総部屋数",
AgreementNight: "契約の宿泊数",
AirChinaPassengerBaggageDomesticTransportationGeneralConditions: "中国国際航空旅客手荷物国内輸送一般条件2017年版(中国語版)",
AirfareYouSearchedFor: "検索した航空券",
AirlineList: "航空会社リスト",
AirlinePassengerMaintenance: "航空会社の乗客メンテナンス中",
AirLowestFareAcceptance: "最低運賃受け入れ率",
Airport: "空港",
AirportDropOff: "送迎",
AirportDropOff_x: "%a$人送迎",
AirportPickup: "迎え",
AirportPickup_x: "%a$人迎え",
AirportStation: "空港駅",
AirRouteAnalysis: "航路分析(エコノミークラスのみ)",
AirRouteAndCarrierAnalysis: "航路とキャリアの分析",
AirTicketBookingInstructions: "航空券予約のご案内",
AirTicketConsumptionInDifferentCities: "各都市の航空券消費状況",
AirTickets: "航空券価格",
Aisle: "通路",
Alipay: "アリペイ",
AliPay: "アリペイ支払い",
All: "すべて",
AllBookings: "すべての予約",
AllCabin: "すべての船室",
AllCabins: "すべての船室",
AllCollection: "すべてのコレクション",
AllCompanyPayment: "全社支払い",
AllLoaded: "すべての読み込み完了",
AllOrdersForRecentOneMonth: "直近6ヶ月のすべての注文を表示",
AllOrdersForRecentOneYear: "直近1年間のすべての注文を表示",
AllOrdersForRecentOneYear_1: "直近1年間のすべての注文を表示",
AllOrdersForRecentSixMonths: "直近6ヶ月のすべての注文を表示",
AllSelected: "すべて選択",
AllTime_x: "時間はおよそ %a$",
AllTime_x_x: "%a$、時刻は約%b$",
AlreadyHave12306Account: "既に12306アカウントをお持ちです",
AlreadySetting: "設定済み",
Amount: "金額",
Amount_1: "金額(税込)",
AmountOfBillForTheCurrentPeriod: "当期の請求金額",
AmountOfConsumption: "消費金額",
AmountPaidInThisPeriod: "当期の支払済金額",
AmountPayableInTheCurrentPeriod: "当期の支払予定金額",
And: "と",
AndroidPersonalizedRecommendation: "カスタマイズ推奨",
AndroidPersonalizedRecommendationDesc: "カスタマイズされた推奨機能を提供し、予約体験を向上させる",
AndroidSettingPrivacy: "プライバシー設定",
Announcement: "お知らせ",
AnnouncementInformation: "お知らせ情報",
Any: "どれでも",
AnyCity: "どの都市でも",
Appearance: "外観モード",
Applicant: "申請者",
ApplicantForRefund: "払い戻し申請者",
Application: "申請",
ApplicationContent: "申請内容",
ApplicationContent_x: "申請内容：%a$",
ApplicationDate: "申請日",
ApplicationForApproval: "承認申請",
ApplicationForBook: "リクエストフォーム予約",
ApplicationFormInformation: "申請書情報",
ApplicationInformation: "申請書情報",
ApplicationIntlFlight: "国際航空券の申請",
ApplicationIntlHotel: "海外ホテルの申請",
ApplicationNumber: "申請書番号",
ApplicationToReservedNoData: "予約待ちの申請書はありません",
Apply: "申請",
ApplyForRefund: "キャンセル申請",
ApplyRefund: "申し込み",
ApplyReschedule_1: "変更申請",
ApplyTime: "申請時間",
ApplyTravel: "出張申請",
ApplyTravelConfirm: "出張申請の確認",
ApplyTravelNeeds: "出張の要求",
ApplyTravelSegment: "出張セグメント",
ApplyTravelTime: "出張日",
ApproachingTakeoff: "出発が近づいています",
ApprovalExpired: "承認期限切れ",
ApprovalFail: "承認失敗",
ApprovalFlow: "承認フロー",
ApprovalInformation: "承認情報",
ApprovalInformationDesc: "承認状況のリマインダー",
ApprovalRejection: "承認拒否",
Approvals: "承認",
Approvals_1: "私の承認",
ApprovalSetting: "承認設定",
ApprovalStatus: "承認の状態",
ApprovalSubmitted: "承認が提出されました",
ApprovalTemplate: "テンプレート",
ApprovalTemplate_1: "承認テンプレート",
ApprovalTemplateDetails: "テンプレートの詳細",
ApprovalTimeoutNoticeTime: "承認のタイムアウト通知時間：%a$分",
ApprovalType: "承認のタイプ",
ApprovalType_1: "承認の種類",
ApprovalWhitelist: "承認ホワイトリスト",
Approved: "承認済み",
ApprovedCanceled: "申請キャンセル済み",
ApprovedPass: "承認済み",
Approver: "承認者",
ApproversHaveBeenBound: "承認者がバインドされました",
ApproxPay: "おおよそ",
Area: "面積",
AreYouSureToAddThisAsAFrequentTraveler: "この人物を頻繁な旅行者として追加しますか？",
AreYouSureToCancelTheOrder: "注文をキャンセルしてもよろしいですか？",
AreYouSureToCancelUnableToSave: "キャンセルすると、このページの情報は保存されません。本当にキャンセルしますか？",
AreYouSureToChange: "変更しますか？",
AreYouSureToChangeTheCurrency: "通貨を変更しますか？",
AreYouSureToChangeTheLanguage: "言語を変更しますか？",
AreYouSureToChangeYourFlightToTheCheapestFlight: "最も安いフライトに航空券を変更しますか？",
AreYouSureToDelete_x: "%a$を削除してもよろしいですか？",
AreYouSureToDeleteTheApprover: "指定の承認者を削除してもよろしいですか？",
AreYouSureToDeleteTheCertificate: "証明書を削除してもよろしいですか？",
AreYouSureToDeleteTheEmployeeFromTheWhitelist: "従業員をホワイトリストから削除してもよろしいですか？",
AreYouSureToDeleteTheRC: "RCを削除してもよろしいですか？",
AreYouSureToDeleteTheSelectedCollection: "選択したコレクションを削除してもよろしいですか？",
AreYouSureToDeleteThisCustom: "このカスタム項目を削除してもよろしいですか？",
AreYouSureToDeleteThisEmployeeFromTheTemplate: "この従業員をテンプレートから削除してもよろしいですか？",
AreYouSureToPayForThisOrder: "この注文を支払うことを確定しますか？",
AreYouSureToRemove_x: "%a$を削除してもよろしいですか？",
AreYouSureToRemoveItFromTheCostCenterList: "コストセンターリストから削除してもよろしいですか？",
AreYouSureToRemoveItFromTheDepartmentsList: "部門リストから削除してもよろしいですか？",
AreYouSureToRemoveItFromTheEmployeeList: "従業員リストから削除してもよろしいですか？",
AreYouSureToRemoveItFromTheFrequentTravellerList: "頻繁な旅行者リストから削除してもよろしいですか？",
AreYouSureToRemoveItFromTheNonEmployeeList: "本当に部外者リストから除外したいのか？",
AreYouSureToRescheduled: "日程変更してもよろしいですか？",
AreYouSureToSeat: "座席を選択してもよろしいですか？",
AreYouSureToSubmitAndPayForTheOrder: "注文を提出し、支払いを行ってもよろしいですか？",
AreYouSureToSubmitYourOrder: "注文を提出してもよろしいですか？",
AreYouSureToTurnOffFaceIDLogin: "Face ID ログインをオフにしてもよろしいですか？",
AreYouSureToTurnOffTouchIDLogin: "Touch ID ログインをオフにしてもよろしいですか？",
AreYouSureYouWantToDeleteThisAccount: "このアカウントを削除してもよろしいですか？",
AreYouSureYouWantToGiveUpTheInformationChange_x: "%a$の情報変更を放棄してもよろしいですか？",
AreYouSureYouWantToLogout: "このアカウントからログアウトしてもよろしいですか？",
AreYouSurYouWantToDeleteThisAccount: "このアカウントを削除してもよろしいですか？",
AreYouSurYouWantToLogout: "このアカウントからログアウトしてもよろしいですか？",
AreYouSurYouWantToUntieThisAccount: "このアカウントの紐付けを解除してもよろしいですか？",
Arrival: "到着",
ArrivalCity: "到着都市",
ArrivalCityAddress: "到着都市の住所",
ArrivalCityAddressDesc: "出張途中の都市はすべて目的地に追加する必要があります",
ArrivalCityOptions: "到着都市のオプション",
ArrivalDate: "到着日",
ArrivalEarliest: "到着(最も早い)",
ArrivalLatest: "到着(最も遅い)",
ArrivalStation: "到着駅",
ArrivalStation_1: "到着駅",
ArrivalTerminal: "到着ターミナル",
ArrivalTime: "到着時刻",
ArrivalTime_1: "到着時間",
ArrivalTime_2: "到着駅/時間",
ArrivalTimeDepartureDate: "到着時間/出発日",
ArrivalTimeIsLocalTime: "到着時刻は現地時間です",
Arrive: "到着",
ArriveAddress: "到着地の住所",
Associated12306Account: "関連付けられた12306アカウント",
AtNoonToday: "今日の正午",
AToB_x_x: "%a$から%b$まで",
AttachFileUpload_x: "さらに%a$をパスする可能性がある",
AttachFileUploadExplain_1: "·10ファイルまでアップロード可能、各ファイルのサイズは8Mを超えないこと",
AttachFileUploadExplain_2: "·サポートされているファイルタイプはimg、word、ppt、excel、pdfです",
AUD: "オーストラリアドル",
Authentication: "認証",
AuthenticationProtocol: "《認証プロトコル》",
AuthenticationProtocol_1: "已阅读并同意《認証プロトコル》",
Available: "利用可能",
Available_x: "利用可能な%a$",
AvailableIntegral: "利用可能な積分",
AvailableTickets: "利用可能なチケット",
AverageBusinessTravelFare: "ビジネス旅行の平均運賃",
AverageDiscount: "平均割引",
AverageDomesticDiscount: "国内の平均割引(エコノミークラス)",
AverageHotelPrice: "ホテルの平均価格",
AverageMileagePrice: "マイレージの平均価格(エコノミークラス)",
AverageNumberOfDaysBookedInAdvance: "平均予約日数",
AveragePrice: "平均価格",
AveragePriceOfBusinessTravelAgreement: "ビジネス旅行契約の平均価格",
AveragePriceOfBusinessTravelMileage: "ビジネス旅行の平均マイレージ価格",
AverageTicketPrice: "平均チケット価格",
AverageTrainTicketPrice: "平均列車チケット価格",
AVerificationMessagePleaseEnterTheVerificationCodeYouReceived: "確認メッセージが送信されました。受け取った確認コードを入力してください。",
Avg: "平均",
AvgTicketPriceOfMileage: "マイレージの平均チケット価格",
AwayFrom: "離れて",
B: "B",
Baby: "ベビー",
Baby_1_x: "%a$ ベビー",
Baby_x: "ベビー%a$",
BabyDesc: "14日(含む)-2歳(含まない)",
BabyNonBookable: "乳児の予約不可",
BabyNonSupportBookable: "乳児のチケット予約については、旅行コンサルタントにお問い合わせください",
BabyReQuery: "このフライトでは乳児の予約はできません。乳児の搭乗が必要な場合は、利用可能なフライトを再検索してください",
BabyTooSmallNonBookable: "14日未満の乳児は予約できません",
BabyWith: "乳児同伴",
BabyYearsOld: "14日 - 2歳",
Back: "戻る",
BackFlight: "帰りのフライト",
BackModify: "修正に戻る",
BackTag: "戻る",
BackToHome: "ホームに戻る",
BackToHotelList: "ホテルリストに戻る",
BackToLogin: "ログインに戻る",
BackWeek_x: "%a$に戻る",
Baggage: "手荷物",
BaggageAllowance_x: "手荷物の許容量：%a$",
BaggagePolicy: "手荷物の規定",
Balance: "残高",
Bank: "銀行",
BankAccount: "口座",
BankCardInformation: "クレジットカード情報",
BasicInformation: "基本情報",
BasicInformationIsIncompletePleaseEdit: "基本情報が不完全です。編集してください",
BasicInformationIsIncompletePleaseEdit_x: "基本情報が不完全です。%a$を編集してください",
Batch: "一括管理",
BatchPass: "一括承認",
BecomeDue_x: "%a$ 到期",
BedType: "ベッドタイプ",
BedWide_x_x: "幅%a$ x %b$",
BeijingTime: "ぺきんじかん",
BeingApprovalByTheFlightCompany: "航空会社の承認待ち",
BeingVerified: "検証中です",
BelongCity: "所属市",
Below: "下",
BeOverdueByCarPleaseCheckAgain: "車両情報が変更されている可能性があります。再度確認してください",
BeOverdueByFlightPleaseCheckAgain: "フライト情報が変更されている可能性があります。再度確認してください",
BeOverdueByHotelPleaseCheckAgain: "ホテル情報が変更されている可能性があります。再度確認してください",
BeOverdueByTrainPleaseCheckAgain: "列車情報が変更されている可能性があります。再度確認してください",
BigBed: "ダブルベッド",
BillEndDate: "請求書終了日",
BillGrouping: "請求書グループ化",
BillingPeriod: "請求期間",
BillStartDate: "請求書開始日",
Bind: "今すぐバインド",
BindFailed: "バインドに失敗しました",
Binding: "バインディング",
BindingCTrip: "携程会員のバインド",
BindingCTripDesc: "お客様が入力された携帯電話番号を使用して、携程の個人会員としてバインドします。まだ携程の会員でない場合、恒順出張はその携帯電話番号を使用して、お客様のために携程の会員登録を行います。",
BindingCTripPrivacy: "携程の利用規約とプライバシーポリシーに同意する",
BindingCustomItems: "カスタムアイテムのバインド",
BindingEmployees: "従業員のバインド",
BindingHuazhu: "華住会員のバインド",
BindingHuazhuDesc: "お客様の情報を華住の関連ユニットとバインドします。まだ華住の会員でない場合、恒順出張はその情報を使用してお客様のために華住の会員登録を行います。",
BindingHuazhuPrivacy: "華住の利用規約とプライバシーポリシーに同意する",
BindingLogin: "ワンクリック認証バインディング",
BindingMeituan: "美団会員のバインド",
BindingMeituanDesc: "お客様の情報を美団の関連ユニットとバインドします。まだ美団の会員でない場合、恒順出張はその情報を使用してお客様のために美団の会員登録を行います。",
BindingMeituanPrivacy: "美団の利用規約とプライバシーポリシーに同意する",
BindingStatus: "バインディングの状態",
BindMailbox: "メールボックスのバインド",
BindMembershioFor_X: "製本 %a$ 会員カード",
BindMembersToEnjoyLowerPrices: "会員登録でより低価格をお楽しみください",
BindPerson_x: "%a$人がバインドされました",
BindSuccessfully: "バインドに成功しました",
BlankImport: "空のインポート",
Book: "予約",
Book_1: "チケット予約",
Book_2: "今すぐ予約",
Book_3: "予約する",
BookAllStaff: "全従業員のサブスクリプション",
BookCar: "車両の予約",
BookDesignated_x: "指定された%a$のサブスクリプション",
BookDesignatedDepart: "指定された部署のサブスクリプション",
BookDesignatedStaff: "指定された従業員のサブスクリプション",
BookDomesticFlights: "国内フライトの予約",
BookDomesticHotel: "国内ホテルの予約",
Booker: "予約者",
BookFinished: "予約完了",
BookFlight: "フライトの予約",
BookHotel: "ホテル予約",
BookInAdvance: "事前予約",
Booking: "予約",
BookingDaysInAdvance: "事前予約日数",
BookingInformation: "予約情報",
BookingInstructionsAndInstructionsForReturning: "予約の注意事項とキャンセルポリシー",
BookingLevel: "予約レベル",
BookingMail: "予約メール",
BookingMethods: "予約方法",
BookingRange: "予約範囲",
BookingSMS: "予約SMS",
BookingSource: "予約元",
BookingSucceed: "予約成功",
BookingTheBus: "バスの予約",
BookingTheCar: "車両の予約",
BookingTheMeals: "食事の予約",
BookInternationalFlights: "国際フライトの予約",
BookInternationalHotel: "海外ホテルの予約",
BookOnlySelf: "ご本人様のみのご予約となります",
BookPermissions: "サブスクリプション権限",
BookTheLowestLogicalFare: "最低運賃の予約",
BookTrain: "列車の予約",
BookType: "予約タイプ",
Bound: "バウンド",
Bound_1: "従業員の紐付け済み",
BoundCustomItems: "カスタムアイテムのバウンド",
BoundEmployees: "従業員のバウンド",
Brand: "ブランド",
Breakfast: "朝食込み",
Breakfast_1: "朝食",
BrowserOpens: "ブラウザが開きます",
Budget: "予算",
Budget_1_x: "%a$ 予算",
Budget_x: "予算 %a$",
BudgetAmount: "予算金額",
BulkRejection: "一括拒否",
Business: "ビジネス",
Business_x: "%a$ (ビジネス)",
BusinessCenter: "ビジネスセンター",
BusinessClass: "ビジネスクラス",
BusinessConsultant: "ビジネスコンサルタント",
BusinessDistrict: "ビジネス街",
BusinessDistrictInfrastructureBrandEtc: "ビジネス街/インフラ/ブランドなど",
BusinessTravel: "ビジネストラベル",
BusinessTravelScale: "ビジネス旅行規模(月次)",
BusinessTrip: "ビジネス利用",
BusinessType: "業務タイプ",
BusinessType_1: "業務タイプ",
BusinessUnit: "ビジネスユニット",
BusOrderConfirmation: "バスチケット注文確認",
BusOrderDetails: "バスチケットの詳細",
BusRank: "バスランク",
BusRefundOrderConfirm: "バスチケットの払い戻し注文確認",
BusStation: "バス停",
BusTicket: "バスチケット",
BusTicketOrder: "バスチケット注文",
By: "経由",
By_x: "%a$ によって",
ByMail: "メールで",
ByPhoneNumber: "電話番号で",
Cabin: "キャビン",
CabinInformationHasBeenUpdated: "船室情報が更新され、注文の一部の内容が変更される可能性があります。正確性を確保するために注文を再記入してください",
CabinLevel: "キャビンレベル",
CabinType: "キャビンタイプ",
CAD: "カナダドル",
Calendar: "カレンダー",
CallCar: "車を呼ぶ",
CallingForYou: "あなたのために呼び出しています...",
CallPhone: "電話をかける",
CallThePolice: "110警察に電話する",
Camera: "カメラ",
CanAccommodateUpToXPeople_x: "最大%a$人まで宿泊可能",
CanAccommodateXPeople_x: "最大%a$人まで宿泊可能",
Cancel: "キャンセル",
Cancel_1: "今はアップグレードしない",
Cancel_2: "管理をキャンセル",
CancelAnyway: "やっぱりキャンセル",
Canceled: "キャンセル済み",
CancelFailed: "キャンセルに失敗しました",
CancelFee: "キャンセル料金",
CancelForFree: "無料キャンセル",
CancelInformation: "キャンセル情報",
CancelInquiry: "キャンセルの問い合わせ",
Cancelling: "キャンセル中",
CancelOrder: "注文をキャンセルする",
CancelReason: "取消理由",
CancelRefund: "払い戻しをキャンセル",
CancelRules: "キャンセル規則",
CancelSucceed: "キャンセルが成功しました",
CannotBeCancelled: "キャンセル不可",
CanNotSupportedFileType: "このファイルタイプはサポートされていません。もう一度選択してください",
CanOnlySelectUpToXGuest_x: "%a$部屋につき最大1人の入居者しか選択できません",
CanOnlySelectUpToXpassengers_x_x: "%a$ %b$までしか選択できません",
CanPeopleStaying_x: "居住可能%a$人",
Car: "車",
CarAirport: "空港送迎",
CarApplication: "車の申請",
CarbonEmission: "炭素排出量",
CarbonOffset: "カーボンオフセット(樹木の植樹)",
CardholderInformation: "カード会員情報",
CarDistance_x: "%a$キロメートル走行",
CardNumber: "カード番号",
CardType: "カードタイプ",
CarDuration_x: "合計所要時間：%a$分",
CarFare: "車の運賃",
CarOrder: "車の注文",
CarOrderConfirmation: "車の注文確認",
CarPlatform: "カープラットフォーム",
CarRank: "車のランク",
Carrier: "キャリア",
Carrier_1: "キャリア",
CarrierAnalysis: "キャリア分析",
CarriersItineraries: "キャリアと行程",
CarScene: "カーシーン",
CarServiceTime: "カーサービス時間",
CarTrain: "駅の送迎",
CarType: "車のタイプ",
CellphoneNumber: "携帯電話番号",
CertificateInformation: "証明書情報",
Change: "変更",
Change_1: "変更",
Change_2: "フライトの変更",
Change_3: "12306アカウントの切り替え",
ChangeDetails: "日程変更明細",
ChangeDetails_1: "変更の詳細",
ChangeFailed_1: "変更に失敗しました",
ChangeFee: "変更手数料",
ChangeFlight: "フライトの変更",
ChangeFlightSchedule: "振替便",
ChangeInstructions: "変更手順",
ChangeMobileNumber: "携帯電話番号の変更",
ChangeMobileNumber_1: "携帯電話番号の変更",
ChangePassword: "パスワードの変更",
ChangePolicy: "ポリシーの変更",
ChangeRate: "変更率",
ChangeSchedule: "スケジュールの変更",
ChangeSegment: "トリップの変更",
ChangeStation: "到着駅の変更",
ChangeStationTitle: "駅の変更のお知らせ",
ChangeStationWarn_1_x_x: "あなたの出発駅が%a$から%b$に変更されました。続行しますか？",
ChangeStationWarn_2_x_x: "あなたの到着駅が%a$から%b$に変更されました。続行しますか？",
ChangeStationWarn_x_x_x_x: "あなたの出発駅が%a$から%b$に変更され、到着駅が%c$から%d$に変更されました。続行しますか？",
ChangeSucceed: "変更に成功しました",
ChangeSuccessFailed: "変更に失敗しました",
ChangeSuccessfully: "変更に成功しました",
ChangeTheNumberOfPeople_x: "選択した搭乗者に変更がありました。必要に応じて%a$に調整します。必要に応じて変更することができます。",
ChangeTheNumberOfRoomsAndPeople_x_x: "選択した宿泊者数に変更があり、%a$室%b$人に調整します。もしあなたのニーズを満たしていなければ、自分で部屋の宿泊者数を変更することができます",
ChangeTo_x: "%a$に変更",
ChangeYourFlightAndContinueDeparting: "フライトを変えて、出発を続けます~",
Changing: "変更中",
Charge: "料金",
ChargebackDateTime: "キャンセル申請日時",
ChargebackDetails: "キャンセルの詳細",
ChargebackNumber: "キャンセル番号",
ChargebackNumber_x: "返品番号%a$",
ChargebackRoom: "キャンセルされた部屋数",
ChargebackTime: "キャンセル日",
ChargeByAirlineRefundRules: "航空会社の払い戻し規則に基づいて請求されます",
ChargedAccordingToTheCorrespondingAirlineTicketRegulations: "該当航空会社のチケット規定(NFD)に基づいて料金が請求されます",
CheapestFlight: "最も安いフライト",
CheapFlights: "格安航空券",
Check: "チェック",
CheckerInformation: "ゲスト情報",
CheckIn: "チェックイン",
CheckIn_x: "%a$ C/I",
CheckInCheckOut_x_x_x: "チェックイン：%a$、チェックアウト：%b$、%c$泊",
CheckInCity: "チェックイン都市",
CheckInCounterIsClosedNotBookedYet: "チェックインカウンターは閉まっています。まだ予約されていません",
CheckInDate: "チェックイン日",
CheckInformation: "情報の確認",
CheckingForTheLatestPriceForYou: "最新の価格を確認中です。お待ちください...",
CheckInHotel: "ホテルのチェックイン",
CheckInRules: "チェックインルール",
CheckInSeat: "当番選択席",
CheckInToCheckoutNight_x_x_x: "チェックインからチェックアウト：%a$ ～ %b$、%c$ 泊",
CheckInToCheckoutNight_x_x_x_x_x: "チェックイン：%a$ %b$、チェックアウト：%c$ %d$、%e$泊",
CheckInToCheckoutNightAndRoom_x_x_x_x: "チェックインからチェックアウト：%a$ ～ %b$、%c$ 泊/%d$ 部屋",
CheckInToCheckoutNightAndRoomAndRoom_x_x_x_x_x: "%a$ - %b$ · %c$晚%d$間 · %e$",
CheckOrder: "注文を確認する",
CheckOut: "チェックアウト",
CheckOut_x: "%a$ C/O",
CheckOutDate: "チェックアウト日",
CheckTheCancellationRules: "キャンセル規則",
CheckTheDifference: "料金の詳細を表示するにはクリックしてください",
CHF: "スイスフラン(CHF)",
ChildOfAge: "子供の年齢",
Children: "子供",
Children_1_x: "%a$人の子供",
Children_x: "%a$人の子供",
ChildrenAndBabyExplain: "子供と乳幼児の予約手順",
ChildrenDesc: "2歳(含む)-12歳(含まない)",
ChildrenNonBookable: "子供の予約不可",
ChildrenNonSupportBookable: "子供のチケットの予約については、旅行コンサルタントにお問い合わせください",
ChildrenReQuery: "このフライトでは子供の予約はできません。子供と一緒に旅行する場合は、予約可能なフライトを再検索してください。",
ChildrenWith: "子供同伴",
ChildrenYearsOld: "2歳から12歳",
China: "国内",
China_1: "国内(香港、マカオ、台湾を含む)目的地",
China_3: "中国",
ChinaRegion: "国内(香港、マカオ、台湾を含む)",
ChinaSouthernAirlinesNewRegulations: "中国南方航空の新しい規則により、乗客は中国南方航空のアプリまたはWeChatを通じて自分のチケットを払い戻すか変更することができます。払い戻し金は直接受け取ることができます。乗客が中国南方航空に直接払い戻しを要求した場合、弊社は引き続き元のチケット料金を貴社に対して全額請求いたします。リスクを回避するため、適切な監督を行ってください。",
ChineseName: "中国名",
Choose: "せんたく",
Choose_x: "%a$を選択",
ChooseAirport: "空港を選択",
ChooseAtLeastXPeople_x: "最低%a$人を選択してください",
ChooseAtMostContact_x: "最大%a$人まで連絡先を選択できます",
ChooseBU: "BUを選択",
ChoosePhotos: "写真を選択",
ChooseSnatchSeat_1: "高速鉄道 / 新幹線 / 通勤電車の座席を選択する",
ChooseSnatchSeat_2: "普通急行/急行/その他の座席を選択してください",
ChooseTheLowestPriceToSave: "最低価格で選択して節約する",
ChooseTheReason: "理由の選択",
ChooseTrainStation: "駅を選択",
ChooseUpToXPeople_x: "最大%a$人まで選択できます",
CI: "チェックイン",
CICO: "チェックイン/チェックアウト日",
CICO_1: "入退店日",
City: "都市",
City_1: "出発地/目的地による",
CityAnalysis: "都市分析",
ClassAnalysis: "クラス分析",
ClassHotel: "クラスホテル",
ClassRoomTypeSeat: "クラス/ルームタイプ/シート",
Clear: "クリア",
ClearCache: "キャッシュをクリア",
ClearHistory: "履歴をクリア",
ClickLogin: "ログインするにはクリック",
ClickNextToIndicateThatYouHaveAcceptedAndAreAwareOfTheRefundPolicyForThisTicket: "次へをクリックして、このチケットの払い戻しポリシーに同意し、認識していることを示してください",
ClickRefresh: "更新するにはクリック",
ClickShrink: "クリックして縮小する",
ClickToResend: "再送信するにはクリック",
ClickToSetTheAddress: "住所を設定するにはクリック",
ClickToView: "クリックして表示",
Close: "閉じる",
Closed: "閉じた",
CloudBill: "クラウド請求書",
CloudQuickPay: "クラウドクイックペイ",
CN: "中",
CNYStart_x: "%a$から",
CO: "チェックアウト",
CO2Emissions: "CO2排出量",
CodeShare: "コード共有",
ComfortHotel: "快適なホテル",
ComfortHotel_1: "快適",
CommentsDetails: "レビュー詳細",
CommodityDetails: "商品の詳細",
CommonOperations: "共通の操作",
Company: "会社",
CompanyAndPersonalPayment_1_x_x: "会社支払い %a$，個人支払 %b$",
CompanyAndPersonalPayment_x_x: "会社：%a$ 個人：%b$",
CompanyInfo: "会社情報",
CompanyInvoiceHeader: "会社の請求書ヘッダー",
CompanyName: "会社名",
CompanyPayment: "会社の支払い",
CompanyPaymentPrepay: "会社の支払い(前払い)",
CompanyTel: "会社の連絡先電話番号",
ComplaintsSuggestions: "苦情/提案",
Completed: "支払い完了",
CompleteVerification: "検証完了",
Confirm: "確認",
Confirm_1: "確認",
Confirm_2: "選択済み",
Confirm_3: "確定",
ConfirmationOrder: "確認書",
ConfirmDelete: "削除を確認",
ConfirmDeparture: "[終了を確定]",
Confirmed: "確認済み",
ConfirmedCallCar: "確認済み、すぐに車を呼びます",
ConfirmedCheckInTimeAtTheCounterCanBeBooked: "カウンターでチェックイン時間が確認されました。予約を続行します",
ConfirmImmediately: "すぐに確認",
Confirming: "確認中",
ConfirmMail: "確認メール",
ConfirmPay: "支払いを確認する",
ConfirmRules: "確認規則",
ConfirmSelectedTraveler_x: "%a$名様の当選確認",
ConfirmSelection: "選択を確認する",
ConfirmSMS: "SMSの確認",
ConfirmTheChanges: "変更を確認する",
ConfirmTheChanges_1: "変更を確認する",
ConfirmTheNewPassword: "新しいパスワードを確認する",
ConfirmTheNewPassword_1: "パスワードの確認",
ConfirmTheRemovalOfTheirDocuments: "ドキュメントの削除を確認しますか？",
ConfirmToResendApprovalInformation: "承認情報を再送信しますか？",
ConfirmUnbinding: "解除の確認？",
ConfirmUpdate: "更新を確認する",
Consignee: "荷受人",
Consultant: "コンサルタント",
ConsumptionOfCarsInDifferentCities: "異なる都市での車の消費",
ConsumptionOfTrainTicketsInEachCity: "各都市の列車チケットの消費",
ContactCustomerService: "カスタマーサービスに連絡する",
ContactDetails: "連絡先詳細",
ContactDriver: "ドライバーに連絡する",
ContactEmailFormatIsIncorrect_x: "連絡先%a$のメールアドレスの形式が正しくありません",
ContactHotel: "ホテルに連絡する",
ContactInfo: "連絡先",
ContactInformation: "連絡先情報",
ContactInformationOfExclusiveConsultant_x: "専属コンサルタント%a$の連絡先情報",
ContactMobileFormatIsIncorrect_x: "連絡先%a$の携帯番号の形式が正しくありません",
ContactName: "連絡先名",
ContactNumber: "連絡先番号",
ContactNumberColon: "連絡先番号：",
ContactPhoneNumber: "連絡先携帯番号",
ContactPhoneNumberCannotBeDuplicated: "連絡先の携帯番号は重複できません",
Contacts: "連絡先",
Contacts_x: "連絡先%a$",
ContactTheEmailNotificationHasBeenTurnedOn_x: "連絡先%a$はメール通知機能を有効にしており、メールアドレスは空にできません！",
Content: "内容",
ContentIsEmpty: "内容は空です",
Continue: "続ける",
ContinueBooking: "予約を続ける",
ContinueBooking_1: "予約を続ける",
ContinueBookingWithoutRescheduling: "予約変更不可、予約継続",
ContinuetoBook: "元のフライトを予約し続ける",
ContinueToChange: "改訂を続ける",
Copy: "コピー",
Copy_1: "コピー",
CopyInvitationLink: "招待リンクをコピー",
CopyLink: "リンクのコピー",
CopySuccess: "クリップボードにコピーしました",
CopyTheLink: "リンクをコピー",
CopyToClipboard: "クリップボードにコピー",
CorporateTourism: "企業旅行",
CostCenter: "コストセンター",
CostCenterName: "コストセンター名",
CreationDate: "作成日",
CreationTime: "作成時間",
Credential: "証明書",
CreditCard: "クレジットカード",
CreditCardGuarantee: "クレジットカードの保証",
CreditGuarantee: "信用保証",
Cross: "过",
Currency: "通貨",
CurrencyExplain_x: "通貨：%a$ おとな 1 名あたりの料金",
CurrencySelectedIncorrectly: "通貨が正しく選択されていません。もう一度選択してください。",
CurrencySetting: "通貨設定",
Current: "現在",
CurrentAdvancePayment: "現在の前払い金",
CurrentCityNoCarService: "現在、この都市ではこの時間帯にカーサービスは利用できません。",
CurrentIntergral: "現在のポイント",
CurrentLxd: "現在の利用可能な旅行ビーンズ",
CurrentlyBusiness: "現在はビジネスの問い合わせのみ可能です",
CurrentlyOnlineInsuranceOnlySupportsTheFirstSegment: "現在、オンライン保険は最初のセグメントの購入のみをサポートしています。",
CurrentlyOnlyDomesticAirTickets: "現在、国内航空券のみサポートしています。航空会社の旅行ポリシーに違反している理由を選択してください。",
CurrentlyPrivate: "現在、個人の問い合わせのみ可能です。",
CurrentMobile: "現在の携帯電話番号：",
CurrentOrderGeneratedOrder_x: "現在、現在の注文から %a$ 件の注文が生成されました。",
CurrentOrderGeneratedOrderYouNeedToPayInTurn_x: "現在、現在の注文から %a$ 件の注文が生成されました。順番に支払う必要があります。",
CurrentPageDownload: "現在のページをダウンロード",
CurrentPassword: "現在のパスワード",
CurrentPosition: "現在の位置(参考のみ)",
CurrentVersion: "現在のバージョン",
CustomerChoosesThePrice: "お客様が価格を選択する",
CustomerExperienceFirst: "顧客体験第一",
CustomerServiceSupport: "顧客サービスサポート",
CustomItems: "カスタムアイテム",
CustomizationsBound: "カスタマイズがバインドされました",
CustomUseLxd: "旅行ビーンのカスタム使用",
D: "始",
Dark: "ダークモード",
DataUpTo: "データ更新日時",
Date: "日付",
DateIsTheLocalDateOfDepartureCity: "選択した日付は出発都市の現在の日付です。",
DateOfBirth: "生年月日",
DateOptions: "日付のオプション",
Days_1_x: "+%a$ 日",
Days_x: "%a$ 日",
DeductionLxd: "控除",
DeductionRequired: "要控除金",
Default: "デフォルト",
Delete: "削除",
Delete_1: "削除",
DeleteSuccessFailed: "削除に失敗しました",
DeleteSuccessful: "削除に成功しました",
DeliveryAddress: "配送先住所",
DeliveryAfterSuccessfulBooking: "予約成立時に受け取る",
DeliveryInfo: "配送情報",
DeliveryMethod: "配送方法",
DepartingColon: "出発:",
Department: "部署",
DepartmentName: "部署名",
Departure: "出発",
Departure_1: "乗車場所",
Departure_x: "%a$ から出発",
DepartureCity: "出発都市",
DepartureCityAddress: "出発地",
DepartureCityAndArrivalCityCannotBeSame: "出発都市と到着都市は同じにすることはできません",
DepartureCityAndArrivalCityCannotBeSame_x: "出発都市と到着都市は%a$レグで同じにすることはできません",
DepartureCityOptions: "出発都市のオプション",
DepartureDate: "出発日",
DepartureDateLocation: "出発日(現地時間)",
DepartureDateOptions: "出発日のオプション",
DepartureEarliest: "最も早い出発",
DepartureEarliest_1: "出発(最も早い順)",
DepartureLatest: "最も遅い出発",
DepartureLatest_1: "出発(最も遅い順)",
DepartureStation: "出発駅",
DepartureTerminal: "出発ターミナル",
DepartureTime: "出発時刻",
DepartureTime_1: "出発駅/時間",
DepartureTime_2: "出発時間",
DepartureTime_3: "出発時間帯",
DepartureTimeCheckInDate: "出発時刻/チェックイン日",
DepartureWeek_x: "%a$出発",
DesignateApprover: "承認者の指定",
Destination: "目的地",
DestinationOptions: "目的地のオプション",
Detail: "詳細",
Details: "詳細",
Details_1: "詳細を表示",
DetailsOfThisMonth: "今月の詳細",
DidiDriverIsComing: "ドライバーが来ています",
DifferentFromTheDepartingAirport: "出発空港と異なる",
DifferentFromTheOriginalScheduledArriveAirport: "空港到着が当初の旅程と異なる",
DifferentFromTheOriginalScheduledDepartureAirport: "当初の旅程と異なる出発空港",
DifferentFromTheOriginalScheduledDepartureAndrArriveAirport: "当初の旅程と異なる出発・到着空港",
DiffPrice: "価格の差",
Dimension: "分析次元",
Dimension_1: "多次元分析",
Diner: "食事者",
DinerDetail: "食事者の詳細",
DinerInfomation: "食事者情報",
DinerName: "食事者の名前",
Dingtalk: "DingTalk",
DingTalkByAccount: "DingTalkアカウント",
DingTalkByUser: "DingTalk社員バインディング",
Direct: "直行",
DirectFlightFirst: "直行便優先",
DirectionsForUse: "使用方法",
Disagree: "同意しない",
DiscountDistribution: "割引の分布",
DiscountInterval: "割引間隔",
DisplayTicketPrice: "チケット価格を表示",
DistanceCenter_x_x: "市中心からの距離 %a$·%b$",
DistanceFromYou_x: "あなたからの距離 %a$",
DistanceLowToHigh: "距離(小から大)",
DistanceNearToFar: "距離(近から遠)",
DistanceToDestination_x: "目的地までの距離 %a$",
DocumentInformationVerificationFailed: "文書情報の検証に失敗しました",
DocumentSignatory: "文書の署名国",
DomAverageDiscount: "国内平均割引(エコノミークラスのみ)",
DomesticFlights: "国内航空券",
DomesticFlights_1: "国内航空券(香港・マカオ・台湾地区を含まない)",
DomesticFlightsApplication: "国内航空券の申請",
DomesticFlightsOrderConfirmation: "国内航空券の注文確認",
DomesticHotel: "国内ホテル",
DomesticHotel_1: "国内ホテル(香港・マカオ・台湾地区を含む)",
DomesticHotelApplication: "国内ホテルの申請",
DomesticHotelOrderConfirmation: "国内ホテルの注文確認",
DomesticHotelOrders: "国内ホテルの注文",
DomesticServicePhone: "国内サービスホットライン",
DomesticTicketChange: "国内航空券の変更",
DomesticTicketChangeApplication: "国内航空券の変更申請",
DomesticTicketOrder: "国内航空券の注文",
DomesticTicketRefundApplication: "国内航空券の返金申請",
DomFullFareTicketRate: "国内全額航空券の比率",
DoNotChange: "変更不可",
DoNotChangeBack: "変更・返金不可",
DoNotUse: "使用を見合わせる",
DoNotUseLxd: "旅行豆を使用しない",
DoubleSIMDeviceTip: "現在のデバイスはデュアルSIMデバイスです。メッセージを送信する番号を選択してください。",
Download: "ダウンロード",
DownloadAll: "すべてダウンロード",
Downloaded: "ダウンロード済み",
DownloadFailed: "ダウンロード失敗",
Downloading_x: "ダウンロード中...%a$%",
DownloadPolicy: "保険証券のダウンロード",
DownloadSchedule: "スケジュールのダウンロード",
DownloadTheQRcode: "QRコードをダウンロード",
DowntownCenter: "市中心",
DoYouWantToLeaveHumanCustomerService: "人間のカスタマーサービスを終了しますか？",
DoYouWantToSwitchToPerson: "会員アカウントに切り替えますか？",
DoYouWantToSwitchToUnit: "単位アカウントに切り替えますか？",
DrillMultipleChoice: "ドリルの複数選択",
DriverArrival: "ドライバーが到着しました",
DropDownToViewHistory: "メッセージ履歴を表示するには下にスクロールしてください",
Duration: "所要時間",
DutyParagraph: "会社の税号",
DwellTime: "滞在時間",
Dysfunction: "機能障害",
E: "終了",
EachRoomNeedsToFillIn_x: "各室には%a$人を記入する必要があります",
EarlyThisMorning: "今朝早く",
EarlytoLate: "早朝から夜遅くまでの出発時間",
Economic: "経済的",
EconomicHotel: "エコノミーホテル",
EconomicHotel_1: "経済的",
Economy: "経済",
EconomyClass: "エコノミークラス",
EconomyClassDiscountBelow_x: "エコノミークラス(%a$割引以下)",
Edit: "編集",
Edit_x: "%a$を編集",
EditContact: "連絡先を編集",
EditContactFailed: "連絡先の編集に失敗しました",
EditCostCenter: "コストセンターの編集",
EditDepartment: "部署の編集",
EditFrequentContact: "よく連絡する相手を編集",
EditInvoiceHeader: "請求書のヘッダーを編集",
EditRC: "RCの編集",
Effective: "有効",
EffectiveDate: "有効期限",
ElectronicInvoice: "電子請求書",
Email: "メール",
EmailAddress: "メールアドレス",
EmailApproval: "メール承認",
EmailChineseOrEnglishForFlight: "・中/英版確認メール（中/英版航空券確認票pdf添付ファイル）",
EmailChineseOrEnglishForTrain: "・中/英版確認メール（中/英版列車チケット確認票pdf添付ファイル））",
EmailFormatIsIncorrect: "メールの形式が正しくありません！",
EmailFormatIsIncorrect_x: "%a$のメール形式が正しくありません",
EmergencyContactMobile: "緊急連絡先の電話番号",
EmergencyContactName: "緊急連絡先氏名",
EmergencyContactNumber: "緊急連絡先電話番号",
EmergencyHelp: "緊急求助",
Emission: "排出量(kg)",
EmployeeDetails: "従業員の詳細",
EmployeeInformation: "ユーザ情報",
EmployeeManagement: "従業員管理",
EmployeeNumber: "従業員数",
EmployeesCanApply: "従業員はリンクにアクセスしてチームに参加申請できます",
EmployeesCcanScan: "従業員はチームに参加するために QR コードをスキャンできます",
EmptyFileCannotBeUploaded: "空のファイルはアップロードできません。もう一度選択してください",
EN: "英",
EnableFingerprintLogin: "指紋ログインを有効にする",
End: "終了",
EndDate: "終了日",
EnglishName: "英文氏名",
EnquiryOrderNumber: "要求番号",
EnterANewPassword: "新しいパスワードを入力",
EnterflightNoMU5166: "フライト番号を入力してください(例：MU5166)",
EnteringKefu: "カスタマーサービスに接続中です",
EnterMembershipNumber: "会員カード番号を入力してください",
EnterNewMobile: "新しい携帯電話番号を入力してください",
EnterPhoneNumber: "電話番号を入力してください",
EnterpriseCertification: "資格認定",
EnterpriseCooperation: "企業協力",
EnterTheLastFourDigitsOfYourID: "IDの末尾4桁を入力してください",
EnterVerificationCode: "確認コードを入力してください",
EnterVerificationCode_1: "認証コードを入力",
EnterVerificationCodeForThe12306: "12306からの返信に含まれる確認コードを入力してください",
EnterYourNewPasswordAgain: "新しいパスワードを再入力する",
Estimate: "見積もり",
EstimateMeteredBilling: "見積もり(メーターに基づく請求、実際の使用料金による)",
ETA: "到着予定時刻",
Etc: "他の航空会社",
EUR: "ユーロ",
EvaluateKefu: "サービスを評価してください",
ExactlyLate: "遅延中",
ExceedingOrderAmount: "割引額の上限を超えています",
Exchange: "交換する",
Exchange_1: "すぐに交換する",
ExchangeSerialNumber: "交換シリアル番号",
ExchangeSuccessful: "正常に交換されました",
ExcludingFullPriceAverageDiscount: "定価平均割引を除く",
ExclusiveBrandPrivileges: "独占ブランド特典",
ExclusiveConsultant_x: "専属コンサルタント%a$",
ExistingBusinessTravelAccount: "既存の契約済みビジネストラベルアカウントです",
ExistingStaff: "既存のスタッフ",
ExistingStaff_x: "既存のスタッフ(%a$)",
Exit: "出口",
ExitWarning: "退出すると、WeChatとこのアカウントのバインディングが解除されます。バインディングが解除されると、このアカウントのWeChatメッセージ通知を受け取ることはできません。本当に退出しますか？",
ExpandMore: "さらに展開",
ExpectToAt: "予定時刻",
Expired: "有効期限切れ",
ExportOrder: "注文詳細のエクスポート",
Express: "宅配便",
ExpressDelivery: "宅配配送",
Ext: "内線番号",
ExtendedInformation: "拡張情報",
Face: "顔認証",
FaceIDIsNotEnabled: "現在のデバイスではFace IDが有効になっていません",
FaceIDIsOnlyValidForTheMachine: "Face IDはこのマシンに対してのみ有効です",
FaceIDLogin: "Face IDログイン",
FacilitiesDetails: "施設/詳細",
FacilityServices: "施設サービス",
FailedToAccessTheCamera: "カメラへのアクセスに失敗しました",
FailedToChangePassword: "パスワードの変更に失敗しました",
FailedToGetPleaseTryAgainLater: "取得に失敗しました。後でもう一度お試しください",
FailedToResetPassword: "パスワードのリセットに失敗しました",
FalseAlarmAccountability: "虚偽の警報は、法的責任を追及します",
FalseAlarmAccountabilityDesc_1: "ワンクリックアラームはプラットフォームのカスタマーサービスに接続します。人身および財産の安全を危険にさらす緊急事態の場合は、直接110に電話してください。",
FalseAlarmAccountabilityDesc_2: "虚偽の警報は、5日以上10日以下の拘留に処せられる可能性があります",
FeaturedIntroduction: "特集紹介",
Feedback: "フィードバック",
Feishu: "飛書",
FeishuByAccount: "飛書アカウント",
FeishuByUser: "飛書従業員のバインディング",
Female: "女性",
File: "ファイル",
FileDownloadFailed: "ファイルのダウンロードに失敗しました",
FileSizeLimit_x: "ファイルは%a$Mbまでのサイズに対応しています",
FillInAccordingToTheActualNumber: "実際の人数に基づいて記入してください",
FillInAccurateAndCompleteUnitInformation: "より良いサービスを提供するために、正確で完全な単位情報を記入してください",
FillingGuide: "記入ガイド",
FillInTheReason: "理由を記入してください",
FillInYourBoundEmailAddress: "登録されているメールアドレスを入力してください",
Filter: "フィルター条件",
Filter_1: "高度なフィルター",
Filters: "フィルター",
FindANewQueryQuote: "新しいクエリの見積もりを見つける",
FingerprintDoItAgain: "指紋認証に失敗しました。もう一度お試しください",
FingerprintTouch: "指紋センサーに軽く触れてください",
FingerprintVerification: "指紋認証",
FingerprintVerificationTooManyTimes: "指紋認証の試行回数が多すぎます。後でお試しください",
Finish: "完了",
Finished: "完了しました",
First: "第",
FirstBusinessClass: "ファーストクラス/ビジネスクラス",
FirstClass: "ファーストクラス",
FirstClassSeat: "ファーストクラスの座席",
FirstNameFormatIsIncorrect: "英語の名前の形式が正しくありません！",
FirstStep: "最初のステップ",
FixedPrice: "固定価格",
Flight_x: "%a$ フライト",
FlightBookPermission: "フライト予約許可",
FlightCancellationDelayForMoreThan: "フライトが30分以上遅延またはキャンセルされた場合、航空会社の審査に基づきます",
FlightConfirmationWarn: "ビザや出入国審査のために使用され、確認票を印刷して外出時に持ち歩くことができます",
FlightDate: "フライト日",
FlightDeparturesAreLocalTime: "フライトは現地時間で出発します",
FlightDetails: "フライトの詳細",
FlightDynamicsAreForReferenceOnly: "フライト情報は参考情報です。航空会社の発表をご参照ください",
FlightIndex_x: "%a$フライト",
FlightInformation: "フライト情報",
FlightInformationDesc: "フライトの変更や旅程のリマインダー",
FlightNo: "フライト番号",
FlightNo_x: "フライト番号%a$",
FlightNumber: "フライト番号で",
FlightNumberHotelTrainNumber: "フライト番号/ホテル/列車番号",
FlightPreference: "フライトの希望条件",
FlightPricesChangeFrequently: "フライトの価格は頻繁に変動しますので、旅行計画に支障が生じないよう、お早めにお支払いください",
FlightRank: "フライトランク",
FlightRefundOrderConfirm: "航空券の払い戻し注文確認",
FlightRefundTrip: "キャンセルスケジュール",
FlightRescheduleOrderConfirm: "変更オーダーの確認",
FlightReschedulingOrRefundTips: "暖かいヒント：チェックインした旅客は先にチェックインをキャンセルしなければならず、変更、キャンセルを操作することができない。",
FlightSeatInfoArrow: "座席情報 >",
FlightsPassenger: "乗客",
FlightsRefunds: "国内線の航空券払い戻し",
FlightTicket: "航空券",
FlightType: "フライトタイプ",
Floor: "階",
Floor_1: "階",
Floor_x: "%a$階",
FlyingTime_x: "飛行時間 %a$",
FollowTheSystem: "システムに従う",
Foods: "飲食",
FoodTotalPrice: "商品の合計金額",
ForDomesticTicketsPleaseReturnToTheHomePageAndSelectDomesticTickets: "国内航空券については、トップページに戻り、国内航空券を選択してください。",
ForgetPassword: "忘れる",
ForgetPassword_1: "忘れる？",
FormatIsIncorrect: "形式が正しくありません",
Free: "送料無料",
FreeBoardband: "無料のブロードバンド",
FreeParking: "無料駐車場",
FreePickUpService: "無料の送迎サービス",
FreeWiFi: "無料Wi-Fi",
FrequentContacts: "よく連絡する相手",
FrequentContactsManagement: "よく連絡する相手の管理",
FrequentTraveler: "よく旅行する人",
FrequentTravelerCardInformation: "よく旅行する人のカード情報",
FrequentTravelerManagement: "よく旅行する人の管理",
FriendsareWaiting: "あなたの友達が恒順ビジネストラベルであなたを待っています。この専用リンクを使って参加しましょう！",
From: "から",
Fuel: "燃料",
FuelFee: "燃料費",
FullPrice: "定価",
FullyBooked: "完売",
FullyCallingTheVehicle: "車両を全力で呼び出しています",
GBP: "GBP",
Gender: "性別",
GeneralAmenities: "一般的な設備",
GeneralCarUsage: "一般的な車の使用方法",
GeneralConditionsOfTransportation: "一般的な輸送条件",
GeneralTrainTicket: "一般的な列車チケット",
GetSMS: "SMSを取得",
GettingBoardingLocation: "乗車場所を取得中",
GivenNames: "Given name",
GivenNamesEg: "e.g.MEIMEI",
GoAndReturn: "出発/帰り",
GoApprovals: "承認に行く",
GoBook: "出発を予約する",
GoEnquiry: "出発を問い合わせる",
GoFlight: "出発便",
Going: "出発",
GoingAndReturnTicket_x_x: "%a$(出発), %b$(帰り)",
GoingDate: "出発日",
GoingTicket_x: "%a$(出発)",
GoingTo: "行先",
GoLogin: "ログインに行く",
GoTag: "出発",
GoToBind: "バインドへ進む",
GoToChange: "変更へ進む",
GoToHotel: "ホテルへ進む",
GoToMixedPay_x: "混合支払いへ%a$",
GoToPay: "支払いへ進む",
GoToPay_1_x: "支払い%a$",
GoToPay_x: "%a$を支払うために進む",
GoToQuery: "クエリページに進む",
GoToRefund: "払い戻しに進む",
GoToTheStoreBeforeOClock_x: "%a$までに店舗に行く",
Guarantee: "保証",
Guarantee_1: "保証",
GuessYouWantToFindIt: "お探しの情報ではないかと思います",
GuestRoom_x: "ゲストルーム%a$",
Guests: "宿泊者数",
Gym: "ジム",
HasProblem: "支払いに問題が発生しました",
HasQuitHumanCustomerService: "人間の顧客サービスを終了しました",
HaveAgreement: "協定価格あり",
HaveQuestions: "質問がありますか？ お問い合わせするには、下のボタンをクリックしてください〜",
HaveRefund: "キャンセルあり",
HaveRescheduled: "改期がある",
HelpCenter: "ヘルプセンター",
HengshunTravelAPP: "恒順トラベルアプリ",
HightoLow: "高い順の価格",
History: "履歴",
HistoryOrHot: "履歴/人気",
HistoryOrLocation: "現在/履歴",
HistoryRecord: "履歴記録(過去1年間)",
HKD: "HKD",
HoldToTalk: "話すために押し続ける",
Home: "ホーム",
Home_1: "家",
Homsom: "私のHOMSOM",
HOMSOM: "HOMSOM",
HomsomEnterpriseCooperation: "Homsom企業との協力",
HomsomEnterpriseCooperationDesc: "貴社が既にHomsom Travelの契約企業である場合は、アカウントを取得するために担当マネージャーに連絡してください。再登録しないでください。",
HomsomFWXY: "《サービス契約》",
HomsomLoginPrivacyInfo: "《サービス契約》と《プライバシーポリシー》を読んで同意した",
HomsomLoginPrivacyTitle: "以下の契約事項をお読みいただき、同意してください",
HomsomLoginPrivacyValue: "個人情報の安全を保護するために、ログイン機能を使用する前に、サービス契約とプライバシーポリシーをお読みいただき、同意する必要があります。",
HomsomLxd: "Homsom LXD",
HomsomPrivacyInfo: "《サービス契約》と《プライバシーポリシー》",
HOMSOMScanningCodeLoginConfirmation: "Homsom Travelウェブサイトのスキャンコードログイン確認",
HomsomServiceFee: "恒順サービス料",
HomsomServiceFeeByChange: "期日変更サービス料",
HomsomServiceFeeByIssue: "発券サービス料",
HomsomSuccessDesc: "Homsom Travelがお客様の予約サービスを完了しました",
HomsomTools: "一般的なツール",
HomsomUpdateToExperience: "Homsom Travelが再度更新されました。ぜひ先行体験してください",
HomsomYSZC: "《プライバシーポリシー》",
Hot: "人気",
Hot_1: "人気の交換",
HotChina: "国内人気",
Hotel: "ホテル",
Hotel_x: "%a$ホテル",
HotelAddress: "ホテルの住所",
HotelBedNeeds: "ベッドの要求",
HotelBookPermission: "ホテル予約権限",
HotelBookWarn: "注文を送信する前に、「恒順ビジネスホテル予約規約」、「個人情報承認声明」、「サプライヤー予約規約」をよくお読みください。",
HotelBookWarn_1: "《恒順ビジネスホテル予約規約》《個人情報承認声明》《サプライヤー予約規約》",
HotelChargeback: "ホテルの返金",
HotelCheckInInfo: "チェックイン情報",
HotelCheckInPost_x: "%a$後チェックイン",
HotelCheckOutBefore_x: "%a$前チェックアウト",
HotelConfirmEmailChineseOrEnglish: "·中国語/英語の確認メール（中国語/英語のチェックインバウチャーpdf添付）",
HotelConfirmNumber: "ホテル予約確認番号",
HotelConfirmSMSChineseOrEnglish: "·中国語/英語の確認SMS",
HotelConsumptionInEachCity: "各都市のホテル消費状況",
HotelDetails: "ホテルの詳細",
HotelFeature: "ホテル特徴",
HotelGeneral: "ホテル概要",
HotelGroupMembership: "ホテルグループ",
HotelGroupMembershipCard: "団体会員証",
HotelGroupMembershipCard_1: "ホテルグループ会員カード",
HotelGroupMembershipCardBind: "バインド",
HotelGroupMembershipCardDetails: "会員証の詳細",
HotelGroupMembershipCardInfo: "ホテルグループ会員カード情報",
HotelIntroduction: "ホテル紹介",
HotelInvoiceExplain: "注：部屋代領収書はホテルが発行しますので、フロントで請求してください",
HotelLatestRoomPrice: "最新のホテル料金をお知らせします。",
HotelName: "ホテル名",
HotelNight: "ホテルの総宿泊数",
HotelOtherNeeds: "その他の要求",
HotelPayInStore: "店頭でのお支払い",
HotelPromptIsMorning: "今朝6時前にチェックインする場合は、今日の未明にチェックインしてください",
HotelRank: "ホテルのランキング",
HotelRating: "ホテルのスター(ダイヤモンド)評価",
HotelRC: "ホテルのRC",
HotelReasonCodeDistribution: "ホテルの理由コード分布",
HotelReservationAuthority: "国内ホテルのランキング",
HotelRestaurant: "ホテルのレストラン",
HotelRoomNumber: "部屋数",
HotelRoomRemarkInputTip: "その他の要件がある場合は、ご記入ください。ホテルは可能な限り対応いたします",
Hotels: "のホテル",
HotelSelectChargebackGuest: "キャンセルするゲストを選択",
HotelService: "ホテルサービス",
HotelServicePreferences: "サービス・好み",
HotelServicePreferences_1: "サービスの好み",
HotelSpecialNeeds: "特別要件",
HotelSpecialNeedsEditHint: "その他ご要望がございましたら、フォームにご記入ください。",
HotelSpecialNeedsExplain: "お客様のご要望をホテルに伝えますが、ホテルがそのご要望にお応えできる保証はありませんので、特別なご要望がある場合は、お客様ご自身でホテルにお問い合わせください。",
HotelTotalAmount: "ホテルの総額",
HsTravelerExplainContext: "恒順商旅に入力した頻繁旅行者の身分情報をご理解いただいています。この情報は航空券や鉄道券などの実名登録が必要なすべての商品の予約に使用され、使用時には確認されます。この情報の真正性と有効性を確保してください。恒順商旅はこの情報を暗号化などの手段で保護し、具体的な取引において関連する第三者に提供するためにのみ許可します。",
HsTravelerExplainTitle: "以下を読んで同意する",
ID: "ID",
IDCard: "身分証明書番号",
IDCardFormatIsIncorrect: "身分証明書番号の形式が正しくありません！",
IDNumber: "証明書番号",
IDType: "IDタイプ",
IfTheApproverIsInconsistent: "承認者が一致しない場合は、個別に注文してください。",
IfTheRemainingSeats: "残りの席が座席選択の要件を満たせない場合、システムは自動的に座席を割り当てます。",
IfTheTicketHasBeenIssuedTheLossMustBeBorneByTheOwner: "2. チケットが発行されている場合、返金や変更による損失は所有者が負担する必要があります。",
IfYouCheckThisOption: "このオプションをチェックすると、今後のスケジュールは個人の旅行と見なされます。",
IfYouHaveAnyQuestions: "ご質問がありましたら、恒順商旅の全国サービスホットラインまでお電話ください：4006-123-123",
IfYouHaveAnyQuestionsPleaseContactYourBusinessConsultant: "ご質問がある場合は、ご担当のビジネスコンサルタントにお問い合わせください。",
IfYouNeedToNarrowTheScopePleaseEnterMoreConditions: "範囲を狭める必要がある場合は、追加の条件を入力してください。",
IGotIt: "了解しました",
IHaveReadAndAgree: "読みましたか同意します",
IHaveReadAndAgree_x: "読みましたか同意します%a$",
Import: "インポート",
Import_1: "インポート",
ImportBusiness: "インポート(ビジネス)",
ImportFromFrequentTraveler: "頻繁な旅行者からのインポート",
In: "イン",
IncludingHongKongMacauAndTaiwan: "香港、マカオ、台湾を含む",
Income: "収入",
IncomeExpenditure: "収支",
InconsistentPasswordEnteredTwice: "パスワードが2回入力されていません",
IncorrectFormatOfContactMobileNumber: "連絡先の携帯電話番号の形式が正しくありません！",
Index_x: "%a$索引",
Indexs: "アルファベット索引",
InformationIsIncomplete: "情報が不完全です",
InformationVerification: "情報の検証",
Infrastructure: "インフラストラクチャー",
InitiativeEnthusiasmEfficientMeticulousSpecialty: "より積極的で熱心、効率的で細心、専門的",
InnovativeService: "革新的なサービス",
InputInformation: "情報を入力する",
InquiryInformationIsInvalid: "照会情報は無効です",
Install: "インストール",
Instructions: "心得",
InsufficientRemainingTicketsChooseOtherFlight: "残りのチケットが足りません。他のフライトを選択してください",
Insurers: "に保険をかける",
Integral: "ポイント",
Integral_1: "商品ポイント",
IntegralByHomsom: "ホムソムビジネストラベルポイント",
IntegralMall: "ポイント交換",
Intergral_x: "%a$ポイント",
IntergralDeliveryNumber_x: "配送番号：%a$",
IntergralDescription: "ポイントの説明",
IntergralRules: "ポイントルール",
IntergralTotalColon: "合計：",
InternalProcurement: "内部調達",
International: "海外",
InternationalHotel: "海外ホテル",
InternationalHotel_1: "海外ホテル(香港・マカオ・台湾地区を含まない)",
InternationalHotelOrderConfirmation: "海外ホテルの予約確認",
InternationalHotelOrders: "海外ホテル予約",
InternationalRegion: "国際/中国香港、マカオ、台湾",
InternationalRegion_1: "国際便 - 香港、マカオ、台湾",
InternationalTicket: "国際便",
Internet: "インターネット",
Interval_x: "%a$の間隔",
InTheLocation: "位置情報取得中",
InTheTicket: "チケット発行中",
Intl_1: "国際",
IntlDestination: "海外の目的地",
IntlFlightEnquiry: "国際航空券の需要注文",
IntlFlightEnquiryService: "国際線のフライト問い合わせ",
IntlFlightEnquiryServiceDesc: "専任担当者による個別の問い合わせサービスで、時間と手間を節約",
IntlFlightOrder: "国際線のフライト予約",
IntlFlightRank: "国際線のフライトランキング",
IntlFlights: "国際航空券",
IntlFlights_1: "国際航空券(香港・マカオ・台湾地区を含む)",
IntlFlightsOrderConfirmation: "国際線のフライト予約確認",
IntlHotel: "海外ホテル",
IntlHotelReservationAuthority: "海外ホテルの予約権限",
InTrainTransfer: "車内乗り換え",
InTravel: "旅行中",
Invalid: "無効",
Inventory_x: "在庫：%a$ 個",
InvitationOtherType: "その他の招待方法",
InvitationStaff: "スタッフを招待",
Invitee: "招待者",
InviteEmployeesToJoin: "以下の方法を使用して従業員をチームに招待してください。管理者がユーザ情報をレビューした後、従業員はSMS通知を受け取ります。",
Invoice: "請求書",
InvoiceAmount: "請求金額",
InvoiceContent: "請求内容",
InvoiceDescription: "請求説明",
InvoiceDetais: "請求詳細",
InvoiceFilling: "請求書記入",
InvoiceFinished: "請求済",
InvoiceHeader: "請求のヘッダー",
InvoiceHeaderName: "ヘッダー名",
InvoiceHeaderType: "ヘッダータイプ",
InvoiceInformation: "請求情報",
InvoiceInst: "請求説明",
InvoiceOperating: "請求中",
InvoiceParagraph: "請求税番号",
InvoiceRemarks: "請求備考",
InvoiceTitle: "請求のタイトル",
InvoiceType: "請求タイプ",
InvoicingStatus: "請求ステータス",
InvoluntaryRefund: "非自発的な払い戻し",
InvoluntaryRefundDesc: "非自発的な払い戻し(フライトキャンセル/30分以上の遅延、航空会社の確認に準じる)",
InvoluntaryRefundExplain: "非任意払い戻しの説明",
InvoluntaryReschedule: "非自発的な振り替え",
InvoluntaryRescheduleDesc: "非自発的な振り替え(フライトキャンセル/30分以上の遅延、航空会社の確認に準じる)",
InvoluntaryRescheduleExplain: "非自発的な改期の説明",
IsLoggedIn: "ログイン済み",
IsNotAArrivalCity_x: "%a$は到着地として選択できません",
IsNotADepartureCity_x: "%a$は出発地として選択できません",
IsSendReceiveEmail: "受注メール",
IsSendReceiveSms: "受注SMS",
ItIsNowPastZeroOclock_x: "現在、午前0時を過ぎました。%a$時より前のチェックインをご希望の場合は、本日のチェックインに「今日の未明」を選択してください。",
JobDescription: "ジョブの説明",
JPY: "日本円",
JudgeCertificateExpired_x: "%a$の現在の有効期限は、出発時刻から6ヶ月未満でありすので、搭乗できない可能性があります。続行しますか？",
JustLookAtTheHighSpeedTrain: "新幹線のみ表示",
JustTime: "ちょうど",
Keywords: "キーワード",
KeywordSearch: "キーワード検索(ホテル名)",
KeywordSearch_1: "位置/ブランド/ホテル名",
Kilometers: "%a$キロメートル走行",
KPIAnalysis: "フライトKPI分析",
KRW: "韓国ウォン",
LackOfIntegral: "ポイント不足",
Language: "多言語",
LanguageBeingSet: "言語設定中...",
LanguageCH: "中国語",
LanguageDefault: "デフォルト言語",
LanguageEN: "英語",
LanguageSetting: "言語設定",
LastNameFormatIsIncorrect: "姓の形式が正しくありません！",
LatestArrivalDate: "最終到着日",
LateToEarly: "出発時刻 遅→早",
Latitude: "複数の緯度分析",
LearnAbout: "お知らせ",
LearnMore: "詳細を知る",
LengthMustNotExceedXDigits_x_x: "%a$の長さは%b$桁を超えてはいけません",
LessThan10People: "10人未満",
LetYourFriendsAlsoDownloadHengshunTravelAPP: "友達にも恒順商旅アプリをダウンロードさせましょう",
LevelXAapproval_x: "%a$レベルの承認",
Light: "ライトモード",
LimitedToXReservation_x: "%a$予約に制限",
LinkYourCellphone: "携帯電話をリンクする",
List: "リスト",
Loading: "読み込み中",
Loading_1: "リフレッシュ中...",
LoadMoreTransit: "さらに乗り継ぎ情報を読み込む",
LocacleHotHotelRecommend: "現地の人気ホテルのおすすめ",
LocalTax: "地方税",
LocalTime: "現地時間",
Location: "現在の位置",
Location_1: "位置情報",
Location_2: "位置の距離",
LocationFailed: "現在の位置の取得に失敗しました",
LocationNotTurnOn: "位置情報がオフになっています",
LocationOfTheUnit: "単位所在地",
Login: "ログイン",
Login_1: "ログイン",
Login12306: "12306にログインする",
Login12306Account: "12306アカウントにログインする",
Login12306AccountDesc_1: "実名制が必要です",
Login12306AccountDesc_2: "現在の12306アカウントを使用してチケットを購入/払い戻し/変更/再予約する",
Login12306AccountEnjoyExtremeReversionMemberPoints: "12306アカウントにログインして、究極の返金・変更ポリシーおよび会員ポイントを利用してください。",
Login12306AccountInPerson_x: "あなたがアカウント本人であることを確認してください：%a$",
Login12306FaceToBeCompleted: "顔認証を完了する必要があります",
LoginByAccountPassword: "アカウントパスワードログイン",
LoginByVerificationCode: "認証コードログイン",
LoginByVerificationCodeEmail: "メール認証コード ログイン",
LoginByVerificationCodeSMS: "SMS認証コードログイン",
LoginConfirm: "ログインを確認",
LoginExpired: "ログインの有効期限が切れました。再度ログインしてください！",
LoginName: "ログイン名",
LoginOut: "現在のアカウントからログアウト",
LoginSuccessful: "ログインに成功しました",
LoginSuccessful12306: "12306ログイン成功",
LoginTo12306ToImproveTheSuccessRateOfTicketIssuance: "12306アカウントにログインして、チケット発券の成功率を向上させる",
LogisticsCompany: "物流会社",
Logout: "ログアウト",
LongPressSaveTheQRcode: "QRコードを長押しして保存する",
LookAtThePriceExcludingTax: "税抜き価格を表示する",
LookCertificate: "証明書を表示する",
LookChargebackOrder: "チャージバック注文を表示する",
LooOriginOrder: "元の注文を表示する",
Loss: "損失",
LossAnalysis: "損失分析",
LossPercentage: "損失割合",
LowCarbonHomsomPlantingHomsomForestTogether: "低炭素ホムソム、一緒にホムソムの森を植林する",
LowestPrice: "最低価格",
LowestPrice_1: "最低価格",
LowestPriceDetails: "最低価格の詳細",
LowestPriceHoursAndTicket_x_x: "前後%a$時間の最低価格：%b$ / チケット",
LowestPriceTicketBookingRatio: "最低価格のチケット予約比率",
LowestTicketPrice: "最低価格の航空券",
LowtoHigh: "価格の低い順から高い順",
LuggageStorage: "荷物預け",
Luxury: "高級",
LuxuryHotel: "高級ホテル",
LuxuryHotel_1: "高級",
Lxd: "旅行豆",
LxdReward: "旅行豆の報酬",
LxdReward_x: "%a$ 旅行豆",
LxdRewardCompleteTheOrderToObtain: "注文完了後に獲得できます",
LxdRules: "旅行豆のルール",
LxdToCompleteTheOrder_x: "注文完了後に%a$ 旅行豆を獲得",
MailSent: "Eメール送信",
MainFlight: "メインフライト",
MainRoomer: "メインルーマー",
MainRoomerRequired: "主な入居者（必須）",
MakeSureToClearCache: "キャッシュをクリアしてもよろしいですか？",
MakeSureToFingerprintLoginClose: "指紋ログインを無効にしてもよろしいですか？",
MakeSureToLeaveYourCurrentAccount: "現在のアカウントからログアウトしてもよろしいですか？",
Male: "男性",
Manage: "管理",
Manage_x: "%a$ の管理",
Management: "管理",
Manager: "マネージャー",
ManualApproval: "手動承認",
ManuallyImport: "手動インポート",
ManuallyPassed: "手動承認済",
Map: "地図",
MapNavigation: "地図ナビゲーション",
MapPointSelection: "地図ポイント選択",
MapSelection: "地図選択",
MapSurroundings: "地図/周辺",
MarkAllMessagesAsRead: "將全部消息標記為已讀？",
MatchingTheBestDriverForYou: "あなたに最適なドライバーをマッチングしています",
MaximumFaceValue: "最大額面価格",
MayBeAt: "おそらく",
Meals: "食事",
MealsGoods: "食事",
MealsOrder: "食事の注文",
MealsOrderConfirm: "食事の注文を確認する",
MealsRank: "食事ランキング",
MealsRemarks: "食事の備考",
MeetingRoom: "会議室",
MembershipNumber: "会員証番号：",
Message: "メッセージ",
MessageCenter: "メッセージセンター",
MessageFailedToSend: "メッセージの送信に失敗しました。再試行してください。",
MessageSendSuccessfully: "メッセージが送信されました",
MicrophoneIsRequiredForCurrentService: "現在のサービスではマイクが必要です。設定にアクセスし、有効にしてください。",
Midscale: "ミッドスケール",
Mileage: "マイレージ",
Minute: "分",
Minutes_x: "合計%a$ 分かかりました",
MissedSavingAnalysis: "節約の見落とし分析",
MixedPayment: "混合支払い",
MixedPaymentContent_x_x_x: "ご予約いただいたご注文の合計金額は%a$、出張基準は%b$、%c$を超えています。超過分は自分で支払うか、すべての会社で支払うかを選択できます。",
MixedPaymentDesc: "基準を超える部分の自己負担、プレミアムホテルの超過支払い",
MixedPaymentDetails_x_x: "会社が%a$を支払い、個人が差旅基準金額%b$を超えて支払う必要があります",
MixedPaymentOpen: "貴社は混合支払いが有効になっています",
MixedPaymentOpenDesc: "自己で超過金額を支払うことで、より良い部屋を利用できます",
MixedPaymentSelfPayment: "ハイブリッド支払い",
MnlyOneMembershipCardCanBeAddedToTheSameHotel: "申し訳ありませんが、同じホテルには会員カードが1枚しか追加できません。",
MobileCode: "携帯電話のエリアコード",
MobileInvitationCode: "携帯電話の招待コード",
MobileInvitationCodeShare: "QRコードを含む画像を会社のコミュニティで共有し、同僚は直接スキャンして申請できます",
MobileLinked: "携帯電話番号がリンクされています",
MobilePhoneNumberVerification: "携帯電話番号の検証",
MobileUnlinked: "携帯電話番号がリンクされていません",
ModifiedSuccess: "変更が成功しました",
ModifiedSuccessfully: "変更が成功しました！新しい携帯電話番号は",
Modify: "変更",
ModifyTheBoundPhone: "バインドされた携帯電話を変更する",
Moments: "モーメント",
Month: "月",
MonthApr: "4月",
MonthAug: "8月",
MonthDeb: "2月",
MonthDec: "12月",
MonthDetails: "今月の詳細",
MonthJan: "1月",
MonthJul: "7月",
MonthJun: "6月",
Monthly: "月次",
MonthlyExpense: "月次の経費",
MonthlyKPI: "月次のKPI",
MonthlySummary: "月次の概要",
MonthMar: "3月",
MonthMay: "5月",
MonthNov: "11月",
MonthOct: "10月",
MonthOnMonth: "前月比",
MonthSep: "9月",
More_1: "もっと",
More_2: "もっと見る",
More_3: "もっと<br/>見る",
MoreAvailable_x: "%a$以上のお買い上げでご利用いただけます",
MoreCabinsNoResult: "利用可能なキャビンはありません！",
MoreConsultation: "さらなる相談",
MoreDate: "その他の日付",
MoreDateB: "その他\nの日付",
MoreDiner: "さらに多くの食事人数",
MoreFilterCriteria: "さらなるフィルター条件",
MorePassengerFlight: "追加",
MorePassengerRoomer: "追加ゲスト(ルーム)",
MorePassengerVehicle: "追加乗客(車両)",
MorePrice: "追加価格",
MoreSorting: "追加の並べ替え",
MoreThan100People: "100人以上",
MoreThanThePurchaseLimitCanNotBuy: "購入制限を超えているため、購入できません",
MoreTraveler: "追加の旅行者",
Morning: "朝",
MorningCall: "モーニングコール",
MultipleAccountsHaveBeenBound: "入力した携帯電話番号は複数のアカウントをバインドしていますので、他のログイン方法を試してみてください",
MultipleChoice: "複数選択",
MultipleChoiceSuccessRate: "複数選択は成功率を高めます",
MultiTierReschedulingNotSupported: "マルチティアの予定変更はサポートされていません",
MustBeAccompaniedByAnAdultOnBoard: "子供/乳児は大人の同伴が必要です",
MyAdvancePayment: "前払い金",
MyApplyTravel: "私の申請",
MyApprovals: "私の承認",
MyBalance: "私の残高",
MyCollection: "私のコレクション",
MyCustomerService: "私のカスタマーサービス",
MyInitiations: "私の発起",
MyIntergral: "私のポイント",
MyLxd: "私のトラベルビーンズ",
MyTrip: "私の旅",
Name: "名前",
Name_x: "%a$ 名称",
NameFillingInstructions: "名前の入力方法",
Nation: "国家/地域",
NationalityOfThePerson: "滞在者の国籍",
NationalServicePhone: "全国サービスホットライン",
Near: "付近",
NearExpiration: "期限間近",
NearlyOneYear: "1年近く",
NetList: "ネットリスト",
NetTransactionPrice: "純粋な取引価格",
Network: "ネットワーク",
NetworkRequestConnectionFailed: "ネットワークリクエストの接続に失敗しました",
NetworkRequestFailed: "ネットワークリクエストに失敗しました",
New: "新規",
New_1: "新規",
New_x: "新規%a$",
NewCertificate: "証明書の追加",
NewContactSuccessFailed: "連絡先の追加に失敗しました",
NewContactSuccessFul: "連絡先が正常に追加されました",
NewDepartment: "部署の追加",
NewDiner: "食事の追加",
NewFlightDate: "新規フライト日",
NewFrequentTravelCard: "頻繁な旅行カードの追加",
NewHotelGroupMembershipCard: "ホテルグループ会員カードの追加",
NewMobile: "新しい携帯電話番号",
NewPassenger: "乗客の追加",
NewPassengerFlight: "乗客の搭乗依頼の追加",
NewPassengerVehicle: "乗車者の追加",
NewRC: "RCの追加",
NewRoomer: "宿泊者の追加",
NewTraveler: "旅行者の追加",
NewVersion: "ニューバージョン",
NewVersionFound: "新しいバージョンが見つかりました",
NextStep: "次へ",
NextStep_1: "次へ",
NickName: "ニックネーム",
NightsTotalPrice_x: "%a$泊の総額",
NoApprovalRequired: "承認不要",
NoAvailableCabin: "予約可能な船室はありません",
NoAvailableFlight: "予約可能なフライトはありません",
NoAvailableFlightDesc: "該当するフライトが見つかりませんでした。フィルタ項目を変更してみてください~",
NoAvailableHotel: "現在、予約可能なホテルはありません。",
NoAvailableHotelTip: "条件に一致するホテルが見つかりませんでした。フィルターオプションを変更してみてください～",
NoAvailableTrain: "現在、予約可能な列車番号はありません。",
NoAvailableTrainTip: "条件に一致する列車の時刻表が見つかりませんでした。フィルターオプションを変更してみてください～",
NoAvailableVouchersForUse: "利用可能なクーポンはありません",
NoBreakfast: "朝食なし",
NoChangeablePassengers: "変更可能な乗客はありません",
NoChildUnderDepartmentsAtPresent: "現在の部門には子部門がありません",
NoCollection: "コレクションなし",
NoData: "結果がありません",
NoData_1: "データがありません",
NoDataBySearch: "申し訳ありません、見つかりませんでした。",
NoDataContent: "内容はありません",
NoDataTravelStandard: "暫定相関差標情報なし",
NoIncludedChangeFeeTag: "(変更料金は含まれていません)",
NoMeal: "食事なし",
NoMore: "これ以上ありません",
NoMoreCredential: "利用可能な資格証はありません",
NonBookable: "予約不可",
None: "なし",
NoNet: "ネットワークがない",
NoNewNotice: "新しい通知はありません",
NoNews: "ニュースはありません",
NonStaff: "外部スタッフ",
NonStaffDetail: "社外ユーザの詳細",
NonStaffManagement: "社外ユーザ管理",
Noon: "正午",
NoOrder: "注文はありません",
NoOrderEnquiry: "需要注文はありません",
NoPermissions: "権限がありません",
NoPermissionToAccess: "アクセス権がありません",
NoPlatformAvailablePleaseContactYourTravelConsultant: "利用可能なプラットフォームはありません。差旅アドバイザーにお問い合わせください",
NoRemainingTickets: "予約可能な残りチケットはありません",
NoReschedulingReeIsIncluded: "変更手数料は含まれていません",
NoResult: "結果がありません",
NormalSeat: "ビジネスクラスシート",
NoStay: "未チェックイン",
NotBook: "予約不可",
NotBooking_1: "予約不可",
NotCertificateInfo: "証明書情報はありません",
NotesOnRefundAndChangeOfVisa: "再発行の注意事項",
Nothing: "なし",
Notice: "通知",
NoticeCanceled: "通知がキャンセルされました",
NoTicket: "チケットなし",
NotInitiated: "未発起",
NotNecessaryChoose: "必要ではない選択",
NotNecessaryChoosePleaseChoose: "選択してください",
NotNecessaryChooseUneditable: "選択は必要ありません",
NotNecessaryFill: "必要ではない入力",
NotNecessaryFillPleaseFill: "入力してください",
NotNecessaryFillPleaseFillOrChoose: "記入または選択してください",
NotNecessaryFillUneditable: "入力は必要ありません",
NotNeedDepartPassenger: "出発する乗客はいません",
NoTravelCard: "常時カード情報はありません",
NoTravelStandard: "まだ出張基準がありません",
NotRefundable: "払い戻し不可",
NoTrip: "現在、旅行情報はありません",
NotSelectable: "選択不可",
NotSendEmptyInfo: "空のメッセージは送信できません",
NotUsed: "使用しない",
Now: "現在",
NoXSegment_1_x: "%a$区間を選択",
NoXSegment_2_x: "%a$区間として選択",
NoXSegment_3_x: "%a$区間",
NoXSegment_x: "%a$区間",
Number: "人数",
Number_1: "数量",
NumberOfAdult: "大人の総数",
NumberOfChild: "子供の総数",
NumberOfCustomItems: "カスタムアイテムの数",
NumberOfFullPriceTickets: "全額チケットの枚数",
NumberOfGuestsPerRoom: "一部屋あたりのゲスト数",
NumberOfRoom: "部屋数",
NumberOfRooms: "部屋数",
NumberOfRoomsAndOccupancy: "部屋数と宿泊人数",
NumberOfRoomsBooked: "予約済みの部屋数",
NumberOfSheets: "シートの数",
NumberOfTrainTickets: "列車チケットの枚数",
NumberOfWorkers: "従業員数",
NZD: "ニュージーランドドル",
OffAndBelow: "割引および以下",
OK: "了解しました",
OldAndNewPasswordsMustNotBeTheSame: "古いパスワードと新しいパスワードは同じではいけません",
OldVersion: "旧バージョン",
OneAirlineCanOnlyAddOneFrequentTravelCard: "申し訳ありませんが、同じ航空会社では通常旅行カードを1枚しか追加できません。",
OneWay: "片道",
OnlineChangeNotSupported: "オンラインでの変更はサポートされていません",
OnlineCheckIn: "オンラインチェックイン",
OnlineFlight: "オンラインフライト",
OnlineFlight_1: "チェックイン",
OnlineFlightWarn: "オンラインチェックインサービスは航空会社が提供しています。チェックインによる問題は航空会社に直接連絡してください。",
OnlinePayment: "オンライン支払い",
OnlinePayment_1: "オンライン",
OnlineReport: "オンラインレポート",
OnlineSeatSelection: "オンライン座席選択",
OnlineService: "オンラインサービス",
OnlyAdultTicketBookingsAreSupported: "大人のチケット予約のみサポートされています",
OnlyAlternativeTrainsCanBeSelectedAtMost_x: "最大で%a$本の代替列車を選択できます",
OnlyChineseName_x: "%a$には中国語の名前のみ使用できます",
OnlyDirectFly: "直行便のみ",
OnlyEnglishName_x: "%a$には英語の名前のみ使用できます",
OnlySeeSelected: "選択済みのみ表示",
Open: "開く",
Open_1: "オープンへ進む",
Open_2: "開く",
OpenHengshunTraveMobilelEditionForBetterExperience: "より良い体験のために恒順旅行モバイル版を開く",
Opening: "オープン中",
OpenSafari: "Safariで開く",
OpenSuccess: "開通成功",
OpenYears: "開催年",
OperatedBy: "運営元",
Operating: "処理中",
Operation: "操作",
Operator: "オペレーター",
Optional: "任意",
OptionalCities: "利用可能な都市",
Order: "注文",
Order_1: "予約",
Order_2: "注文の問題",
Order_3: "兌換記録",
OrderCancelFailed: "注文のキャンセルに失敗しました",
OrderCancelled: "注文はキャンセルされました",
OrderCompleted: "注文は完了しました",
OrderDate: "注文日",
OrderDateBookTime: "注文時間",
OrderDetails: "注文詳細",
OrderDetailsBusiness: "注文詳細(法人)",
OrderDetailsPersonal: "注文詳細(個人)",
OrderFilling: "注文記入",
OrderInformation: "注文情報",
OrderInformationForChange: "変更オーダー情報",
OrderInformationForRefund: "キャンセル注文情報",
OrderIntergral: "注文ポイント",
OrderNo: "注文番号",
OrderNo_1: "交換番号",
OrderNoCannotBeEmpty: "オーダー番号を空にすることはできません",
OrderNotice: "注文通知",
OrderNoticeDesc: "チケット発券および予約ステータスのリマインダー",
OrderNumber: "注文番号",
OrderNumber_1: "シリアル番号",
OrderNumber_x: "注文番号%a$",
OrderPaid: "注文は支払い済みです",
OrderStatus: "注文ステータス",
OrderStatusHasChangedPleaseReOperate: "注文ステータスが変更されました。再操作してください",
OrderSubmissionSuccessFailed: "注文の提出に失敗しました",
OrderSubmissionSuccessful: "注文の提出に成功しました！",
OrderSynTime: "同期時間",
OrderTime: "注文時間",
OrderType: "注文タイプ",
OrganizationalStructure: "組織構造",
Original: "元の",
OriginalPrice: "元の価格",
OriginalSchedule: "元のスケジュール",
OriginFlightOrderNo: "原注文番号",
OriginFlights: "元のフライト",
OriginOrderNumber: "元の注文番号",
OriginTrip: "元の行程",
OriginTripOrderNo: "元の列車注文番号",
Other: "その他",
Other_x: "その他: %a$",
OtherInfo: "その他の情報",
OtherInformation: "その他の情報",
OtherLoginMethods: "その他のログイン方法",
OtherPolicy: "その他のポリシー",
OtherReasons: "その他の理由",
OurAgreedAveragePrice: "当社の合意した平均価格",
OurCompany: "当社",
OurExclusiveConsultantWillContactYouAsSoonAsPossible: "デルの専属コンサルタントができるだけ早くお問い合わせします",
Out: "離れた",
OverseasMultiCity: "複数都市",
OverseasServicePhone: "海外サービスホットライン",
Overview: "概要",
OverviewOfTickets: "チケットの概要",
P: "P",
PackUp: "収納する",
Page: "ページ",
PaidBoardband: "有料ブロードバンド",
PaidParking: "有料駐車場",
PaidPickUpService: "有料ピックアップサービス",
PaidWiFi: "有料WiFi",
PartialChange: "一部変更",
PartialRefund: "一部払い戻し",
Pass: "合格",
Passenger: "乗客",
PassengerDetails: "乗客の詳細",
PassengerFlight: "乗客",
PassengerFlightDetails: "乗客の詳細",
PassengerFlightName: "乗客の氏名",
PassengerInformation: "乗客情報",
PassengerList12306: "12306乗客リスト",
PassengerName: "乗客の氏名",
PassengersWhoNeedToRefund: "キャンセルが必要な搭乗者",
PassengersWhoNeedToReschedule: "改期を要する搭乗者",
PassengersWithoutRefundableTickets: "払い戻し可能なチケットのない乗客",
PassengerVehicle: "乗車者",
PassengerVehicleDetails: "乗車者の詳細",
PassengerVehicleForChange: "変更乗車者",
PassengerVehicleForRefund: "キャンセル乗車者",
PassengerVehicleName: "乗車者の氏名",
PassengerVehicleWhoNeedToRefund: "払い戻しの行程を選択してください",
PassengerVehicleWhoNeedToReschedule: "改札する必要のある乗車者",
Password: "パスワード",
Password12306: "12306のパスワード",
PasswordCanBeResetViaEmail: "登録されたメールアドレスを使用してパスワードをリセットできます",
PasswordCanBeResetViaPhone: "登録された電話番号とSMS検証コードを使用してパスワードをリセットできます",
PasswordMustBeLettersAndNumbers: "パスワードは8〜20文字で、英数字を含める必要があります",
PasswordMustBeNoLessThanEightDigitsInLength: "パスワードは8文字以上である必要があります",
PasswordMustBeNoLessThanSixDigitsInLength: "パスワードは6文字以上である必要があります",
PasswordRuleDescription: "推奨されるパスワードは、大文字と小文字、数字で構成され、長さは8桁以上です",
PasswordsMustContainLettersAndNumbers: "パスワードには文字と数字を含める必要があります",
PasswordUpdateSuccessful: "パスワードの変更に成功しました",
Pay: "支払い",
PayAgain: "再度支払う",
PayAtTheStore: "店頭で支払う",
PayAtTheStore_x: "店まで%a$払う",
PayAtTheStoreAbout: "店に着いて支払う おおよそ",
PayAtTheStoreAbout_x: "店に着いて支払う おおよそ %a$",
PayAtTheStoreTaxesFees_x: "税金/費用の別納 おおよそ %a$",
PayBackTips: "注文はまだ支払われていません。戻ると支払いは中止されます。注文で支払いを表示し、続行できます！",
PayInformation: "支払い情報",
PayInformationDetails: "支払詳細",
PaymentFailed: "支払いに失敗しました",
PaymentOnBehalfShareDesc: "发给他人帮你付款吧~",
PaymentPermission: "支払い権限",
PaymentSerialNumber: "支払いシリアル番号",
PaymentSuccessful: "支払い成功",
PaymentSuccessfulContinueToPayTheNextOrder: "支払い成功、次の注文に進む",
PaymentTime: "支払い時間",
PaymentWay: "支払い方法",
PayNow: "すぐに支払う",
PayOrder: "注文を支払う",
PayTypeCompanyDesc: "会社口座から費用を支払う",
PayTypeMixedDesc: "出張の基準を超えた費用は個人で支払う必要がある",
PayTypePersonalDesc: "個人負担",
PdfDownload: "PDFのダウンロード",
PendForOperating: "処理中",
Pending: "承認待ち",
Pending_1: "申請が提出されました(審査待ち)",
Pending_2: "予約が成功しました(審査待ち)",
Pending_3: "申請が提出されました",
Pending_4: "レビュー待ち",
Pending_x: "レビュー待ち(%a$)",
People_x: "%a$人",
People10: "10-20人",
People20: "20-40人",
People40: "40-100人",
PeoplePerRoom: "ルームごとの人数",
PeopleStaying_x: "%a$人滞在",
PercentageOfPotentialSavings: "潜在的な節約の割合",
PercentageOfRoomNights: "宿泊数の割合",
PercentageOfSavings: "節約の割合",
PercentageOfSheets: "シートの割合",
PermissionsRange: "権限の範囲",
Person: "個人",
PersonAccount: "会員アカウント",
Personal: "個人",
Personal_x: "%a$(個人)",
PersonalInformation: "個人情報",
PersonalPayment: "個人支払い",
PersonalPermissions: "個人の権限",
PersonalTools: "パーソナルツール",
PersonalTravelInformation: "個人の出張情報",
PersonalTrip: "個人利用",
PersonalTrip_1: "個人の旅行",
PersonalWallet: "個人ウォレット",
PersonalWorkbench: "個人のワークベンチ",
PersonEntry: "会員エントランス",
PhoneNumberFormatIsIncorrect: "電話番号の形式が正しくありません！",
PhoneNumberFormatIsIncorrect_1: "電話番号の形式が正しくありません！",
PhoneNumberFormatIsIncorrect_x: "%a$の電話番号の形式が正しくありません",
PhoneNumberOrEmailFormatIsIncorrect: "携帯電話番号/メールボックスのフォーマットが正しくありません",
Photo: "写真",
PhotoOrFile: "写真またはファイル",
PickUpUseCarTimeTenMinute: "着陸後10分",
PickUpUserCarTimeFiftyMinute: "着陸後50分",
PickUpUserCarTimeFourtyMinute: "着陸後40分(預めされる荷物に対応)",
PickUpUserCarTimeSixtyMinute: "着陸後60分",
PickUpUserCarTimeThirtyMinute: "着陸後30分(預めされる荷物なしに対応)",
PickUpUserCarTimeTwentyMinute: "着陸後20分",
Picture: "写真",
PlaceOfDeparture: "出発地点",
PlaceOfService: "サービス地点",
Plan: "元の計画",
Plan_x: "元の計画%a$",
Plane: "機種",
PlaneBookNoticeCopyTips: "リンクはクリップボードにコピーされました。ミニプログラムは現時点ではプレビューをサポートしていません。ブラウザで開いてください",
PlaneConstruction: "空港建設",
PlaneConstructionFee: "空港建設料金",
PlaneConstructionFuel: "空港建設 / 燃料",
PleaseAddARoomer: "宿泊者を追加してください",
PleaseAddContact: "連絡先を追加してください",
PleaseCheckInAccordingToTheActualFlight: "実際のフライトに従ってチェックインしてください。",
PleaseCheckTheBoxToAgreeAfterReadingTheRelevantTermsAndConditions: "関連条項を読んで同意をチェックしてください",
PleaseChoose: "選択してください",
PleaseChoose_1: "選択してください",
PleaseChooseAPlaceOfArrival: "到着地点を選択してください",
PleaseChooseAPlaceOfDeparture: "出発地点を選択してください",
PleaseChooseApprover: "承認者を選択してください",
PleaseChooseASeat: "座席を選択してください",
PleaseChooseCarType: "車のタイプを選択してください",
PleaseChooseContacts: "連絡先を選択してください",
PleaseChooseIDType: "IDタイプを選択してください",
PleaseChooseOrInput_x: "%a$を選択または入力してください",
PleaseChoosePayWay: "支払い方法を選択してください",
PleaseChooseRouteArrivalCity_x: "%a$区間の到着地を選択してください",
PleaseChooseRouteDepartCity_x: "%a$区間の出発地を選択してください",
PleaseChooseRouteGoDate_x: "%a$区間の出発日を選択してください",
PleaseChoosesCabinLevel: "キャビンクラスを選択してください",
PleaseChooseTheAirClub: "航空会社クラブを選択してください",
PleaseChooseTheDocumentSignatory: "文書の発行国を選択してください",
PleaseChooseTheHotelGroupMembership: "ホテルグループをお選びください",
PleaseChooseTheReason: "理由を選択してください",
PleaseChooseTheReason_1: "请理由を選択してください:",
PleaseChooseTheValidityPeriodOfTheCertificate: "文書の有効期間を選択してください",
PleaseChooseWhenToUseTheCar: "車の利用時間を選択してください",
PleaseChooseYourNationality: "国籍を選択してください",
PleaseCompleteTheInformation_x: "%a$の情報を入力してください",
PleaseCompleteTheReceiptInformation: "受け取り情報を入力してください",
PleaseCompleteTheVerificationAsSoonAsPossible: "チケット発券に影響を与えないよう、できるだけ早く確認を完了してください",
PleaseCompleteYourGivenNames: "名前を入力してください",
PleaseCompleteYourNameInformation: "名前情報を入力してください",
PleaseCompleteYourSegments: "区間情報を入力してください",
PleaseCompleteYourSurname: "姓を入力してください",
PleaseConfigureTheExaminerInTheBackground: "バックグラウンドで承認者を設定してください",
PleaseConfirmIfWeChatPaymentHasBeenCompleted: "WeChat支払いが完了しているか確認してください",
PleaseConfirmTheNewPassword: "新しいパスワードを確認してください",
PleaseContactTheAccountNumber12306: "12306アカウントを関連付けてください",
PleaseDoNotRefreshThisPagePromptOperation: "このページを更新せず、携帯電話の指示に従って操作してください",
PleaseEnter: "入力してください",
PleaseEnter_1: "検索する%a$を入力してください",
PleaseEnter_1_x: "検索する%a$を入力してください",
PleaseEnter_x: "%a$を入力してください",
PleaseEnterAccurateAndCompleteUnitInformation: "正確で完全な単位情報を記入してください",
PleaseEnterAComment: "コメントを入力してください",
PleaseEnterACostCenterName: "コストセンター名を入力してください",
PleaseEnterAddress: "住所を入力してください",
PleaseEnterANewPassword: "新しいパスワードを入力してください",
PleaseEnterASuggestionOfNoLessThanEightWords: "8文字以上の提案を入力してください",
PleaseEnterAUsername: "ユーザー名を入力してください",
PleaseEnterAuthorizationCode: "認証コードを入力してください",
PleaseEnterBindingCredentialName: "バインドする資格証明書番号を入力してください",
PleaseEnterBindingCredentialType: "バインドする資格証明書の種類を選択してください",
PleaseEnterBindingMobile: "バインドする携帯電話番号を入力してください",
PleaseEnterBindingUserName: "バインドする名前を入力してください",
PleaseEnterCompnayInfo: "企業名を入力してください",
PleaseEnterContactName: "連絡先名を入力してください",
PleaseEnterCorrectEmail: "正しいメールアドレスを入力してください。",
PleaseEnterCorrectMobile: "正しい携帯電話番号を入力してください",
PleaseEnterEheCorrectFormatOfValidityPeriod: "クレジットカードの有効期限の正しい形式を入力してください",
PleaseEnterIDNumber_x: "%a$のID番号を入力してください",
PleaseEnterInformation: "情報を入力してください",
PleaseEnterKey: "検索するキーワードを入力してください",
PleaseEnterMobileVerification: "携帯電話の認証コードを入力してください",
PleaseEnterPhone: "受取人の携帯電話番号を入力してください",
PleaseEnterPhoneNumber_x: "%a$の携帯電話番号を入力してください",
PleaseEnterPhoneNumberOrEmail: "携帯電話番号/メールアドレスを入力してください",
PleaseEnterRecipient: "受取人の名前を入力してください",
PleaseEnterRegisterInfo: "登録情報を入力してください",
PleaseEnterSMSCode: "SMS認証コードを入力してください",
PleaseEnterThe_x: "検索する%a$を入力してください",
PleaseEnterTheAccountForRetrievePassword: "最初にパスワードを取得したいアカウントを入力してください。",
PleaseEnterTheAccountInformationYouNeedToRetrieveYourLoginPassword: "ログインパスワードを取得するために必要なアカウント情報を入力してください",
PleaseEnterTheAirport: "検索する空港を入力してください",
PleaseEnterTheArriveAddress: "降車地点を入力してください",
PleaseEnterTheBindingEmail: "バインドされたメールアドレスを入力してください",
PleaseEnterTheBindingPhone: "バインドされた電話番号を入力してください",
PleaseEnterTheBindingPhoneNumberEmail: "バインドされた携帯電話番号/メールアドレスを入力してください",
PleaseEnterTheBindingPhoneNumberEmail_1: "バインドされた携帯電話番号/メールアドレスを入力してください",
PleaseEnterTheCardNumber: "カード番号を入力してください",
PleaseEnterTheCardNumberOrUsername: "カード番号またはユーザー名を入力してください",
PleaseEnterTheCarRank: "検索する車のランクを入力してください",
PleaseEnterTheCity: "検索する都市名を入力してください",
PleaseEnterTheCityNameToSearch: "検索する都市名を入力してください",
PleaseEnterTheCompanyAddress: "会社の住所を入力してください",
PleaseEnterTheCorrectAccountPassword: "正しいアカウントパスワードを入力してください",
PleaseEnterTheCorrectCreditCardNumber: "正しいクレジットカード番号を入力してください",
PleaseEnterTheCorrectMobilePhoneNumberEmail: "正しい携帯電話番号/メールアドレスを入力してください",
PleaseEnterTheCorrectVerificationCode: "正しい確認コードを入力してください",
PleaseEnterTheCostCenter: "検索するコストセンターを入力してください",
PleaseEnterTheCountry: "検索する国を入力してください",
PleaseEnterTheCurrentPassword: "現在のパスワードを入力してください",
PleaseEnterTheCustomItems: "検索するカスタムアイテムを入力してください",
PleaseEnterTheDepartAddress: "乗車地点を入力してください",
PleaseEnterTheDepartment: "検索する部署を入力してください",
PleaseEnterTheFlightBookPermission: "検索するフライト予約権限を入力してください",
PleaseEnterTheHomeAddress: "自宅の住所を入力してください",
PleaseEnterTheHotelBookPermission: "検索するホテル予約権限を入力してください",
PleaseEnterTheHotelCity: "都市/ランドマーク/ホテル名",
PleaseEnterTheIntlHotelCity: "都市/ホテル名",
PleaseEnterTheLastThreeDigitsOfTheCVVSecurityCode: "CVVセキュリティコードの下3桁を入力してください。",
PleaseEnterTheName: "検索する名前を入力してください",
PleaseEnterTheNameOfThePerson: "ゲストの名前または注文番号を入力してください",
PleaseEnterTheNameOfThePerson_1: "ゲストの名前または注文番号またはホテル名を入力してください",
PleaseEnterTheNameOfTheRank: "検索するランクの名前を入力してください",
PleaseEnterTheNameOfTheRole: "検索する役割の名前を入力してください",
PleaseEnterTheNewPassword: "8〜20文字の英数字で入力してください",
PleaseEnterTheOldPassword: "古いパスワードを入力してください",
PleaseEnterThePassengerName: "乗客の名前または注文番号を入力してください",
PleaseEnterThePassword: "ログインパスワードを入力してください",
PleaseEnterTheSystemRole: "検索するシステムの役割を入力してください",
PleaseEnterTheTemplate: "検索する承認テンプレートの名前を入力してください",
PleaseEnterTheTrainAccountNewPassword: "6〜20文字の英数字で入力してください",
PleaseEnterTheTrainAccountNewPassword_1: "少なくとも6文字を入力してください",
PleaseEnterTheTrainBookPermission: "検索する列車予約権限を入力してください",
PleaseEnterTheTrainStation: "検索する駅名を入力してください",
PleaseEnterTheTraveler: "検索する名前/メールアドレス/携帯番号/従業員番号を入力してください",
PleaseEnterTheTraveler_1: "名前/メールボックス/携帯電話番号/従業員番号/ニックネームの検索",
PleaseEnterTheTraveler_2: "名前/携帯電話番号/証明書番号",
PleaseEnterTheUnitCodeOrUnitShortName: "検索する単位コード/略称/全称を入力してください",
PleaseEnterTheValidityPeriodOfTheCreditCard: "クレジットカードの有効期限を入力してください",
PleaseEnterYourAccountInformation: "アカウント情報を入力してください",
PleaseEnterYourExceedReason: "超過の理由を入力してください",
PleaseEnterYourNewPasswordAgain: "新しいパスワードを再入力してください",
PleaseEnterYourNewPasswordAgain_1: "パスワードを確認してください",
PleaseFill: "入力してください",
PleaseFill_x: "%a$を入力してください",
PleaseFill_x_x: "%a$の%b$を入力してください",
PleaseFillBudget: "予算を入力してください",
PleaseFillBudget_x: "%a$の予算を入力してください",
PleaseFillIn: "新しい携帯電話番号を入力してください",
PleaseFillIn_x: "%a$の名前を入力してください",
PleaseFillInCardNumber: "カード番号を記入してください",
PleaseFillInCostCenterName: "コストセンター名を入力してください",
PleaseFillInDepartmentName: "部署名を入力してください",
PleaseFillInDiner: "食事をする人の名前を入力してください",
PleaseFillInEmail_x: "%a$のメールアドレスを入力してください",
PleaseFillInFirstName: "名を入力してください",
PleaseFillInGuestName: "宿泊者の名前を入力してください",
PleaseFillInIDNumber: "ID番号を入力してください",
PleaseFillInLastName: "姓を入力してください",
PleaseFillInMobile_x: "%a$の携帯電話番号を入力してください",
PleaseFillInName: "名前を入力してください",
PleaseFillInNewPhoneCurrentPhone: "新しい携帯電話番号を入力してください。現在の携帯電話番号：",
PleaseFillInOrderNumber: "注文番号を入力してください",
PleaseFillInPassengerName: "乗客の名前を入力してください",
PleaseFillInPhoneNumber: "携帯電話番号を入力してください",
PleaseFillInRC: "RCを入力してください",
PleaseFillInTheContactMobilePhoneNumber: "連絡先の携帯電話番号を入力してください",
PleaseFillInTheContactMobilePhoneNumber_x: "連絡先の%a$携帯電話番号を入力してください",
PleaseFillInTheReasonForRefund: "返金理由を入力してください",
PleaseFillInTheReasonForRescheduling: "日程変更の理由を入力してください",
PleaseFillInTheShippingAddress: "配送先住所を入力してください",
PleaseFillInVerificationCode: "確認コードを入力してください",
PleaseFillOrChoose_x: "%a$を入力または選択してください",
PleaseFillTotalBudget: "総予算を入力してください",
PleaseGetTheVerificationCode: "まず、確認コードを取得してください",
PleaseHoldDownTheSliderAndDrag: "スライダーを押し下げて右端までドラッグしてください",
PleaseHoldThePhoneVertically: "携帯電話を垂直に握ってください",
PleaseInputCreditCardNumber: "クレジットカード番号を入力してください",
PleaseInputYourDestination: "目的地を入力してください",
PleaseMakeSureYouHaveEnoughTimeToCheckIn: "1. チケットを購入する前にチェックインカウンターでチケット発行後もチェックインに十分な時間があることを確認してください。",
pleaseOpenScanOnAlipayScanQRCodeToPay: "携帯電話でアリペイのスキャンQRコード機能を開き、QRコードをスキャンして支払いを行ってください",
pleaseOpenScanOnWeChatScanQRCodeToPay: "携帯電話でWeChatを開き、QRコードスキャン機能に移動し、QRコードをスキャンして支付",
PleasePressBackOnceToExit: "終了するには1回戻るボタンを押してください",
PleaseReadAndAgreeTo: "「アカウント授権契約」を読んで同意してください",
PleaseReadAndAgreeTo_x: "%a$を読んで同意してください",
PleaseReadAndTickTheAgreementFirst: "まず契約書を読んでチェックしてください",
PleaseReselectYourCheckInDate: "チェックイン日を再選択してください",
PleaseSelect_x: "%a$を選択してください",
PleaseSelect_x_x: "%a$の%b$を選択してください",
PleaseSelectADepartureDate: "出発日を選択してください",
PleaseSelectADestination: "目的地を選択してください",
PleaseSelectAirport: "空港を選択してください",
PleaseSelectApprover: "承認者を選択してください",
PleaseSelectArea: "地域を選択してください",
PleaseSelectArrival: "到着先を選択してください",
PleaseSelectArrivalAirport: "到着空港を選択してください",
PleaseSelectArrivalCity: "到着都市を選択してください",
PleaseSelectArrivalTrainStation: "到着駅を選択してください",
PleaseSelectAtLeastOneCityOverseas: "少なくとも1つの国際都市または地域を選択してください",
PleaseSelectAtLeastOneOccupant: "少なくとも1人の宿泊者を選択してください",
PleaseSelectBackDate: "帰りの日付を選択してください",
PleaseSelectBusinessTravelScale: "月次の出張規模を選択してください",
PleaseSelectCertificate: "証明書を選択してください",
PleaseSelectCertificateNo: "証明書番号を入力してください",
PleaseSelectCostCenter: "コストセンターを選択してください！",
PleaseSelectDepartment: "部署を選択してください！",
PleaseSelectDeparture: "出発地を選択してください",
PleaseSelectDepartureAirport: "出発空港を選択してください",
PleaseSelectDepartureTrainStation: "出発駅を選択してください",
PleaseSelectDestination: "目的地を選択してください",
PleaseSelectDiner: "食事をする人を選択してください",
PleaseSelectedTheCheckInDate: "チェックイン日を選択してください",
PleaseSelectFlightChangeDate: "フライトの変更日を選択してください",
PleaseSelectFlightChangeDate_x: "%a$便の変更日を選択してください",
PleaseSelectFlightDate: "フライト日を選択してください",
PleaseSelectFlightNumber: "フライト番号を入力してください",
PleaseSelectGuest: "宿泊者を選択してください",
PleaseSelectGuestLeastOne: "各部屋には少なくとも1名の宿泊者を入力してください",
PleaseSelectHotelArrivalTime: "ホテルの到着時間を選択してください",
PleaseSelectLoginTypeWitchAnytime: "以下のいずれかのエントリを選択してログインしてください",
PleaseSelectOrFillInMealsRemarks: "食事の備考を選択/入力してください",
PleaseSelectOrFillInPurpose: "出張目的を選択/入力してください",
PleaseSelectOrFillInTheReasonForCancelReason: "キャンセルの理由を選択/入力してください",
PleaseSelectOrFillInTheReasonForExceedingTheStandard: "基準を超える理由を選択/入力してください",
PleaseSelectOrFillInTheReasonForRefundReason: "払い戻しの理由を選択/入力してください",
PleaseSelectOrFillInTheReasonForReschedulingReason: "日程変更の理由を選択/入力してください",
PleaseSelectOrFillInTheReasonForViolatingTheTravelRank: "出張基準違反の理由を選択/入力してください",
PleaseSelectOrFillInTheReasonOrPleaseSelectTheMixedPayment: "基準超過の理由を選択/記入するか、基準超過支払を選択してください",
PleaseSelectPassenger: "乗客を選択してください",
PleaseSelectPassengersFirst: "先に乗客を選択してください",
PleaseSelectPaymentWay: "支払い方法を選択してください",
PleaseSelectPurpose: "出張の目的を選択してください",
PleaseSelectSeats: "座席を選択してください",
PleaseSelectTheCardExpirationMonth: "月を選択",
PleaseSelectTheCardExpirationYear: "年の選択",
PleaseSelectTheDateOfBirth: "生年月日を選択してください",
PleaseSelectTheDepartureCity: "出発都市を選択してください",
PleaseSelectTheFavoriteToDeleteFirst: "削除するお気に入りを先に選択してください",
PleaseSelectTheFirstSeat: "最初の区間の座席を選択してください",
PleaseSelectTheFollowingMethodsForVerification: "以下の方法で検証を選択してください",
PleaseSelectTheItineraryToBeRescheduled: "再予約する予定を選択してください",
PleaseSelectTheMixedPayment: "サブスクリプションを続行するには、超過料金を選択してください",
PleaseSelectThePassengerWhoNeedsToChange: "変更が必要な乗客を選択してください",
PleaseSelectThePassengerWhoNeedsToRefund: "払い戻しが必要な乗客を選択してください",
PleaseSelectThePassengerWhoNeedsToRefund_x: "%a$の払い戻しが必要な乗客を選択してください",
PleaseSelectThePassengerWhoNeedsToReschedule: "日程変更が必要な乗客を選択してください",
PleaseSelectTheRoomAndRoomer: "部屋と入居者を選択",
PleaseSelectTheSecondSeat: "2番目の区間の座席を選択してください",
PleaseSelectTheShippingAddress: "配送先住所を選択してください",
PleaseSelectTheTravelApplication: "旅行依頼フォームを選択してください",
PleaseSelectTheTypeOfQuestion: "質問の種類を選択してください",
PleaseSelectTrainStation: "駅を選択してください",
PleaseSelectTraveler: "出発者を選択してください",
PleaseSendTheAbovePaymentTo: "上記の支払いを以下に送信してください",
PleaseSure: "必ず確認してください",
PleaseUploadAttachFile_x: "%a$ をアップロードしてください",
PleaseUseTheFollowingCreditCard: "お知らせ：以下のクレジットカードを使用してください",
PleaseVerifyThatYouHaveAFaceIDForLogin: "ログインのためにFace IDを確認してください",
PleaseVerifyThatYouHaveATouchIDForLogin: "ログインのためにTouch IDを確認してください",
PointYourFaceAtTheFrontCameraOfYourPhone: "顔を携帯電話の前面カメラに向けてください",
PoliciesServices: "ポリシーとサービス",
Policy_1: "ポリシー",
PolicyAddress: "保険証券の住所です",
PolicyNoColon: "保険証券番号：",
PopularDestinations: "人気のある目的地",
PopularFilters: "人気のあるフィルター",
PopularRoutes: "人気のあるルート",
Pptimization: "最適化",
Precautions: "注意事項",
Predict: "予測",
Preferential: "特恵",
Preferential_1: "この注文は以下の対象となる。",
PremiumHotel: "プレミアムホテル",
PremiumHotel_1: "プレミアム",
Prepay: "前払い",
Prepayments: "前払い",
PreQuantifier: "/人",
PressVoice: "録音するには押してください",
PreviewNotSupportedAndIsCopy: "プレビューは現在サポートされていません。クリップボードにコピーされました。ブラウザで開いてください",
Price: "価格",
Price_1: "参考料金",
Price_2: "スターレーティング価格",
Price150: "¥150以下",
Price150_300: "¥150-300",
Price300_450: "¥300-450",
Price400: "¥400以下",
Price400_700: "¥400-700",
Price450_600: "¥450-600",
Price600_1000: "¥600-1000",
Price700_1000: "¥700-1000",
Price1000_1300: "¥1000-1300",
Price1300_1800: "¥1300-1800",
PriceCeiling: "価格上限",
PriceDetails: "価格の詳細",
PriceHighest: "高い順",
PriceHighToLow: "価格の高い順",
PriceHotel: "室料",
PriceLowest: "低い順",
PriceLowToHigh: "価格の低い順",
PriceOfPerson_x_x: "%a$ X %b$ 人",
PriceOver1000: "¥1000以上",
PriceOver1800: "¥1800以上",
PricePerPerson_x: "%a$ 1人あたり",
PriceRating: "価格/レーティング",
PriceRating_1: "価格/ドリル・レベル",
PriceSingleChoice: "価格(単一選択)",
Print: "印刷",
PriorityHighPrice: "高価優先",
PriorityHighRating: "高星優先",
PriorityLowPrice: "低価格優先",
PrivacyPolicy: "プライバシーポリシー",
Processing: "処理中",
ProcessingCompleteTime: "処理完了時間",
ProcessingTime: "処理時間",
ProduceDesc: "製品の説明",
ProduceName: "製品名",
Product: "製品",
ProductDesc: "製品の説明",
ProductExperience: "製品体験",
ProductIntroduction: "商品紹介",
ProductOverview: "商品の概要",
ProductUseRuleDes: "使用ルール",
Progress: "進行中",
PromptDateHotel: "選択したのはホテルの現地日付です",
PromptDatePrice: "選択した日付は出発日です。価格は変動する可能性がありますので、実際の価格をご確認ください",
ProportionOfContractedHotelStarLevelTransactions: "契約ホテルの星レベル取引の割合",
ProportionOfDomesticFullPriceTickets: "国内フルプライスチケットの割合",
ProportionOfHotelRoomNightsInAgreement: "契約ホテルの客室利用率",
ProportionOfNetTransactionPrice: "ネット取引価格の割合",
ProportionOfRCTimes: "RCの回数の割合",
PullToRefresh: "引っ張って更新",
PullUpLoadMore: "上にスワイプしてもっと読み込む",
Punctuality: "遅延率",
Punctuality_x: "遅延率%a$",
PurchaseXItemAtMost_x: "最大%a$個まで購入",
Purpose: "出張目的",
PutTheQRCodeIntoTheBox: "QRコードをボックスに入れると自動的にスキャンされます",
QrCode: "QRコード",
QRCodeHasExpired: "QRコードは期限切れです",
QuantifierHotel: "室",
QuantifierNight: "泊",
QuantifierPeople: "人",
Quantity: "航空券の枚数",
Quarterly: "四半期ごと",
QueryConditions: "クエリ条件",
QueryNotJurisdiction: "クエリ権限がありません",
QueryQuoteErrors: "見積もりのクエリエラー",
QueryRange: "クエリ範囲",
QueryResultOfTheCurrentCity: "現在の都市のクエリ結果",
QueryType: "クエリタイプ",
QuickSelection: "クイック選択",
R: "返品",
RankDescription: "ランクの説明",
RankInformation: "ランク情報",
RankManagement: "ランク管理",
RankName: "ランク名",
Rating: "スター(ダイヤモンド)評価",
RatingHighToLow: "評価の高い順から低い順へ",
RatingLowToHigh: "星が低い-高い",
RatingMultipleChoice: "スターレーティング(複数選択可)",
RCContent: "RCの内容",
RCManagement: "RC管理",
RCType: "RCタイプ",
Read: "既読",
Ready_x: "予定%a$",
Reapply: "再申請",
ReasonForNotSelectingTheCheapestFlight: "最も安いフライトを選択しない理由",
ReasonForNotSelectingTheLowestPrice: "最低価格を選択しない理由",
ReasonForTheRefundTicket: "返金チケットの理由",
ReasonForTicketIssueFailure: "チケット発行の失敗理由",
ReasonForTravelRankingViolation: "出張ランキング違反の理由",
ReasonsForBusiness: "出張の理由",
ReasonsForBusiness_x: "出張の理由：%a$",
ReBook: "再予約",
ReceiveMode: "受信モード",
RecentSearches: "最近の検索",
Recharge: "チャージ",
RechargeAmount: "チャージ金額",
Recipient: "受取人",
Recommend: "おすすめ",
Recommend_1: "人気のおすすめ",
RecommendCabinPrice: "同じフライトの優先価格をお勧めします：",
Recommended: "おすすめ",
RecommendedFlights: "お勧めフライト",
RecommendedTransferFlights: "推奨トランジット便",
RecordsExchange: "引換記録",
ReElection: "キャビンの再選択",
RefreshFlight: "フライトの更新",
RefreshPageTip_1: "注文承認状態が変更されました。ページを更新して最新の状況を取得してください。",
RefreshPrices: "価格の更新",
RefreshRetry: "ページを更新して再度試してください～",
RefreshSucceed: "更新成功",
Refund: "払い戻し",
Refund_1: "返金",
RefundAmount: "払い戻し金額",
RefundAmountPendingApproval: "返金額はレビュー待ち",
RefundCertificate: "払い戻し証明書",
RefundChangeAndBaggageInstructions: "返品変更及び荷物の説明",
RefundDetails: "払戻詳細",
RefundFailed: "払い戻し失敗",
RefundFee: "払い戻し手数料",
Refunding: "払い戻し中",
RefundInstructions: "払い戻しの手順",
RefundOrChangeExplain: "払い戻し/変更の説明",
RefundOrderDate: "払い戻し申請日",
RefundOrderDetails: "キャンセル詳細",
RefundOrderNumber: "払い戻し注文番号",
RefundOrderNumber_x: "キャンセル注文番号%a$",
RefundOrderTime: "払い戻し申請時間",
RefundPassenger: "キャンセル搭乗者",
RefundPolicy: "払い戻しポリシー",
RefundRate: "払い戻し率",
RefundReason: "払い戻し理由",
RefundSucceed: "払い戻し成功",
RefundTrip: "キャンセルスケジュール",
Regard: "フォロー",
RegardEmployees: "フォローしている人",
RegardFailed: "フォロー失敗",
RegardSuccess: "フォロー成功",
Register: "登録",
Register_1: "登録情報",
Register_2: "携帯電話の検証",
Register_3: "審査待ち",
RegisterSuccess: "できるだけ早くご連絡いたします。お電話をご確保ください。ありがとうございます！",
Reject: "拒否",
RelationAccount: "アカウントのリンク",
RelationLogin: "リンクしてログイン",
RelationToHOMSOM: "ログインアカウントをリンクしてください",
ReleaseImmediateLoad: "即時読み込み解除",
ReleaseImmediateRefresh: "即時リフレッシュ解除",
ReleaseVoice: "解放して終了",
Relevance: "関連性",
Remaining_1_x: "残り%a$個",
Remaining_x: "残り%a$枚",
RemainingRoom_x: "残り%a$室",
RemainingUseLxd_x: "%a$個残っています(1つのトラベルビーン=1元人民幣)",
Remarks: "備考",
ReminderYouCanImportFrequentTravelerInFrequentTravelerManagement: "注意事項：常客は常客管理に人員情報を導入することで追加することもできる。",
RemovalFailedPleaseTryAgainLater: "削除に失敗しました。後でもう一度お試しください。",
RemoveFrequentTraveller: "この頻繁な旅行者を削除する",
RemoveFromTheTiedList: "結合リストから削除する",
RemoveSuccessful: "削除成功",
RemoveThe_x: "この%a$を削除する",
RemovetheCostCenter: "このコストセンターを削除する",
RemoveTheDepartment: "この部署を削除する",
RemoveTheEmployee: "この従業員を削除する",
RemoveTheNonEmployee: "外部ユーザの削除",
RemoveTheRC: "このRCを削除する",
RemoveThisFrequentContact: "このよく連絡する相手を削除する",
RenovationYears: "改装年",
ReportTotal: "合計",
ReQuery: "再確認",
RequiredChoose: "必須(選択)",
RequiredChoose_x: "%a$人を選択する必要があります",
RequiredChooseArrivalCityAddressDesc: "(必須)出張中に都市を経由する場合は追加する必要があります",
RequiredChoosePleaseChoose: "(必須)選択してください",
RequiredChooseUneditable: "(必須)編集不可",
RequiredFill: "必須(記入)",
RequiredFill_x: "%a$必須",
RequiredFillPleaseFill: "(必須)記入してください",
RequiredFillPleaseFillOrChoose: "(必須項目) 記入または選択してください",
RequiredFillUneditable: "(必須)編集不可",
RequiredReadingForBook: "外出には必ず読む",
RequiredReadingForChange: "期日変更の心得",
RequiredReadingForHotel: "ホテル予約の必読事項",
RequiredReadingForRefund: "払い戻しの心得",
RequiredReadingForTrainChange: "心得",
ReScan: "再スキャン",
Reschedule: "日程変更のリクエスト",
Rescheduled: "日程変更済み",
Rescheduling: "日程変更中",
ReschedulingFee: "日程変更手数料",
ReschedulingReason: "日程変更の理由",
Reselect: "再選択",
Reselect_1: "出発地を再選択",
ReselectFlight: "フライトの再選択",
ReSelectPrice: "価格の再選択",
Resend: "再送信",
Resend_x: "再送 %a$s",
ResendAfterXSeconds_x: "%a$s秒後に再送信",
ResendConfirmationMessage: "確認メッセージの再送",
ResendPleaseWait: "再送信してください。お待ちください",
ResendPleaseWait_x: "%a$ 待ってから再送信してください",
Reset: "リセット",
ResetFilter: "フィルターをリセット",
ResetPassword: "パスワードをリセット",
ResetUsingBoundEmail: "バインドされたEメールアドレスでリセット",
ResetUsingBoundPhoneNumber: "携帯電話番号でリセット",
Rest: "休憩",
Result: "検索結果",
RetrieveByEmail: "メールアドレスで取得",
RetrieveByMobile: "携帯電話番号で取得",
RetrievePassword: "パスワードの取得",
RetrievePasswordByMobile: "携帯電話の回収",
Retry: "再試行",
Return: "復路",
Return_1: "返:",
ReturnAndChangeInstructions: "返品および変更の指示",
ReturnColon: "返:",
ReturnDate: "復路の日付",
ReturnTicket_x: "%a$(復路)",
ReVetting: "再審査",
RevisedFlightInformation: "変更後のフライト情報：",
ReviseTheDifference: "変更料金を修正",
RideInfo: "乗車情報",
RMB: "元",
RMB_x: "%a$元",
RMBNight: "元/泊",
RMBPrice_x_x: "¥%a$-%b$",
RMBPriceOver_x: "¥%a$以上",
RMBPriceUnder_x: "¥%a$未満",
RoleDescription: "役割の説明",
RoleDetail: "役割の詳細",
RoleManagement: "役割の管理",
RoleName: "役割名",
Room: "客室",
Room_x: "%a$室",
RoomAdult_x_x: "%a$室 大人%b$人",
RoomAdult_x_x_x: "%a$部屋 %b$大人 %c$子供",
RoomAndNight_x_x: "%a$部屋 %b$泊",
Roomer: "宿泊者",
RoomerDetails: "宿泊者の詳細",
RoomerName: "宿泊者名",
RoomFees: "部屋料金",
RoomNight: "泊",
RoomNightPrice_x_x: "%a$部屋·%b$泊",
RoomNoXAtLeastChooseOneRoomer_x: "へや%a$には最低1人の宿泊者が選択される必要があります",
RoomsAndGuests: "部屋とゲスト",
RoomType: "部屋タイプ",
RoomTypeDetails: "部屋タイプの詳細",
RoomTypeRemarks: "部屋タイプの備考",
RoundTrip: "往復",
Route: "ルート",
RouteDepartDateCannotBeLessThanLastRoute_x_x: "ルート%a$の出発日はルート%b$の出発日より遅くすることはできません",
RUB: "ルーブル",
Rules: "ルール",
SafetyAlarmRequired: "安全警報必読",
SafetyManage: "安全管理",
SameLevelApprover: "同レベルの承認者",
SameStationTransfer: "同駅乗り換え",
Save: "保存",
Save_1: "節約",
Save_2: "変更を保存",
Save_3: "節約",
SaveFailed: "保存に失敗しました",
SaveFailedFromCode: "QRコードの保存に失敗しました",
SaveOrderError: "注文の保存エラーです。フライト検索ページに戻り、再度選択してください！",
SaveQRCode: "QRコードを保存",
SaveSucceed: "保存に成功しました",
SaveSucceedToPhotoLibary: "写真ライブラリに保存されました",
SavingAnalysisofBookinginAdvance: "事前予約の節約分析",
SavingAndMissedSaving: "節約分析",
Savings: "節約額",
SavingsLosses: "節約と損失",
ScaleLevel_1: "1万〜3万",
ScaleLevel_2: "3万〜10万",
ScaleLevel_3: "10万〜30万",
ScaleLevel_4: "30万〜100万",
ScaleLevel_5: "100万以上",
ScanAndLogin: "QRコードをスキャンしてログイン",
ScanCodeLogin: "QRコードをスキャンしてログイン",
ScanSuccessfully: "スキャン成功",
ScanTheQRCode: "QRコードをスキャン",
Schedule: "時刻表",
ScheduledItinerary: "予定された旅程",
ScheduleTip: "ヒント: 具体的には列車の実際の運行状況に基づきます。駅の放送に注意してください。",
SchemIndex_x: "プラン %a$",
SchemIndexSelect_x: "プラン %a$(選択済み)",
ScoreByComments: "分",
Search: "検索",
Search_1: "検索",
Search_2: "クリックして検索",
Search_3: "検索",
SearchCanBeRescheduled: "改期便の照会",
SearchFlight: "検索",
SearchForMoreCities: "他の都市を検索",
SearchHistory: "検索履歴",
SearchHotel: "検索",
SearchThisArea: "このエリアを検索",
SearchTrain: "検索",
Seat: "座席",
SeatInfo: "座席情報",
SeatNumber: "座席番号",
SeatSelectionDepartingTrip: "座席選択(出発)",
SeatSelectionReturnTrip: "座席選択(帰り)",
SeatSelectionSucceed: "座席選択成功",
SecondAfter_x: "%a$s",
SecondClass: "二等席",
SecondStep: "2番目のステップ",
SecurityAlarmDesc: "危険に遭遇した場合は、110番にすぐに通報してください",
SecurityAssistant: "セキュリティアシスタント",
SecurityCenter: "セキュリティセンター",
SecurityCode: "セキュリティコード",
See: "低価格を見る",
SeeAll: "すべてを見る",
SeeAll_x: "全%a$件の価格を見る",
SeeDetails: "詳細を見る",
Segment: "区間",
SegmentRecommend: "おすすめ",
Select: "選択済み",
Select_1_x: "選択済み：%a$",
Select_x: "%a$を選択",
SelectAll: "すべて選択",
SelectApprover: "承認者を選択",
SelectCabin: "キャビンを選択",
SelectCarPlatform: "車のプラットフォームを選択",
SelectCarScene: "車のシーンを選択",
SelectCity: "都市を選択",
SelectConfirmationOrder: "確認票の選択",
SelectContact: "連絡先を選択",
SelectCustomItems: "カスタムアイテムを選択",
SelectDate: "日付を選択",
SelectDeparting: "出発を選択",
Selected: "選択済み",
Selected_1: "選択済み",
Selected_Train_codes_x: "%a$個の列車が既に選択されています",
Selected_x_x: "%a$個%b$が選択されました",
SelectedAddress: "選択した住所",
SelectedAsGoing: "行くことに決定",
SelectedFlights: "選択済みのフライト",
SelectedPassengers: "選抜客室乗務員",
SelectedPassengerVehicle: "乗車者が選択されました",
SelectedTraveler_x: "選択済み：%a$人",
SelectEmployees: "従業員を選択",
SelectFlight: "フライトを選択",
SelectFlightChangeInfo: "代替フライト情報の選択",
SelectFlightChangeStep1: "01.改期の搭乗者を選ぶ",
SelectFlightChangeStep2: "02.期間変更のスケジュールの選択",
SelectFlightChangeStep3: "03.変更日の選択",
SelectFlightOnline: "ストロークの選択",
SelectFlightRefund: "キャンセルスケジュールの選択",
SelectFlightRefundInfo: "キャンセルフライト情報の選択",
SelectFlightRefundStep1: "01.キャンセルする搭乗者を選ぶ",
SelectFlightRefundStep2: "02.キャンセルのスケジュールを選択",
SelectFlightRescheduling: "期間変更トリップの選択",
SelectFromTheAlbum: "アルバムから選択",
SelectInvoicingType: "請求タイプの選択",
Selection: "座席選択",
SelectionResult: "座席選択結果",
SelectLanguage: "言語を選択",
SelectOneWay: "片道を選択",
SelectPassengers: "乗客を選択",
SelectReturn: "復路を選択",
SelectTheCardExpirationMonth: "月を選択",
SelectTheCardExpirationYear: "年の選択",
SelectTheNumberOfRooms: "選択間数",
SelectThePassenger: "搭乗者を選ぶ",
SelectThePassengerVehicle: "乗車者を選択してください",
SelectTheReasonForTheRefundTicket: "払い戻しタイプを選択",
SelectTheRescheduleType: "変更タイプを選択",
SelectTrain: "列車を選択",
SelectTrainChangeInfo: "改札情報を選択してください",
SelectTrainChangeStep1: "改札の乗車者を選択してください",
SelectTrainChangeStep2: "改札の行程を選択してください",
SelectTrainRefund: "払い戻し行程を選択してください",
SelectTrainRefundInfo: "払い戻しの情報を選択してください",
SelectTrainRefundStep1: "払い戻しの乗車者を選択してください",
SelectTrainRefundStep2: "返金する行程を選択してください",
SelectTrainRescheduling: "改札行程を選択してください",
SelectYourCabin: "キャビンを選択",
SelectYourSeat: "選択する",
SelectYourSeatDeparting: "座席を選択(出発)",
SelectYourSeatReturn: "座席を選択(帰り)",
Self: "本人",
SelfPay: "自己負担",
Send: "送信する",
SendATo12306: "12306に送信",
SendDistressMessage: "救援メッセージを送信",
SendEmailOrPhone: "送信携帯電話/メール",
SendFailure: "送信失敗",
SendingLXD: "LXDのプレゼンテーション",
SendMail: "郵便",
SendMailSuccessfullyPleaseCheckTheMailboxToResetThePassword: "メールの送信に成功しました。パスワードをリセットするためにメールボックスを確認してください。",
SendMoneyToTheFollowingAccount: "以下のアカウントに送金する",
SendSMS: "以下にSMSを送信しました：",
SendSMS_1: "確認用のSMSを送信しました。受信した確認コードを入力してください。",
SendSMS_2: "SMSを送信",
SendSMS_x: "%a$に確認用のSMSを送信しました。受信した確認コードを入力してください。",
SendSucceed: "送信成功",
SendTo12306_x: "%a$を12306に送信",
SendVerificationCode: "確認コードを送信",
SendVerificationCode12306: "666～12306を送信",
SendVerificationCodeTo12306ByMobilePhoneUpToTimesPerDay_x_x: "携帯電話%a$で12306に確認コード%b$を送信し、1日に最大3回まで送信できます",
SerialNumber: "シリアル番号",
SeniorApprover: "ちょくぞくじょうし",
Service: "カスタマーサービス",
Service_1: "オペレーターへ転送",
ServiceAgreement: "サービス契約",
ServiceFee: "手数料",
ServiceFeePerPerson_x: "手数料：%a$ / 人",
ServiceLife: "サービス利用期間",
SetAsDefaultContact: "デフォルトの連絡先に設定",
SetAsStaff: "スタッフとして設定",
SetEmergencyContact: "緊急連絡先を設定",
SetNewPassword: "新しいパスワードの設定",
SetSuccessfully: "設定成功",
Setting: "設定",
SettlementMethod: "決済方法",
SettlementPeriod: "決済期間",
SettleNow: "即時決済",
SGD: "シンガポールドル",
ShareDescPaymentOnBehalf_x: "我在恒顺商旅预订了%a$，需要你帮我付款哦~",
SharedFlight: "共有フライト",
SharePDF: "PDFを共有",
ShareTitlePaymentOnBehalf_x: "%a$希望你帮他付款",
ShareWithFriends: "友達と共有",
ShippingAddress: "配送先住所",
ShowAvailableTicketsOnly: "予約可能なチケットのみ表示",
ShowMoreSuppliers: "他のサプライヤーを表示",
ShowTheFrontOfYourFace: "顔の正面を表示",
SigningPolicy: "署名ポリシー",
SignOut: "現在のアカウントからログアウトする",
SignOut_1: "現在のアカウントからログアウト",
Skip: "スキップ",
SkipLogin: "ログインを飛ばす",
SlideUpToSeeMore: "上にスライドして詳細を見る",
SlideYourFingerUp: "指を上にスライドして送信をキャンセル",
Smoke: "喫煙",
SMS: "SMS認証",
SMSVerification: "SMS認証",
Snatch: "奪う",
SnatchCodeWarn_1: "選択する列車の数が多ければ多いほど、チケットの取得成功率は高くなります。",
SnatchCodeWarn_2: "最高価格で前払い収める",
SnatchDeadline_x: "出発前：%a$",
SnatchDeadlineHour_x: "出発まであと%a$時間",
SnatchDeadlineMinute_x: "出発まであと%a$分",
SnatchInfo: "チケット奪取情報",
SnatchSeatsWarn_1: "·複数の座席を選択すると、チケットの獲得成功率がより高くなります。",
SnatchSeatsWarn_2: "·選択後には一時的に最高のチケット価格が請求され、チケットの取得に成功した後に実際のチケット価格に応じて差額が払い戻されます。",
SnatchTicket: "チケ争い",
SnaTchTicketDetails: "チケットの詳細を奪う",
SoldOut: "売り切れ",
SorryCantFind: "すみません、見つかりません",
SorryCouldNotFindIt_x: "申し訳ありませんが、“%a$”に関連するものは見つかりませんでした。",
SorryNoCabinAvailable: "申し訳ありませんが、利用可能なキャビンはありません",
SorryNoMatchingFlight: "申し訳ありませんが、該当するフライトはありません！",
SorryNoMatchingHotel: "申し訳ありませんが、該当するホテルはありません！",
SorryNoMatchingRoom: "申し訳ありませんが、該当する部屋タイプはありません！",
SorryNoMatchingTrain: "申し訳ありませんが、該当する列車はありません！",
SorryThePageYouVisitedDoesNotExist: "申し訳ありませんが、アクセスしたページは存在しません。",
SorryThereAreNoSearchResultsFor_x: "申し訳ありませんが、%a$ の検索結果はありません。",
Sort: "並べ替え",
SortRecommend: "推奨",
SpecialLineForTravelConsultants: "旅行コンサルタント用特別専用ライン",
SpecialOffer: "特別オファー",
SpecificRequirements: "特定の要件",
Spending: "支出",
Staff: "スタッフ",
StaffAdd: "スタッフの追加",
StaffNo: "スタッフ番号",
StarComfort: "3",
StarComfort_1: "3",
StarEconomic: "2",
StarEconomic_1: "2",
StarLuxury: "5",
StarLuxury_1: "5",
StarPremium: "4",
StarPremium_1: "4",
StarRating: "スターレーティング",
Start: "開始",
StartDate: "開始日",
StartVerification: "検証を開始する",
StationDropOff: "駅まで送迎",
StationDropOff_x: "%a$まで送迎",
StationName: "駅名",
StationPickup: "駅でピックアップ",
StationPickup_x: "%a$からピックアップ",
Stay: "滞在",
Stay_1_x_x: "%a$で滞在%b$",
Stay_2_x_x: "%a$で乗り換え、滞在%b$",
Stay_3_x_x: "%a$で停車、滞在%b$",
Stay_x: "%a$滞在",
Stayed: "滞在した",
Stop: "経由",
Stop_1: "経由",
Stop_1_x: "%a$停車",
Stop_2: "経由",
Stop_2_x: "%a$つの停止",
Stop_x: "%a$経由",
StopBy_x: "%a$経由",
StopInfo: "経由停車情報",
StopsFor_x: "%a$のために停車",
StowMoreSuppliers: "その他のサプライヤーを非表示",
SubmissionTime: "コミット時間",
Submit: "提出する",
Submit_2: "提出する",
SubmitAndPay: "提出して支払う",
SubmitOrder: "確定",
SubmitOrderForChange: "確定",
SubmitOrderForChange_1: "確定",
SubmitSucceed: "提出が成功しました",
Submitted: "提出済み",
SubmittedRefund: "払い戻しを申請する",
SubmitVetting: "承認の発行",
Subsidiary: "ポイント詳細",
SubwayStation: "地下鉄駅",
SuccessfullySaved: "保存が成功しました",
SuccessfulPasswordModification: "パスワードの変更に成功しました",
SuggestMultipleChoices: "複数選択をお勧めします",
SuperEconomyClass: "スーパーエコノミークラス",
Superior_x: "上司の%a$",
SuperiorCostCenter: "上位のコストセンター",
SuperiorDepartment: "上位の部門",
SupportingMixedPayment: "超過支払いをサポート",
Surname: "Surname",
SurnameEgLI: "e.g.HAN",
Surplus: "残り",
SwimmingPool: "プール",
SwipeVerification: "スワイプ認証",
SwitchAccount: "アカウント切り替え",
SwitchAndAccess: "切り替えとアクセス",
SwitchLogin: "アカウントでログインに切り替える",
SwitchPerson: "会員の切り替え",
SwitchPersonAccount: "会員アカウントの切り替え",
SwitchUnit: "単位の切り替え",
SwitchUnitAccount: "単位アカウントの切り替え",
SyncDate: "日付の同期",
SystemApproval: "システム承認",
SystemPermissionCamera: "カメラの許可がありません。設定で許可を有効にしてください。",
SystemPermissionDevice: "デバイスの読み取り/書き込みの許可がありません。設定で許可を有効にしてください。",
SystemPermissionInstall: "このアプリのインストールには手動での許可が必要です。設定に移動して権限を有効にしてください。",
SystemPermissionLocation: "位置情報の許可がありません。設定で許可を有効にしてください。",
SystemRecommendedPrice: "システム推奨価格",
SystemRole: "システムの役割",
TakeAPicture: "写真を撮る",
TakeOffTimeAnalysis: "出発時刻の分析",
TakesOff: "出発",
TakesOff_x: "%a$ 出発",
TakesOffArrival_x_x: "%a$ 到着、%b$ 出発",
TalkAboutYourSuggestionOrProblem: "提案や問題について話す",
Tax: "税金",
Tax_1: "税金",
Taxi: "タクシー",
TaxpayerIDNumber: "納税者番号",
TaxPrice_x: "税金: %a$",
TaxRegisterAddress: "税務登録住所",
TeamLink: "チームリンク",
TeamQRcode: "チームQRコード",
Telephone: "電話",
TemplateBound: "他のテンプレートにバウンド済み",
TemplateName: "テンプレート名",
TemplateNotes: "テンプレートのメモ",
ThankYouForYourFeedback: "フィードバックありがとうございます",
THB: "THB",
TheAgeOfTheChild_x: "子供%a$の年齢",
TheApplicationFormYouHaveSelectedNotSupportRoundTripSwitching: "選択した申請フォームは往復切り替えに対応していません",
TheCarMayChangePleaseCheckAgain: "車両情報が変更されている可能性がありますので、再度ご確認ください",
TheCarryingRegulationsFor: "リチウム電池およびパワーバンクの携帯規則、",
TheCityHasBeenSelected: "この都市は選ばれた",
TheCodeValueIsEmpty: "コードの値が空です",
TheCurDatCanExcTheNexTriDatPleGoBacToTheHomPagToModify: "現在の日付は次の旅行日を超えることはできませんので、ホームページに戻って修正してください",
TheCurrentDeviceIsLockedOrNotBiometricEnabled: "現在のデバイスはロックされているか、バイオメトリック認証が有効になっていません",
TheCurrentDeviceSupportsFaceID: "現在のデバイスはFace IDをサポートしています。有効にしますか？",
TheCurrentDeviceSupportsTouchID: "現在のデバイスはTouch IDに対応しています。有効にしますか？",
TheCurrentLogin: "現在のログインは",
TheCurrentNetworkIsNotAvailable: "現在のネットワークは利用できません",
TheCurrentNetworkIsNotAvailable_1: "現在のネットワークは利用できません。接続状態を確認してください。",
TheCurrentOrderHasBeenSplitIntoMultipleOrdersPleaseGoToTheOrderListToView: "現在の注文は複数の注文に分割されました。注文リストで確認してください。",
TheCurrentOrderIsAPersonalPaymentOrder: "現在の注文は個人支払い注文です。承認後、速やかに支払いを行って出券してください。",
TheCurrentOrderRequiresPersonalPayment1: "現在の注文は個人支払いが必要です。支払いが完了してから出券できます。",
TheCurrentOrderRequiresPersonalPayment2: "現在の注文は個人支払いが必要です。支払いが完了してから確認できます。",
TheCurrentPointsAreNotEnoughToExchangeThisItem: "現在のポイントではこの商品と交換するには十分ではありません。",
TheCurrentServiceNeedsToUseLocation: "現在のサービスは位置を使用する必要があります。設定に移動して位置情報を有効にしてください。",
TheCurrentTimeIsNotAvailableForAppointments: "現在の時間は予約できません。",
TheDateOfDepartureCannotBeEarlierThanThePreviousProcess_x: "%a$の出発日は前のプロセスよりも早くすることはできません。",
TheDriverIsGoingToTheDestination: "ドライバーは目的地に向かっています。",
TheDriverWillContactThePassenger: "ドライバーが乗客に連絡します。",
TheEmailNotificationHasBeenTurnedOn: "メール通知がオンになっています。メールアドレスは空白にできません！",
TheFlightCombinationYouSelectedCannotBeReserved: "選択したフライトの組み合わせは予約できません。別々に注文してください。",
TheFlightHasInsufficientTickets: "フライトのチケットが不足しています。別のフライトを選択してください。",
TheFollowingTimesAreLocalTime: "以下の時間は現地時間です。",
TheHotelPicturesAreForReferenceOnly: "ホテルの写真は参考用です。実際の宿泊状況を基準としてください",
TheIdentityOfPassengerXHasNotBeenVerified_x: "乗客%a$の身元が確認されていません。",
TheItineraryYouSelectedNotMatchTheApplicationForm: "選択した行程は申請フォームと一致しません。",
TheLengthOfTheViolationOfTheTravelRankCannotExceedXWords: "旅行ランクの違反の長さは50単語を超えることはできません。",
TheLoadingFailedTryAgain: "読み込みに失敗しました。再度試してください。",
TheLowestLogicalFareIsSuggestedAccordingToTheTravelPolicyOfYourCompany: "貴社の旅行ポリシーに従い、最低適正運賃を予約することをお勧めします。",
TheLowestPriceForDomesticAirTicketIsNotSelected: "国内航空券の最低価格が選択されていません。選択の理由が必要です。",
Theme: "テーマ",
TheNetworkSignalIsPoor: "ネットワークの信号が弱いです。後でもう一度お試しください。",
TheNewPassword: "新しいパスワード",
TheNewVersionIsOnlinePleaseUpdateToTheLatestVersion: "新しいバージョンがオンラインです。最新バージョンに更新してください。",
TheNextPageIsNotUnderOuControl_x: "次のページは私たちの管理下にありません。使用する際は注意してください。%a$",
TheNumberOfTheTicket: "チケット",
TheOrderConfirmedAndProofOfSuccessfulBookingFollowingMethods: "注文は確定しており、予約の成功の証明は以下から入手できる。",
TheOrderIncludesAirfare: "次へをクリックすることで、以下に同意したことになります",
TheOrderIncludesRescheduledPassengers: "このオーダーには、改定された乗客がいます。",
TheOrderIsForIndividualPaymentOrders: "この注文は個人支払い用です。改定については、旅行コンサルタントにお問い合わせいただくか、電話: 4006-123-123 までお問い合わせください。",
TheOrderIssuedAndProofOfSuccessfulBookingFollowingMethods: "注文書は発券されており、次の方法で航空券確認書を取得できます。",
TheOrderIssuedAndProofOfSuccessfulBookingFollowingMethodsForTrain: "注文書は発券済みで、以下の方法で列車の切符確認書を得ることができます。",
ThePeriodOfValidity: "有効期間",
ThePeriodOfValidityTips: "例：2025年6月、0625と入力してください",
ThePhoneNumberIsTheSameAsTheCurrentlyBoundMobilePhoneNumber: "電話番号は現在のバインドされている携帯電話番号と同じです。",
TheRailwayBureauStipulatesThatTicketsMustBeSoldByRealName: "鉄道局は、チケットは実名で販売する必要があると規定しています。",
ThereAreOtherFlightsAtTheSameTime: "同じ時間帯に他のフライトがあります。続行しますか？",
TheReasonForExceedingTheStandardCannotExceedXWords: "規定を超える理由は50語を超えてはいけません。",
TheRefundFeeIsBeingApprovalByTheFlightCompany: "払い戻し料金は航空会社による承認中です。",
TheRefundFeeIsSubjectToTheReviewOfTheAirlineCompany: "払い戻し料金は航空会社の審査を受けます。",
ThereMeals: "食事あり",
ThereMustBeNoContactWithTheSamePhoneNumber: "同じ電話番号の連絡先は存在してはいけません。",
ThereNoAvailableRoomTypesForBooking: "予約可能な部屋タイプがありません",
ThereNoAvailableRoomTypesForBookingForChangeFilter: "条件に合う部屋タイプが見つかりませんでした。フィルターを変更してみてください。",
TheRescheduleType: "変更タイプ",
TheReschedulingFeeIsBeingApprovalByTheFlightCompany: "変更手数料は航空会社による承認中です。",
TheReschedulingFeeIsSubjectToTheReviewOfTheAirline: "変更手数料は航空会社の審査を受けます。",
TheRoomerIDNumberCannotBeRepeated: "宿泊者のID番号は繰り返すことはできません。",
TheRoomIsReservedAllNight: "部屋は一晩中予約されており、14:00より前に到着する場合、部屋の利用可能性を待つ必要があります",
TheSpecificPriceIsSubjectToTheReview: "*具体的な価格は12306による審査に基づきます",
TheSpecificRefundAndChangeRulesShallBeSubjectToTheDateOfTheTicketIssue: "具体的な払い戻しと変更のルールは航空会社による審査に基づきます！",
TheSubmissionFailedPleaseTryAgainLater: "送信に失敗しました。後でもう一度お試しください！",
TheSysteIsBusyPleaseReOperate: "システムが混雑していますので、再操作してください！",
TheSystemIsBusyPleaseTryAgainLater: "システムが混雑していますので、後でもう一度お試しください！",
TheTakeoffAndLandingLocalTime: "フライトの発着時間はすべて現地時間です",
TheTicketYouChooseIsVeryCloseToTheDepartureTime: "あなたが選んだ列車は発車時間に近いので、安全検査、実名制検証、改札などの手続きをするのに十分な時間があることを確保してください。出発が近づくと、発券に失敗するリスクがあるので、スケジュールを遅らせないように情報を確認することに注意してください。",
TheTicketYouChooseIsVeryCloseToTheDepartureTimeChange: "あなたが選んだ列車は発車時間に近いので、安全検査、実名制検証、改札などの手続きをするのに十分な時間があることを確保してください。出発が近づくと、変更に失敗するリスクがあるので、スケジュールを遅らせないように情報を確認することに注意してください。",
TheValidityPeriodHasExpired: "有効期限が切れています！",
TheValidityPeriodHasExpiredByReplace: "あなたの証明書は期限切れです。他の証明書を交換するか、延期してから再予約してください",
TheVerificationCodeHasBeenSentTo_1_x: "認証コードは以下のアドレスに送信されました %a$",
TheVerificationCodeHasBeenSentTo_x: "認証コードは%a$に送信されました",
ThirdPartyBinding: "サードパーティアカウントの関連付け",
ThirdPartyRelation: "アカウント関連",
ThirdStep: "第3ステップ",
ThisInformationIsForReferenceOnlyIfThereIsAnyChangePleaseReferToTheStationAnnouncement: "*この情報は参考用です。変更がある場合は、駅のアナウンスに従ってください。",
ThisOrderUseLxd_x: "この注文では%a$個のトラベルビーンズを使用できます",
ThJourneyIsXAndIsExpectedToTakeXMinutes_x_x: "全行程は%a$キロメートルで、所要時間は%b$分です",
TicketAmount: "発券金額",
TicketBarrier: "改札口",
TicketBarrierWaitingPlace: "改札口/待ち合わせ場所",
TicketBookingAuthority: "国内航空券の料金ルール",
TicketChangeRefund: "チケットの変更/払い戻しポリシー",
TicketFare: "チケット料金",
TicketingMail: "発券メール",
TicketingSMS: "発券SMS",
TicketingSucceed: "発券成功",
TicketNumber: "チケット番号",
TicketPricesHaveChanged: "価格が変更されました",
TicketRestrictions: "払い戻し/変更ポリシー",
TicketRestrictions_x: "%a$からの払い戻し/変更",
TicketRestrictions_x_x: "%a$%b$からの払い戻し/変更",
TicketRestrictionsArrow: "払い戻し/変更ポリシー >",
TicketRestrictionsCancellationBaggage: "払い戻し/変更ポリシー(手荷物)",
TicketRestrictionsCancellationBaggageArrow: "払い戻し/変更ポリシー(手荷物) >",
TicketRestrictionsRule: "払い戻し/変更ポリシールール",
Tickets: "枚",
Tickets_1: "/枚",
Tickets_x: "%a$枚",
TigerRobot: "タイガーロボット",
Time: "交換時間",
Time_1: "時間",
TimeArrival: "に到着",
TimeConsuming: "所要時間",
TimeConsumingLeast: "所要時間(少ない順)",
TimeConsumingMost: "所要時間(多い順)",
TimeDeparture: "はっしゃ",
TimeHighToLow: "時間(遅い順)",
TimeLowToHigh: "時間(早い順)",
TimeRange: "時間範囲",
Tips: "ヒント",
Tips_1: "[ヒント]",
Tips_2: "ヒント",
TipsColon: "暖かいヒント：",
TMS: "Hengshun Hotel TMS",
TMSOnline: "Hengshun Hotel TMS オンライン",
To: "まで",
ToApply: "申請する",
ToBeConfirmed: "確認待ち",
ToBetterYourBusinessTripExperience: "より良いホテルでのビジネス旅行体験のために",
ToChoose: "選択する",
Today: "今日",
ToEnsureThatThePassengerInformationIsCorrect: "乗客情報が正確であることを確認するために、指示に従って確認してください",
ToEnsureTheSecurityOfTheAccount: "アカウントのセキュリティを確保するために、本人操作であることを確認してください",
ToGuarantee: "保証する",
ToGuaranteeAmount: "保証金額",
Tomorrow: "明日",
Tomorrow_1: "翌日",
Tomorrow_x: "翌日%a$時",
Top5Cities: "上位5都市",
Top5CostCenterExpense: "トップ5のコストセンターの経費",
Top5DeptsExpenses: "トップ5の部門の経費分析",
Top5DestinationsbyExpense: "トップ5の目的地別経費",
TopFiveModels: "トップ5のモデル別経費分析",
ToSetUp: "設定する",
Total: "合計",
Total_1: "往復合計金額:",
Total_2: "合計:",
Total_3: "往復合計金額",
Total_x: "合計 %a$",
TotalAmount: "総額",
TotalAmount_1: "総額",
TotalAmount_x: "合計金額%a$",
TotalAmountOfCarUsed: "車両使用総額",
TotalAmountOfTrainTicket: "列車チケットの総額",
TotalAmountPendingApproval: "合計金額レビュー保留中",
TotalBudget: "予算総額",
TotalBudgetAmount: "予算総額",
TotalBudgetRMB: "予算総額(元)",
TotalCabinInfo_x: "航空機情報 %a$ 件",
TotalDuration: "合計時間",
TotalDuration_x: "合計時間%a$",
TotalMileage: "総走行距離",
TotalMissedSaving: "総損失",
TotalNights: "%a$泊",
TotalNights_1: "宿泊日数：%a$泊",
TotalNumberOfPeople: "総人数",
TotalOfDay_x: "%a$日",
TotalOrderAmount: "注文総額",
TotalPiece_x: "総数：%a$件",
TotalPrice: "合計",
TotalPrice_x: "合計%a$",
TotalPriceIncludingTax: "税込合計金額",
TotalPriceNoTax: "税抜き価格",
TotalPriceNoTax_1: "往復総額(税抜き)",
TotalPriceNoTax_2: "マルチトリップ総額(税抜き)",
TotalPriceTax: "税込価格",
TotalPriceTax_1: "往復総額(税込み)",
TotalPriceTax_2: "マルチトリップ総額(税込み)",
TotalSaving: "総節約額",
TotalTrip_x: "(合計 %a$ 件)",
ToTravel: "出発前",
TouchIDIsNotEnabled: "現在のデバイスではTouch IDが有効になっていません",
TouchIDIsOnlyValidForTheMachine: "Touch IDは現在のマシンにのみ有効です",
TouchIDLogin: "Touch IDでログイン",
TourismConference: "観光",
TrackingNo: "追跡番号",
TrackingNumber: "追跡番号",
Traffic: "交通",
Train: "列車",
TrainAccount: "12306アカウント",
TrainAccountLogin12306: "12306にログインする",
TrainAccountName: "アカウント名",
TrainAccountPwd: "アカウントのパスワード",
TrainAccountPwd12306: "12306アカウントのパスワード",
TrainAccountSubmitCheck: "確認のために提出する",
TrainApplication: "列車チケットの申請",
TrainAvailable: "利用可能",
TrainBookPermission: "列車チケットの予約権限",
TrainChanged: "列車情報が変更されている可能性があります。再度検索してください",
TrainDetails: "列車スケジュールの詳細",
TrainNumber: "列車番号",
TrainOrders: "列車チケットの注文",
TrainPassenger: "乗客",
TrainRank: "列車チケットのクラス",
TrainSnatchCode: "代替の列車",
TrainSnatchDate: "代替日",
TrainSnatchDeadline: "締め切り時間",
TrainSnatchSeats: "代替の座席",
TrainStation: "駅",
TrainTicket: "列車",
TrainTicketConfirmation: "列車チケットの注文確認",
TrainTicketFare: "列車チケットの運賃",
TrainTicketsBooking: "列車チケットの予約",
TrainTicketSmsVerification_x_x_x: "お客様は乗車される方にお知らせください。30分以内に電話番号%b$を使用してSMSメッセージ%c$を12306に送信して確認を行ってください。",
TrainTicketsYouSearchedFor: "検索した列車チケット",
TrainType: "列車の種類",
TrainUnavailable: "利用不可",
Transfer: "乗り換え",
Transfer_1: "順番",
Transfer_1_x: "%a$順番",
Transfer_2: "順番",
Transfer_2_x: "%a$レンジ中継",
Transfer_3_x: "%a$で乗り換える",
Transfer_Stop_x_x: "%a$レンジ中継%a$つの停止",
Transfer_x: "%a$ 乗り換え",
TransferToAnotherStation: "別駅乗り換え",
TransportationAccommodation: "交通と宿泊",
TransportTime: "輸送時間",
TravelApplication: "出張申請書",
TravelApplicationCode: "出張申請書番号",
TravelBooking: "旅行予約",
TravelCard: "頻繁な旅行者カード",
TravelControl: "旅行管理",
Traveler: "旅行者",
Traveler_1: "出張者",
Traveler_x: "出張者：%a$",
TravelerDetails: "旅行者の詳細",
TravelerType: "旅行者タイプ",
TravelPolicy: "出張基準",
TravelPositioning: "旅行の位置づけ",
TravelPurpose: "旅行目的",
TravelReport: "旅行レポート",
TravelSecurity: "旅行保険",
TravelStandard: "基準",
TravelStandard_1: "マイ差標",
TravelTime: "旅行時間",
TravelType: "旅行タイプ",
Trip: "旅行",
TripType: "旅行の種類",
TryAgain: "再試してください",
TryAgain_1: "再試してください",
TWD: "TWD",
TwinBed: "ツインベッド",
Type: "タイプ",
Type_1: "理由の種類",
TypeOfQuestionRequired: "質問の種類(必須)",
TypeOfTrip: "旅行の種類",
UnableToBookTheCurrentAgreementPriceProduct: "申し訳ありませんが、ご注文の搭乗者は航空会社のホワイトリストにいないので、現在の契約価格製品を予約することはできません。",
UnableToLocateCurrentCity: "現在の都市を特定できません",
Unbind: "バインド解除",
UnbindSuccessfully: "正常にバインド解除しました",
Unbound: "バインドされていません",
UnboundApprover: "バインドされていない承認者",
UnboundCustomization: "バインドされていないカスタマイズ項目",
UnboundEmployees: "バインドされていない従業員",
UnboundTemplate: "バインドされていないテンプレート",
UnderOneYearOld: "1歳未満",
UnderTheCurrentCostCenterNoCostCenter: "現在のコストセンターにはサブコストセンターがありません",
Uneditable: "編集不可",
Unfilled: "未入力",
Unfinished: "未完了",
Unfold: "展開",
UnHandleAuthorization_x: "未処理の承認依頼が %a$ 件あります",
UnhookYourFingersAndCancelSend: "指を離して送信をキャンセルします",
Unit: "単位",
Unit_1: "つ",
Unit_1_x: "%a$",
Unit_x: " x %a$ 個",
Unit_x_x: "%a$ x %b$ 個",
UnitAbbreviation: "単位の略称",
UnitAccount: "単位アカウント",
UnitEntry: "単位エントリ",
UnitLogo: "単位のロゴ",
UnitManagement: "単位の管理",
UnitName: "単位名",
UnitNumber: "単位番号",
UnitNumber_x: "単位番号：%a$",
UnitNumberOrUnitName: "単位番号または単位の略称",
Unknown: "不明",
Unselected: "未選択",
UnStaffAdd: "追加",
Unsubmitted: "未提出",
Untie: "解除",
UnusedTicketTitle_x: "旅客: %a$は次の旅程の未使用の航空券を持っています。",
Update: "即時アップデート",
Update_1: "更新する",
UpdateSearchWhenPullingMap: "マップを引っ張ると検索を更新",
Upgrading: "アップグレード中",
UploadCertificate: "証明書のアップロード",
UploadedCertificate: "アップロードされた証明書",
UploadFile: "ファイルのアップロード",
Upper: "上",
Upscale: "高級",
UrbanCivision: "としぶんかつ",
USD: "USD",
UseCar: "車を利用する",
UsedForCheckingInAtTheHotelFrontDeskOrForProcessingDestinationVisas: "ホテルフロントでのチェックイン、または渡航先ビザ申請時",
UseLxd: "使用",
UseLxd_x: "最大%a$のトラベルビーンズを使用できます",
UserCanceled: "ユーザーがキャンセルしました",
UserName: "利用者ID",
UserNameEmailMobile: "12306ユーザー名/メール/携帯番号",
UseRules: "利用規則",
UseTheCar_x: "%a$ の車を利用する",
UseTheCarImmediately: "すぐに車を利用する",
UsingTheLastestVersion: "すでに最新バージョンを使用しています",
ValidationCertificateInformationNotPassed_x: "%a$の証明書情報検証が失敗しました",
ValidPeriod: "有効期限",
VehicleDisinfection: "車両の消毒",
VehicleTrack: "車両のトラック",
VerificationCode: "確認コード",
VerificationCodeAgain: "確認コードを再取得",
VerificationCodeAgain_1: "再取得",
VerificationCodeAgain_1_x: "認証コードは既に送信されました。SMSを確認してください。受信していない場合、%a$で。",
VerificationCodeAgain_x: "認証コードは既に送信されました。SMSを確認してください。受信していない場合、%a$で再取得できます。",
VerificationCodeCheck: "SMS確認コードを入力して検証を完了する",
VerificationCodeCheckFail: "確認コードのチェックに失敗しました",
VerificationCodeCoped: "確認コードがコピーされました",
VerificationCodeCopiedSuccessful: "確認コードが正常にコピーされました",
VerificationCodeFailedToSend: "確認コードの送信に失敗しました",
VerificationCodeGet: "確認コードを取得",
VerificationCodeHasBeenSent: "確認コードが送信されました",
VerificationSucceeded: "検証に成功しました、合格です！",
VerificationSuccessful: "検証成功",
VerifiedPhoneNumber: "検証済みの携帯電話番号",
VerifyUsingMail: "電子メールによる確認",
VerifyUsingPhoneNumber: "携帯電話番号による認証",
VersionNumber: "バージョン",
VersionUpdate: "バージョンの更新",
ViewAllStaff: "全従業員を表示可能",
ViewBookPermissions: "表示/サブスクリプション権限",
ViewComments: "コメント",
ViewConfirmNumber: "確認シートを見る",
ViewDesignated_x: "指定を表示できます%a$",
ViewDesignatedDepart: "指定された部門を表示できます",
ViewDesignatedStaff: "指定した従業員を表示できます",
ViewDirectTicket: "直行便チケットを表示",
ViewElectronicPolicy: "保険証券の表示",
ViewItineraryTrack: "行程のトラックを表示",
ViewMoreTransferSchemes: "他の乗り継ぎ案内を表示",
ViewOnlySelf: "本人のみ表示",
ViewOrder: "表示",
ViewOtherPrices_x: "他の%a$の価格を表示",
ViewPermissions: "アクセス権の表示",
ViewRoomType: "部屋タイプを見る",
ViewTheBigMap: "大きな地図を表示",
ViewTheLowestPrice: "最低価格のフライトを表示",
ViewTravelApplicationForm: "出張申請書を表示",
ViolationOfBusTravelRank: "車券出張基準違反、選択が必要な理由",
ViolationOfCarTravelRank: "車両の出張基準に違反しました。選択する必要がある理由。",
ViolationOfHomsomTravelStandardsNotSelectable: "貴社の出張基準に違反して、選択できません",
ViolationOfHomsomTravelStandardsSelectedReason: "貴社の出張基準に違反した場合、原因を選択する必要があります：",
ViolationOfHotelTravelRank: "ホテルの出張基準に違反しました。選択する必要がある理由。",
ViolationOfMealsTravelRank: "食事の出張基準に違反しました。選択する必要がある理由。",
ViolationOfRank: "基準の違反",
ViolationOfRankDetail: "基準の違反の詳細",
ViolationOfRankReason_x: "基準の違反の理由：%a$",
ViolationOfTrainTravelRank: "鉄道の出張基準に違反しました。選択する必要がある理由。",
ViolationOfTravelRank: "出張基準の違反",
VoidApplicationForm: "請求書の無効化",
VoidedFailed: "無効化に失敗しました",
VoidedSuccessfully: "無効化成功",
VoluntaryRefund: "自主的な払い戻し",
VoluntaryRefundDesc: "自主的な払い戻し(航空会社の払い戻しと変更のルールに従います)",
VoluntaryRefundExplain: "自主キャンセルの説明",
VoluntaryReschedule: "自主的な日程変更",
VoluntaryRescheduleDesc: "自主的な日程変更(航空会社の払い戻しと変更のルールに従います)",
VoluntaryRescheduleExplain: "任意改期の説明",
Voucher: "クーポン",
WaitingForConfirmation: "確認待ち",
WarmTipsWhitelistedEmployees: "ヒント：ホワイトリストの従業員は承認の必要がありません。",
WarmTipTheModificationIsNotSupportedForTheTimeBeing: "ヒント：現時点では変更はサポートされていません。変更が必要な場合は、専属のコンサルタントにお問い合わせください。",
Wechat: "WeChat",
WeChat_Limit_1: "アプレットの制限があるため、恒常ビジネス旅行APPまたはWebサイトでこの操作を行ってください",
WechatByAccount: "企業用WeChatアカウント",
WechatByUser: "企業用WeChatアカウントの従業員バインディング",
WechatIsNotInstalled: "WeChat がインストールされていません",
WechatNotSupportDDPleaseUseAppOrH5: "WeChatミニプログラムはDidiカーをサポートしていません。アプリまたはh5を使用して予約してください",
WechatNotSupportOnlineCheckInPleaseOpenOnPcOrApp: "WeChatミニプログラムはオンラインチェックインをサポートしていません。PC、アプリ、またはh5を使用して開きます",
WechatPay: "WeChat Pay",
WeekFri: "金",
WeekFri_1: "金曜日",
WeekMon: "月",
WeekMon_1: "月曜日",
WeekSat: "土",
WeekSat_1: "土曜日",
WeekSun: "日",
WeekSun_1: "日曜日",
WeekThur: "木",
WeekThur_1: "木曜日",
WeekTues: "火",
WeekTues_1: "火曜日",
WeekWed: "水",
WeekWed_1: "水曜日",
Welcome: "Hi、ようこそ！",
Welcome_x: "%a$，ようこそ！",
WelcomeToHOMSOM: "HOMSOMへようこそ",
WelcomeToHumanCustomerService: "人間のお客様サービスへようこそ",
WelcomeToLogin: "こんにちは、ようこそログイン",
WelcomeToLoginHomsom: "ログインへようこそ",
WhetherToAcceptTheSeatlessTicket: "選択した座席が利用できない場合、座席なしのチケットを受け入れますか？",
WhetherToConfirmTheDeletionOfThisFavoriteContact: "このお気に入りの連絡先を削除しますか？",
WhetherToConfirmThePasswordChange: "パスワードの変更を確認しますか？",
WhetherToConfirmTheRefund: "払い戻しを確認しますか？",
WhetherToSendARefundEmail: "払い戻しメールを送信しますか？",
WhetherToSendARefundEmail_1: "払い戻しメールを送信する",
WhetherToSendARefundSuccessfulMail: "払い戻し成功メールを送信しますか？",
WhetherToSendARefundSuccessfulMail_1: "払い戻し成功メールを送信する",
WhetherToSendARefundSuccessfulSMS: "払い戻し成功SMSを送信しますか？",
WhetherToSendARefundSuccessfulSMS_1: "払い戻し成功SMSを送信する",
WholeRoute_x: "全行程%a$",
Window: "窓",
Window_1: "窓際",
WindowType: "窓の種類",
Withdrawal: "引き出し",
Work: "勤務",
WriteReasonCode: "ReasonCodeを記入する",
XFormatIsIncorrect_x: "%a$の形式が正しくありません",
XHoursBeforeAndAfterTheLowestPrice_x: "最低価格 %a$ 時間前後",
XIDIsWrong_x: "ID検証に失敗しました(%a$)",
XIsUnderXyearsOld_x: "%a$は12歳未満です",
XOfFlight_x: "フライト%a$",
XPeoplePerRoom_x: "1部屋あたり%a$人",
XTrips_x: "合計%a$便",
Year: "年",
Year_x: "%a$年",
YearOnYear: "前年比",
Yesterday: "前日",
You: "あなた",
YouAlsoHaveUnselectedPassengers: "未選択の乗客がまだいます。注文を送信する前に座席を選択してください！",
YouCanDoTheFollowing: "以下の操作ができます",
YouCannotSelectContactsWithTheSamePhoneNumberAtTheSameTime: "同時に同じ電話番号の連絡先を選択することはできません",
YouCanOnlyGetSMSVerificationCodesFor3MobilePhoneChecksPerDay: "*1ユーザーあたり1日に3回しか携帯電話のチェックメッセージ検証コードを取得できない",
YouCanOpenItInMySettingSecurityManager: "マイ-設定-セキュリティマネージャーで開くことができます",
YouCanUseFaceIDVerification: "Face ID認証を使用してログインを迅速に完了できます",
YouCanUseTouchIDVerification: "Touch ID認証を使用してログインを迅速に完了できます",
YouHaveACarOrderInProgress: "進行中の車の注文があります",
YouHaveToDoFaceRecognitionAlone: "顔認識は一人で行う必要があります",
YouHaveUnselectedPassengersPleaseSubmitAfterSeatSelection: "未選択の乗客がいますので、座席選択後に送信してください",
YourCompanyAllowsYouToPayTheExtraAmountForAnOutPolicyHotelByCreditCardWeChatOrAlipay: "会社は外部方針のホテルの追加料金をクレジットカード、WeChat、またはAlipayで支払うことを許可しています",
YourCurrentLoginAccountNeedsMobilePhoneNumberVerification: "現在のログインアカウントには携帯電話番号の検証が必要です",
YourDateOfBirthGenderNationalityAndIDCardDoNotMatch: "生年月日、性別、国籍、身分証が一致していませんが、修正されました",
YourExclusiveTravelConsultant: "専属の旅行コンサルタント",
YourHotelGroupMembershipCard: "あなたのホテルグループ会員カード",
YourOrderHasNotBeenCompletedOut: "注文が完了していません。現在のページから移動してもよろしいですか？",
YourOrderHasNotBeenFilledOut: "注文が完了していません。現在のページから移動してもよろしいですか？",
YourPassengersAreFullToDelete12306_x: "あなたの12306の乗客は満員です。%a$人の乗客を削除すると、今回のチケット購入を完了できます",
YourPendingApplicationForm_x: "未処理の申請書があります(%a$)",
YourPhoneNumberHasNotBeenRegisteredYet: "あなたの携帯番号はまだ登録されていません",
YourStayIsTooLongTheFlightAndPriceInformationMayChangeAndTheLatestPrice: "滞在期間が長すぎます。フライトと価格情報は変更される可能性があります。最新の価格を確認中です",
YourTravelCard: "あなたのトラベルカード",
YouWill: "出発地：",
YuanStart_x: "%a$元から",
}