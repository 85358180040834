/* eslint-disable */
/* 当前文件对应的国际化词库版本号: 7.9.2.202503251532 */
export default {
About: "About",
AboutUs: "About Us",
AccordingTo12306TicketsCanOnlyBePurchasedAfterThePhoneNumberHasBeenVerified: "According to 12306, tickets can only be purchased after the phone number has been verified",
AccordingToYourCompanysTravelPolicy: "According to your company's travel policy, you did not choose the lowest price cabin flight",
Account: "Account",
AccountLogin: "Account login",
AccountName: "Account name",
AccountNotExist: "User name does not exist",
AccountNumber: "Account number",
AccountNumber_1: "Account number",
AccountProtocol: "《Account authorization agreement》",
AccountProtocol_1: "I have read and agree《Account authorization agreement》",
AccountProtocol_2: "The name, mobile phone number, ID number and other information you provided will be used for the convenient service of retrieving 12306 account and password and further authentication. For more information, please check《Account authorization agreement》",
AccountProtocol_2_x: "The name, mobile phone number, ID number and other information you provided will be used for the convenient service of retrieving 12306 account and password and further authentication. For more information, please check %a$",
AccountVerification12306: "12306 Account Verification",
AccountVerification12306Desc: "The railway bureau stipulates that real-name registration is required for ticket purchases. Please select the following methods for verification",
AccuratelyFillInTheAgeOfEachChildForSpecialOffers: "Accurately fill in the age of each child for special offers",
AContactAlreadyExistsWithTheSamePhoneNumber: "A contact already exists with the same phone number",
ActivityNotice: "Activity Notice",
ActivityNoticeDesc: "Discount activity reminder",
ActualPayment: "Actual Payment",
ActualTotalPrice_x: "Spend %a$",
Add: "Add",
AddAnotherTrip: "Add Another Trip",
AddBusiness: "New(Business)",
AddContact: "Contact ",
AddCostCenter: "Business Trip Subordinate Department",
AddDay_x: "+%a$ days",
AddDesignatedApprover: "Add Designated Approver",
Added: "Added",
AddFailed: "Add Failed",
AddFlight: "Add Flight",
AddFrequentContacts: "Add Contact",
AddFrequentTraveller: "Add as Frequent Traveler",
AddInvoiceHeader: "Add FaPiao Header",
AdditionalTaxesFees: "Additional tax/fee",
AddManually: "add manully",
AddPassenger: "Add Passenger",
AddPersonal: "New(Personal)",
Address: "Address",
AddressDistrict: "Area",
AddReturnTrip: "Add Return Trip",
AddSecurityExplain: "Add security for the peace of your family",
AddSecurityExplain_1: "Travel with security, for the peace of your family",
AddSucceed: "Add succeed",
AddTiedList: "Add to tied list",
AddTransitCity: "Add transit cities to the destination",
AddTravelCard: "Frequent Flyer Card",
AddTraveler: "Add +",
AdministrativeDistrict: "Administrative District",
AdmissionPolicy: "Check-in policy",
Adults: "Adult(s)",
Adults_1_x: "%a$ Adult(s)",
Adults_Children_x_x: "%a$ adult(s) %b$ children",
Adults_x: "Adult %a$",
AdultsDesc: ">12 years old (inclusive)",
AfterClickingGoToPay: "After clicking 'Go to Pay', please open WeChat on your phone and scan the QR code to complete the payment",
AfterClickingGoToPay_1: "After clicking 'Go to Pay', please open Alipay on your phone and scan the QR code to complete the payment",
AfterSendingTextMessage: "After sending the text message, you will receive a six-digit verification code from 12306. Please enter the code.",
Age_x: "Age %a$",
Agree: "Agree",
AgreeAndContinue: "Agree and continue",
Agreement: "Negotiated",
AgreementDesc: "Negotiated description",
AgreementHotels: "Negotiated hotels",
AgreementHotelStarRating: "Negotiated Hotel Star Rating",
AgreementHotelTotal: "Negotiated Hotel Total Amount",
AgreementHotelTotalRoomNight: "Total Room Nights for Negotiated Hotels",
AgreementNight: "Negotiated night",
AirChinaPassengerBaggageDomesticTransportationGeneralConditions: "Air China Passenger Baggage Domestic Transportation General Conditions 2017 Edition (Chinese Version)",
AirfareYouSearchedFor: "Airfare You Searched For",
AirlineList: "Airline list",
AirlinePassengerMaintenance: "Airline Passenger Conditions are under Maintenance ",
AirLowestFareAcceptance: "Lowest Fare Acceptance",
Airport: "Airport",
AirportDropOff: "Airport drop-off",
AirportDropOff_x: "%a$ airport drop-off",
AirportPickup: "Airport pickup",
AirportPickup_x: "%a$ Airport pickup",
AirportStation: "Airport/Station",
AirRouteAnalysis: "Air Route Analysis (Economy Class Only)",
AirRouteAndCarrierAnalysis: "Air Route and Carrier Analysis",
AirTicketBookingInstructions: "Booking Instructions、",
AirTicketConsumptionInDifferentCities: "Air ticket consumption in different cities",
AirTickets: "Airfare",
Aisle: "Aisle",
Alipay: "Alipay",
AliPay: "AliPay",
All: "All",
AllBookings: "My Trips",
AllCabin: "All",
AllCabins: "All cabins",
AllCollection: "All",
AllCompanyPayment: "Company payments",
AllLoaded: "Loading Completed",
AllOrdersForRecentOneMonth: "All travel applications for recent 6 month",
AllOrdersForRecentOneYear: "All orders already be shown",
AllOrdersForRecentOneYear_1: "All orders for recent 1 year",
AllOrdersForRecentSixMonths: "All orders for recent 6 months",
AllSelected: "Select All",
AllTime_x: "The time is approximately %a$",
AllTime_x_x: "%a$, around %b$",
AlreadyHave12306Account: "Already have 12306 account",
AlreadySetting: "Set",
Amount: "Amount",
Amount_1: "Amount (Including Tax)",
AmountOfBillForTheCurrentPeriod: "Amount of bill for the current period",
AmountOfConsumption: "Amount of consumption",
AmountPaidInThisPeriod: "Amount paid in this period",
AmountPayableInTheCurrentPeriod: "Amount payable in the current period",
And: "And",
AndroidPersonalizedRecommendation: "Personalized recommendation",
AndroidPersonalizedRecommendationDesc: "Provide personalized recommendation function to improve booking experience",
AndroidSettingPrivacy: "Privacy settings",
Announcement: "Announcement",
AnnouncementInformation: "Announcement",
Any: "Any",
AnyCity: "Any city",
Appearance: "Appearance",
Applicant: "Applicant",
ApplicantForRefund: "Applicant for refund",
Application: "Application",
ApplicationContent: "Application content",
ApplicationContent_x: "Application content: %a$",
ApplicationDate: "Application date",
ApplicationForApproval: "Application for approval",
ApplicationForBook: "Request Form Booking",
ApplicationFormInformation: "Application form information",
ApplicationInformation: "Application information",
ApplicationIntlFlight: "Int`l flights application",
ApplicationIntlHotel: "Overseas hotel application",
ApplicationNumber: "Application number",
ApplicationToReservedNoData: "No pending application to reserve",
Apply: "Apply",
ApplyForRefund: "Refund",
ApplyRefund: "Apply",
ApplyReschedule_1: "Request reschedule",
ApplyTime: "Application Time",
ApplyTravel: "Travel application",
ApplyTravelConfirm: "Travel application confirmation",
ApplyTravelNeeds: "Travel needs",
ApplyTravelSegment: "Travel information",
ApplyTravelTime: "Travel date",
ApproachingTakeoff: "Approaching takeoff",
ApprovalExpired: "Approval Expired",
ApprovalFail: "Approval failed",
ApprovalFlow: "Approval flow",
ApprovalInformation: "Approval information",
ApprovalInformationDesc: "Approval status reminder",
ApprovalRejection: "Approval Rejected",
Approvals: "Approvals",
Approvals_1: "Approvals",
ApprovalSetting: "Setting",
ApprovalStatus: "Approval status",
ApprovalSubmitted: "Approval submitted",
ApprovalTemplate: "Template",
ApprovalTemplate_1: "Approval template",
ApprovalTemplateDetails: "Template Details",
ApprovalTimeoutNoticeTime: "Approval timeout notice time: %a$ minutes",
ApprovalType: "Type",
ApprovalType_1: "Type",
ApprovalWhitelist: "Approval whitelist",
Approved: "Approved",
ApprovedCanceled: "Application Canceled",
ApprovedPass: "Approved",
Approver: "Approver",
ApproversHaveBeenBound: "Approvers have been bound",
ApproxPay: "Approx",
Area: "Room Size",
AreYouSureToAddThisAsAFrequentTraveler: "Are you sure to add this as a frequent traveler?",
AreYouSureToCancelTheOrder: "Are you sure to cancel the order?",
AreYouSureToCancelUnableToSave: "If canceled, the information on this page cannot be saved. Are you still sure to cancel?",
AreYouSureToChange: "Are you sure to change?",
AreYouSureToChangeTheCurrency: "Are you sure to change the currency?",
AreYouSureToChangeTheLanguage: "Are you sure to change the language?",
AreYouSureToChangeYourFlightToTheCheapestFlight: "Please confirm if you want to change your flight to the cheapest flight?",
AreYouSureToDelete_x: "Are you sure to delete %a$?",
AreYouSureToDeleteTheApprover: "Are you sure to delete the approver?",
AreYouSureToDeleteTheCertificate: "Are you sure to delete the certificate?",
AreYouSureToDeleteTheEmployeeFromTheWhitelist: "Are you sure to delete the employee from the whitelist?",
AreYouSureToDeleteTheRC: "Are you sure to delete the RC?",
AreYouSureToDeleteTheSelectedCollection: "Are you sure to delete the selected collection?",
AreYouSureToDeleteThisCustom: "Are you sure to delete this custom item?",
AreYouSureToDeleteThisEmployeeFromTheTemplate: "Are you sure to delete this employee from the template?",
AreYouSureToPayForThisOrder: "Are you sure to pay for this order?",
AreYouSureToRemove_x: "Are you sure to remove %a$?",
AreYouSureToRemoveItFromTheCostCenterList: "Are you sure to remove it from the cost center list?",
AreYouSureToRemoveItFromTheDepartmentsList: "Are you sure to remove it from the departments list?",
AreYouSureToRemoveItFromTheEmployeeList: "Are you sure to remove it from the employee list?",
AreYouSureToRemoveItFromTheFrequentTravellerList: "Are you sure to remove it from the frequent traveler list?",
AreYouSureToRemoveItFromTheNonEmployeeList: "Are you sure to remove it from the external personnel list?",
AreYouSureToRescheduled: "Are you sure to reschedule?",
AreYouSureToSeat: "Confirmation of seats?",
AreYouSureToSubmitAndPayForTheOrder: "Are you sure to submit and pay for the order?",
AreYouSureToSubmitYourOrder: "Are you sure to submit your order?",
AreYouSureToTurnOffFaceIDLogin: "Are you sure to turn off Face ID login?",
AreYouSureToTurnOffTouchIDLogin: "Are you sure to turn off Touch ID login?",
AreYouSureYouWantToDeleteThisAccount: "Are you sure you want to delete this account?",
AreYouSureYouWantToGiveUpTheInformationChange_x: "Are you sure you want to give up the information change for %a$?",
AreYouSureYouWantToLogout: "Are you sure you want to log out of this account?",
AreYouSurYouWantToDeleteThisAccount: "Are you sure you want to delete this account?",
AreYouSurYouWantToLogout: "Are you sure you want to log out of this account?",
AreYouSurYouWantToUntieThisAccount: "Are you sure you want to unbind this account?",
Arrival: "Arrival",
ArrivalCity: "Arrival City",
ArrivalCityAddress: "Destination",
ArrivalCityAddressDesc: "Add transit city",
ArrivalCityOptions: "Arrival City Options",
ArrivalDate: "Arrival Date",
ArrivalEarliest: "Arrival (Earliest)",
ArrivalLatest: "Arrival (Latest)",
ArrivalStation: "Arrival Station",
ArrivalStation_1: "Arrival Station",
ArrivalTerminal: "Arrival Terminal",
ArrivalTime: "Arrival Time",
ArrivalTime_1: "Arrival Time",
ArrivalTime_2: "Arrival",
ArrivalTimeDepartureDate: "Arrival Time/Departure Date",
ArrivalTimeIsLocalTime: "Arrival Time is Local Time",
Arrive: "Arrival",
ArriveAddress: "Arrival Address",
Associated12306Account: "Associated 12306 Account",
AtNoonToday: "noon",
AToB_x_x: "%a$ - %b$",
AttachFileUpload_x: "Can upload %a$ more",
AttachFileUploadExplain_1: "·Up to 10 files can be uploaded, each file size does not exceed 8M",
AttachFileUploadExplain_2: "·Supported file types are img, word, ppt, excel, pdf",
AUD: "Australian Dollar",
Authentication: "Authentication",
AuthenticationProtocol: "《Authentication Protocol》",
AuthenticationProtocol_1: "已阅读并同意《Authentication Protocol》",
Available: "Available",
Available_x: "Available %a$",
AvailableIntegral: "Available Integral",
AvailableTickets: "Available",
AverageBusinessTravelFare: "Average business travel fare",
AverageDiscount: "Average discount",
AverageDomesticDiscount: "Average domestic discount (economy class)",
AverageHotelPrice: "Average hotel price",
AverageMileagePrice: "Average Mileage Price (Economy Class Only)",
AverageNumberOfDaysBookedInAdvance: "Average Number of Days Booked in Advance",
AveragePrice: "Average price",
AveragePriceOfBusinessTravelAgreement: "Average Price of Business Travel Negotiated",
AveragePriceOfBusinessTravelMileage: "Average Price of Business Travel Mileage",
AverageTicketPrice: "Average ticket price",
AverageTrainTicketPrice: "Average train ticket price",
AVerificationMessagePleaseEnterTheVerificationCodeYouReceived: "A verification message has been sent. Please enter the verification code you received.",
Avg: "Avg",
AvgTicketPriceOfMileage: "Avg. Ticket Price of Mileage",
AwayFrom: "Away from",
B: "B",
Baby: "Baby",
Baby_1_x: "%a$ Baby",
Baby_x: "Baby %a$",
BabyDesc: "14 days (inclusive) -2 years old (exclusive)",
BabyNonBookable: "Baby Not Bookable",
BabyNonSupportBookable: "For booking baby tickets, please contact your travel consultant",
BabyReQuery: "Baby is not bookable for this flight. Please re-query for available flights for infants",
BabyTooSmallNonBookable: "Infants under 14 days old cannot be booked",
BabyWith: "With Baby",
BabyYearsOld: "14 days - 2 years old",
Back: "Back",
BackFlight: "Return flight",
BackModify: "Go Back to Modify",
BackTag: "Return",
BackToHome: "Back to home",
BackToHotelList: "Back to hotel list",
BackToLogin: "Back to login",
BackWeek_x: "%a$",
Baggage: "Baggage",
BaggageAllowance_x: "Baggage policy：: %a$",
BaggagePolicy: "Baggage Information",
Balance: "balance",
Bank: "Bank",
BankAccount: "Account",
BankCardInformation: "Credit Card Information",
BasicInformation: "Information",
BasicInformationIsIncompletePleaseEdit: "Basic information is incomplete. Please edit",
BasicInformationIsIncompletePleaseEdit_x: "Basic information is incomplete. Please edit %a$",
Batch: "Batch",
BatchPass: "Batch pass",
BecomeDue_x: "Expires on %a$",
BedType: "Bed Type",
BedWide_x_x: "Width %a$ x %b$",
BeijingTime: "Beijing Time",
BeingApprovalByTheFlightCompany: "Waiting for flight company approval",
BeingVerified: "Being verified",
BelongCity: "City",
Below: "D",
BeOverdueByCarPleaseCheckAgain: "Car information may have changed. Please check again",
BeOverdueByFlightPleaseCheckAgain: "Flight information may have changed. Please check again",
BeOverdueByHotelPleaseCheckAgain: "Hotel information may have changed. Please check again",
BeOverdueByTrainPleaseCheckAgain: "Train information may have changed. Please check again",
BigBed: "Queen",
BillEndDate: "Bill end date",
BillGrouping: "Bill grouping",
BillingPeriod: "Billing period",
BillStartDate: "Bill start date",
Bind: "Bind Now",
BindFailed: "Bind failed",
Binding: "Bind",
BindingCTrip: "Bind CTrip Membership",
BindingCTripDesc: "We will use the phone number you provided to bind the CTrip travel personal membership. If you are not a CTrip member yet, Hengshun Business Travel will register a CTrip membership for you using that phone number.",
BindingCTripPrivacy: "Read and agree to CTrip's Terms of Service and Privacy Policy",
BindingCustomItems: "Bind custom items",
BindingEmployees: "Binding employees",
BindingHuazhu: "Bind Huazhu Membership",
BindingHuazhuDesc: "We will bind your information with Huazhu affiliated units. If you are not a Huazhu member yet, Hengshun Business Travel will use that information to register a Huazhu membership for you.",
BindingHuazhuPrivacy: "Read and agree to Huazhu's Terms of Service and Privacy Policy",
BindingLogin: "Authorization binding",
BindingMeituan: "Bind Meituan Membership",
BindingMeituanDesc: "We will bind your information with Meituan affiliated units. If you are not a Meituan member yet, Hengshun Business Travel will use that information to register a Meituan membership for you.",
BindingMeituanPrivacy: "Read and agree to Meituan's Terms of Service and Privacy Policy",
BindingStatus: "Binding status",
BindMailbox: "Bind Email",
BindMembershioFor_X: "Binding %a$ membership card",
BindMembersToEnjoyLowerPrices: "Bind members to enjoy lower prices",
BindPerson_x: "Bound %a$ person(s)",
BindSuccessfully: "Successfully Bound",
BlankImport: "Blank Import",
Book: "Book",
Book_1: "Book",
Book_2: "Book",
Book_3: "Book",
BookAllStaff: "Book for all employees",
BookCar: "Book Car",
BookDesignated_x: "Booking for specified %a$",
BookDesignatedDepart: "Booking for a designated department",
BookDesignatedStaff: "Booking for designated employees",
BookDomesticFlights: "Book domestic air tickets",
BookDomesticHotel: "Book domestic hotels",
Booker: "Booker",
BookFinished: "Sell out",
BookFlight: "Book flight",
BookHotel: "Book hotel",
BookInAdvance: "Book in advance",
Booking: "Book",
BookingDaysInAdvance: "Booking days in advance",
BookingInformation: "Booking information",
BookingInstructionsAndInstructionsForReturning: "Booking Instructions and Cancellation Policy",
BookingLevel: "Booking level",
BookingMail: "Booking email",
BookingMethods: "Booking Methods",
BookingRange: "Booking range",
BookingSMS: "Booking SMS",
BookingSource: "Booking source",
BookingSucceed: "Booking successful",
BookingTheBus: "Book bus tickets",
BookingTheCar: "Book the car",
BookingTheMeals: "Book dining",
BookInternationalFlights: "Book international flights",
BookInternationalHotel: "Overseas hotel reservation",
BookOnlySelf: "Booking for myself only",
BookPermissions: "Booking permissions",
BookTheLowestLogicalFare: "Lowest price",
BookTrain: "Book train",
BookType: "Booking Type",
Bound: "Bound",
Bound_1: "Bound",
BoundCustomItems: "Bound custom items",
BoundEmployees: "Bound employees",
Brand: "Brand",
Breakfast: "Breakfast",
Breakfast_1: "Breakfast",
BrowserOpens: "Browser opens",
Budget: "Budget",
Budget_1_x: "%a$ Budget",
Budget_x: "Budget%a$",
BudgetAmount: "Budget amount",
BulkRejection: "Bulk rejection",
Business: "Business",
Business_x: "%a$ (Business)",
BusinessCenter: "Business center",
BusinessClass: "Business class",
BusinessConsultant: "Business consultant",
BusinessDistrict: "Business district",
BusinessDistrictInfrastructureBrandEtc: "Business District/Infrastructure/Brand, etc.",
BusinessTravel: "Business travel",
BusinessTravelScale: "Enterprise travel scale (monthly)",
BusinessTrip: "Business Trip",
BusinessType: "Business type",
BusinessType_1: "Business Type",
BusinessUnit: "BusinessUnit",
BusOrderConfirmation: "Bus ticket order confirmation",
BusOrderDetails: "Bus ticket details",
BusRank: "Bus standard",
BusRefundOrderConfirm: "Confirmation of bus ticket refund order",
BusStation: "Bus Station",
BusTicket: "Bus ticket",
BusTicketOrder: "Bus ticket order",
By: "By",
By_x: "%a$",
ByMail: "By mail",
ByPhoneNumber: "By phone number",
Cabin: "Cabin",
CabinInformationHasBeenUpdated: "The cabin information has been updated, and some contents of the order may have changed. Please fill out the order again to ensure accuracy",
CabinLevel: "Cabin Class",
CabinType: "Cabin type",
CAD: "Canadian Dollar",
Calendar: "Calendar",
CallCar: "Call car",
CallingForYou: "Calling for you...",
CallPhone: "Call",
CallThePolice: "Call the police",
Camera: "Camera",
CanAccommodateUpToXPeople_x: "Room sleeps up to %a$ guests",
CanAccommodateXPeople_x: "Room sleeps %a$ guests",
Cancel: "Cancel",
Cancel_1: "Cancel",
Cancel_2: "Cancel Management",
CancelAnyway: "Cancel",
Canceled: "Canceled",
CancelFailed: "Cancel failed",
CancelFee: "Cancellation Fee",
CancelForFree: "Cancel for Free",
CancelInformation: "Cancel Information",
CancelInquiry: "Cancel Inquiry",
Cancelling: "Cancelling",
CancelOrder: "Cancel",
CancelReason: "Reason",
CancelRefund: "Refund Cancellation",
CancelRules: "Cancellation terms",
CancelSucceed: "Cancel Succeed",
CannotBeCancelled: "Cannot be cancelled",
CanNotSupportedFileType: "This file type is not supported, please select again",
CanOnlySelectUpToXGuest_x: "Each room can only select a maximum of %a$ occupants",
CanOnlySelectUpToXpassengers_x_x: "Choose at most %a$ %b$",
CanPeopleStaying_x: "Can accommodate %a$ people",
Car: "Car",
CarAirport: "Airport transfer",
CarApplication: "Car application",
CarbonEmission: "Carbon emission",
CarbonOffset: "Carbon offset (planting several trees)",
CardholderInformation: "Cardholder Info",
CarDistance_x: "%a$ kilometers",
CardNumber: "Card number",
CardType: "Cardholder Information",
CarDuration_x: "%a$ minutes",
CarFare: "Car Fare",
CarOrder: "Car order",
CarOrderConfirmation: "Car order confirmation",
CarPlatform: "Car Platform",
CarRank: "Car rank",
Carrier: "Carrier",
Carrier_1: "Carrier",
CarrierAnalysis: "Carrier Analysis",
CarriersItineraries: "Carriers & Itineraries",
CarScene: "Car scene",
CarServiceTime: "Car service time",
CarTrain: "Train",
CarType: "Car type",
CellphoneNumber: "Phone",
CertificateInformation: "Certificate information",
Change: "Change",
Change_1: "Change",
Change_2: "Change",
Change_3: "Change",
ChangeDetails: "Schedule Change Details",
ChangeDetails_1: "Change details",
ChangeFailed_1: "Edit failed",
ChangeFee: "Change fee",
ChangeFlight: "Change flight",
ChangeFlightSchedule: "Change flight schedule",
ChangeInstructions: "Change instructions",
ChangeMobileNumber: "Change your number",
ChangeMobileNumber_1: "Change your number",
ChangePassword: "Change password",
ChangePolicy: "Alteration policy",
ChangeRate: "Change rate",
ChangeSchedule: "Change schedule",
ChangeSegment: "Rescheduled",
ChangeStation: "Change station",
ChangeStationTitle: "Station Change Prompt",
ChangeStationWarn_1_x_x: "Your departure station has been changed from %a$ to %b$. Do you want to continue?",
ChangeStationWarn_2_x_x: "Your arrival station has been changed from %a$ to %b$. Do you want to continue?",
ChangeStationWarn_x_x_x_x: "Your departure station has been changed from %a$ to %b$, and your arrival station has been changed from %c$ to %d$. Do you want to continue?",
ChangeSucceed: "Change succeeded",
ChangeSuccessFailed: "Change failed",
ChangeSuccessfully: "Reschedule succeed",
ChangeTheNumberOfPeople_x: "The passenger you have selected has changed and will be adjusted to %a$. If it does not meet your needs, you can modify the passenger yourself",
ChangeTheNumberOfRoomsAndPeople_x_x: "The number of guests you have selected has changed. We will adjust it to %a$ room with %b$ guests. If it does not meet your needs, you can modify the number of guests in the room yourself",
ChangeTo_x: "Change to %a$",
ChangeYourFlightAndContinueDeparting: "Change your flight and continue departing ~",
Changing: "Alteration your ticket now",
Charge: "Charge",
ChargebackDateTime: "Refund application time",
ChargebackDetails: "Refund details",
ChargebackNumber: "Refund No",
ChargebackNumber_x: "Refund No %a$",
ChargebackRoom: "Cancelled rooms",
ChargebackTime: "Cancellation date",
ChargeByAirlineRefundRules: "Charged according to airline refund rules",
ChargedAccordingToTheCorrespondingAirlineTicketRegulations: "Charged according to the corresponding airline ticket regulations (NFD)",
CheapestFlight: "Cheapest flight",
CheapFlights: "Cheap flights",
Check: "Check",
CheckerInformation: "Checker information",
CheckIn: " check in",
CheckIn_x: "%a$ C/I",
CheckInCheckOut_x_x_x: "Check-In: %a$, Check-Out: %b$, %c$ nights",
CheckInCity: "Check-In city",
CheckInCounterIsClosedNotBookedYet: "Check-in counter is closed, not yet booked",
CheckInDate: "Check-In date",
CheckInformation: "Check information",
CheckingForTheLatestPriceForYou: "Checking for the latest price for you, please wait...",
CheckInHotel: "Check-In Hotel",
CheckInRules: "Check-In rules",
CheckInSeat: "Check-in",
CheckInToCheckoutNight_x_x_x: "%a$ - %b$ %c$ night",
CheckInToCheckoutNight_x_x_x_x_x: "Check-In: %a$ %b$, Check-Out: %c$ %d$, %e$ nights",
CheckInToCheckoutNightAndRoom_x_x_x_x: "Check-In to Check-Out: %a$ to %b$, %c$ nights/%d$ rooms",
CheckInToCheckoutNightAndRoomAndRoom_x_x_x_x_x: "%a$ - %b$ · %c$ nights %d$ rooms · %e$",
CheckOrder: "My orders",
CheckOut: "check out",
CheckOut_x: "%a$ C/O",
CheckOutDate: "Check-Out date",
CheckTheCancellationRules: "Cancellation rules",
CheckTheDifference: "Click to View Fare Details",
CHF: "Swiss Franc (CHF)",
ChildOfAge: "Child age",
Children: "Children",
Children_1_x: "%a$ Child",
Children_x: "Children %a$",
ChildrenAndBabyExplain: "Children and infant booking instructions",
ChildrenDesc: "2 years old (inclusive) -12 years old (exclusive)",
ChildrenNonBookable: "Children Non-Bookable",
ChildrenNonSupportBookable: "For booking child tickets, please contact your travel consultant",
ChildrenReQuery: "Children are not bookable for this flight. If you need to travel with children, please re-query for available flights.",
ChildrenWith: "With children",
ChildrenYearsOld: "2-12 years old",
China: "China",
China_1: "China·Hongkong Macao Taiwan",
China_3: "China",
ChinaRegion: "Greater China",
ChinaSouthernAirlinesNewRegulations: "China Southern Airlines New Regulations: Passengers can refund or change their own tickets through the China Southern Airlines app and WeChat, and can directly receive the refund. If passengers request a refund directly from China Southern Airlines without going through your company, we will still charge your company the full amount of the original ticket cost. Please ensure proper supervision to avoid any risks.",
ChineseName: "Chinese name",
Choose: "Choose",
Choose_x: "Select %a$",
ChooseAirport: "Choose airport",
ChooseAtLeastXPeople_x: "Select at least %a$ guests",
ChooseAtMostContact_x: "You can choose up to %a$ contacts",
ChooseBU: "Choose BU",
ChoosePhotos: "Choose photos",
ChooseSnatchSeat_1: "Select high - speed rail /bullet train /inter - city train seats",
ChooseSnatchSeat_2: "Select ordinary express / express / other seats",
ChooseTheLowestPriceToSave: "Choose the Lowest Price to Save",
ChooseTheReason: "Reason for selection",
ChooseTrainStation: "Choose train station",
ChooseUpToXPeople_x: "Choose Up to %a$ People",
CI: "C/I",
CICO: "CI/CO",
CICO_1: "CI/CO",
City: "City",
City_1: "City",
CityAnalysis: "City analysis",
ClassAnalysis: "Class analysis",
ClassHotel: " hotel",
ClassRoomTypeSeat: "Class/Room Type/Seat",
Clear: "Clear",
ClearCache: "Clear cache",
ClearHistory: "Clear history",
ClickLogin: "Click to login",
ClickNextToIndicateThatYouHaveAcceptedAndAreAwareOfTheRefundPolicyForThisTicket: "Click Next to indicate that you have accepted and are aware of the refund policy for this ticket",
ClickRefresh: "Click refresh",
ClickShrink: "Click away",
ClickToResend: "Click to resend",
ClickToSetTheAddress: "set address",
ClickToView: "View",
Close: "Close",
Closed: "Closed",
CloudBill: "Cloud bill",
CloudQuickPay: "Cloud Quick Pay",
CN: "CN",
CNYStart_x: "%a$",
CO: "C/O",
CO2Emissions: "CO2 Emissions",
CodeShare: "Shared Flight",
ComfortHotel: "Comfort hotel",
ComfortHotel_1: "Comfort",
CommentsDetails: "Comments",
CommodityDetails: "Commodity details",
CommonOperations: "Common operations",
Company: "Company",
CompanyAndPersonalPayment_1_x_x: "Company: %a$，Personal: %b$",
CompanyAndPersonalPayment_x_x: "Company: %a$ Personal: %b$",
CompanyInfo: "Unit information",
CompanyInvoiceHeader: "Company Title",
CompanyName: "Company name",
CompanyPayment: "Company payment",
CompanyPaymentPrepay: "Company payment (Prepay)",
CompanyTel: "Company contact phone",
ComplaintsSuggestions: "Complaints/Suggestions",
Completed: "Completed",
CompleteVerification: "Complete verification",
Confirm: "Confirm",
Confirm_1: "Confirm",
Confirm_2: "Confirm",
Confirm_3: "Confirm",
ConfirmationOrder: "Confirmation",
ConfirmDelete: "Confirm Delete",
ConfirmDeparture: "Confirm",
Confirmed: "Confirmed",
ConfirmedCallCar: "Confirmed, Call Car Now",
ConfirmedCheckInTimeAtTheCounterCanBeBooked: "Check-in time at the counter has been confirmed, continue booking",
ConfirmImmediately: "Confirm immediately",
Confirming: "Confirming",
ConfirmMail: "Confirmation email",
ConfirmPay: "Confirm payment",
ConfirmRules: "Confirm rules",
ConfirmSelectedTraveler_x: "Selection of %a$ persons",
ConfirmSelection: "Confirm selection",
ConfirmSMS: "Confirm SMS",
ConfirmTheChanges: "Confirm changes",
ConfirmTheChanges_1: "Confirm changes",
ConfirmTheNewPassword: "Confirm new password",
ConfirmTheNewPassword_1: "Password Confirmation",
ConfirmTheRemovalOfTheirDocuments: "Confirm the removal of their credential?",
ConfirmToResendApprovalInformation: "Confirm to resend approval information?",
ConfirmUnbinding: "Confirm unbinding?",
ConfirmUpdate: "Confirm update",
Consignee: "Consignee",
Consultant: "Consultant",
ConsumptionOfCarsInDifferentCities: "Consumption of cars in different cities",
ConsumptionOfTrainTicketsInEachCity: "Train Ticket Consumption in Each City",
ContactCustomerService: "Contact customer service",
ContactDetails: "Contact details",
ContactDriver: "Contact driver",
ContactEmailFormatIsIncorrect_x: "The email format of contact %a$ is incorrect",
ContactHotel: "Contact us",
ContactInfo: "contact information",
ContactInformation: "Contact",
ContactInformationOfExclusiveConsultant_x: "Contact Information of Exclusive Consultant %a$",
ContactMobileFormatIsIncorrect_x: "The mobile number of contact %a$ is incorrect",
ContactName: "Contact name",
ContactNumber: "Contact number",
ContactNumberColon: "Contact number: ",
ContactPhoneNumber: "Contact phone number",
ContactPhoneNumberCannotBeDuplicated: "Contact phone number cannot be duplicated",
Contacts: "Contacts",
Contacts_x: "Contact %a$",
ContactTheEmailNotificationHasBeenTurnedOn_x: "Email notification function has been enabled for contact %a$ Email address cannot be empty!",
Content: "Content",
ContentIsEmpty: "The content is empty",
Continue: "Continue",
ContinueBooking: "Continue booking",
ContinueBooking_1: "Continue booking",
ContinueBookingWithoutRescheduling: "Keep booking",
ContinuetoBook: "Continue to book original flight",
ContinueToChange: "Continue to change the ticket",
Copy: "",
Copy_1: "Copy",
CopyInvitationLink: "Copy invitation link",
CopyLink: "Copy PDF link",
CopySuccess: "Copied to clipboard",
CopyTheLink: "Copy the link",
CopyToClipboard: "Copy to clipboard",
CorporateTourism: "Corporate tourism",
CostCenter: "Cost center",
CostCenterName: "Cost center name",
CreationDate: "Creation date",
CreationTime: "Creation time",
Credential: "Credential",
CreditCard: "Credit card",
CreditCardGuarantee: "Credit card guarantee",
CreditGuarantee: "Credit guarantee",
Cross: "P",
Currency: "Currency",
CurrencyExplain_x: "Currency: %a$ per adult ticket",
CurrencySelectedIncorrectly: "Currency selected incorrectly. Please select again.",
CurrencySetting: "Currency setting",
Current: "Current",
CurrentAdvancePayment: "Current prepaid",
CurrentCityNoCarService: "Car service is currently unavailable in this city during this time period.",
CurrentIntergral: "Current points",
CurrentLxd: "Available Lxd",
CurrentlyBusiness: "Currently only business inquiries are available",
CurrentlyOnlineInsuranceOnlySupportsTheFirstSegment: "Currently, online insurance only supports purchasing the first segment.",
CurrentlyOnlyDomesticAirTickets: "Currently, only domestic air tickets are supported. Please select the reason for violating the airline travel policy.",
CurrentlyPrivate: "Currently, only private inquiries are available.",
CurrentMobile: "Current mobile number: ",
CurrentOrderGeneratedOrder_x: "Currently, %a$ orders have been generated from the current order.",
CurrentOrderGeneratedOrderYouNeedToPayInTurn_x: "Currently, %a$ orders have been generated from the current order, and you need to pay for them in turn.",
CurrentPageDownload: "Current page download",
CurrentPassword: "Current password",
CurrentPosition: "Current position (for reference only)",
CurrentVersion: "Current version",
CustomerChoosesThePrice: "Customer chooses the price",
CustomerExperienceFirst: "Customer experience first",
CustomerServiceSupport: "Customer service support",
CustomItems: "Custom items",
CustomizationsBound: "Customizations bound",
CustomUseLxd: "Custom Use of Lxd",
D: "S",
Dark: "Dark Mode",
DataUpTo: "Data up to",
Date: "Date",
DateIsTheLocalDateOfDepartureCity: "The date you have selected is the current date of the departure city.",
DateOfBirth: "Date of birth",
DateOptions: "Date options",
Days_1_x: "+%a$ day(s)",
Days_x: "%a$ day(s)",
DeductionLxd: "Deduction",
DeductionRequired: "Deduction required",
Default: "Default",
Delete: "Delete",
Delete_1: "Delete",
DeleteSuccessFailed: "Deletion failed",
DeleteSuccessful: "Delete successful",
DeliveryAddress: "Delivery address",
DeliveryAfterSuccessfulBooking: "Receive upon successful booking",
DeliveryInfo: "Delivery info",
DeliveryMethod: "Delivery method",
DepartingColon: "Depart:",
Department: "Department",
DepartmentName: "Department name",
Departure: "Departure",
Departure_1: "Department",
Departure_x: "Depart from %a$",
DepartureCity: "Departure city",
DepartureCityAddress: "Departure",
DepartureCityAndArrivalCityCannotBeSame: "Departure city and arrival city cannot be the same",
DepartureCityAndArrivalCityCannotBeSame_x: "Departure city and arrival city cannot be the same for leg %a$",
DepartureCityOptions: "Optional departure city",
DepartureDate: "Departure date",
DepartureDateLocation: "Departure date (Local Time)",
DepartureDateOptions: "Optional departure date",
DepartureEarliest: "Departure(Earliest)",
DepartureEarliest_1: "Departure(Earliest)",
DepartureLatest: "Departure(Latest)",
DepartureLatest_1: "Departure(Latest)",
DepartureStation: "Departure station",
DepartureTerminal: "Departure terminal",
DepartureTime: "Departure time",
DepartureTime_1: "Departure station/Time",
DepartureTime_2: "Departure",
DepartureTime_3: "Departure",
DepartureTimeCheckInDate: "Departure time/Check-in date",
DepartureWeek_x: "%a$",
DesignateApprover: "Designate approver",
Destination: "",
DestinationOptions: "Optional destination",
Detail: "Detail",
Details: "Details",
Details_1: "Details",
DetailsOfThisMonth: "Details of this month",
DidiDriverIsComing: "Driver is coming",
DifferentFromTheDepartingAirport: "Different from the departing airport",
DifferentFromTheOriginalScheduledArriveAirport: "Arrival at the airport is different from the original itinerary",
DifferentFromTheOriginalScheduledDepartureAirport: "Different departure airports from the original itinerary",
DifferentFromTheOriginalScheduledDepartureAndrArriveAirport: "Different departure and arrival airports from the original itinerary",
DiffPrice: "Price difference",
Dimension: "Dimension",
Dimension_1: "Dimension",
Diner: "Diner",
DinerDetail: "Diner details",
DinerInfomation: "Diner Information",
DinerName: "Diner name",
Dingtalk: "DingTalk",
DingTalkByAccount: "DingTalk account",
DingTalkByUser: "DingTalk employee binding",
Direct: "Direct",
DirectFlightFirst: "Direct flight first",
DirectionsForUse: "Directions for use",
Disagree: "Disagree",
DiscountDistribution: "Discount distribution",
DiscountInterval: "Discount interval",
DisplayTicketPrice: "Show Ticket Price",
DistanceCenter_x_x: "Distance from city center %a$·%b$",
DistanceFromYou_x: "Distance from you %a$",
DistanceLowToHigh: "Distance (Low to high)",
DistanceNearToFar: "Distance (Near to far)",
DistanceToDestination_x: "Distance to destination %a$",
DocumentInformationVerificationFailed: "Document information verification Failed",
DocumentSignatory: "Document signatory",
DomAverageDiscount: "Dom. Average Discount(only for economy class)",
DomesticFlights: "Domestic flights",
DomesticFlights_1: "Domestic flights(Excluding Hong Kong, Macau, and Taiwan regions)",
DomesticFlightsApplication: "Domestic flights application",
DomesticFlightsOrderConfirmation: "Domestic flights order confirmation",
DomesticHotel: "Domestic hotel",
DomesticHotel_1: "Domestic hotel(Including Hong Kong, Macau, and Taiwan regions)",
DomesticHotelApplication: "Domestic hotel application",
DomesticHotelOrderConfirmation: "Domestic hotel order confirmation",
DomesticHotelOrders: "Domestic hotel orders",
DomesticServicePhone: "Domestic service hotline",
DomesticTicketChange: "Domestic ticket change",
DomesticTicketChangeApplication: "Domestic ticket change application",
DomesticTicketOrder: "Domestic flight order",
DomesticTicketRefundApplication: "Domestic ticket refund application",
DomFullFareTicketRate: "Dom.Full-Fare ticket rate",
DoNotChange: "Do Not Change",
DoNotChangeBack: "Non-refundable/Non-changeable",
DoNotUse: "Do Not Use",
DoNotUseLxd: "Do Not Use Lxd",
DoubleSIMDeviceTip: "Current device is a dual SIM device. Please select the number to send the message.",
Download: "Download",
DownloadAll: "Download all",
Downloaded: "Downloaded",
DownloadFailed: "Download failed",
Downloading_x: "Downloading...%a$%",
DownloadPolicy: "Download",
DownloadSchedule: "Download schedule",
DownloadTheQRcode: "Download QR Code",
DowntownCenter: "Downtown center",
DoYouWantToLeaveHumanCustomerService: "Do you want to leave the human customer service?",
DoYouWantToSwitchToPerson: "Do you want to switch to a membership account?",
DoYouWantToSwitchToUnit: "Do you want to switch to a company account?",
DrillMultipleChoice: "Rating (multiple)",
DriverArrival: "Driver arrival",
DropDownToViewHistory: "Drop down to view message history",
Duration: "Duration",
DutyParagraph: "Company Tax ID",
DwellTime: "Dwell time",
Dysfunction: "Dysfunction",
E: "End",
EachRoomNeedsToFillIn_x: "%a$ guest per room",
EarlyThisMorning: "Morning",
EarlytoLate: "Early to Late",
Economic: "Economic",
EconomicHotel: "Economy hotel",
EconomicHotel_1: "Economic",
Economy: "Economy",
EconomyClass: "Economy class",
EconomyClassDiscountBelow_x: "Economy class (%a$ discount and below)",
Edit: "Edit",
Edit_x: "Edit %a$",
EditContact: "Edit contact",
EditContactFailed: "Failed to edit contact",
EditCostCenter: "Edit cost center",
EditDepartment: "Edit department",
EditFrequentContact: "Edit frequent contact",
EditInvoiceHeader: "Edit FaPiao header",
EditRC: "Edit RC",
Effective: "Effective",
EffectiveDate: "Effective date",
ElectronicInvoice: "Electronic FaPiao",
Email: "Email",
EmailAddress: "Email address",
EmailApproval: "Email approval",
EmailChineseOrEnglishForFlight: "·Confirmation email in Chinese/English (PDF attachment of Chinese/English flight ticket confirmation form)",
EmailChineseOrEnglishForTrain: "·Confirmation email in Chinese/English (PDF attachment of Chinese/English train ticket confirmation form)",
EmailFormatIsIncorrect: "Email format is incorrect!",
EmailFormatIsIncorrect_x: "Email format for %a$ is incorrect",
EmergencyContactMobile: "Emergency contact mobile",
EmergencyContactName: "Emergency contact name",
EmergencyContactNumber: "Emergency contact number",
EmergencyHelp: "Help",
Emission: "Emission (kg)",
EmployeeDetails: "Employee details",
EmployeeInformation: "Personnel information",
EmployeeManagement: "Employee",
EmployeeNumber: "Employee number",
EmployeesCanApply: "Employees can apply to join the team by accessing the link",
EmployeesCcanScan: "Employees can scan the QR code to join the team",
EmptyFileCannotBeUploaded: "Empty file cannot be uploaded, please select again",
EN: "EN",
EnableFingerprintLogin: "Enable fingerprint login",
End: "End",
EndDate: "End date",
EnglishName: "English name",
EnquiryOrderNumber: "Requirement number",
EnterANewPassword: "Enter new password",
EnterflightNoMU5166: "Enter flight number, e.g., MU5166",
EnteringKefu: "Connecting to customer service",
EnterMembershipNumber: "Please enter your membership card number",
EnterNewMobile: "Enter new mobile number",
EnterPhoneNumber: "Enter phone number",
EnterpriseCertification: "Authentication",
EnterpriseCooperation: "Enterprise cooperation",
EnterTheLastFourDigitsOfYourID: "Enter the last four digits of your ID",
EnterVerificationCode: "Enter verification code",
EnterVerificationCode_1: "Enter the verification code",
EnterVerificationCodeForThe12306: "Enter the verification code replied by 12306",
EnterYourNewPasswordAgain: "Re-enter the new password",
Estimate: "Estimate",
EstimateMeteredBilling: "Estimate (metered billing, subject to actual)",
ETA: "Estimate check in time",
Etc: "...etc",
EUR: "Euro",
EvaluateKefu: "Please evaluate the service",
ExactlyLate: "On-time",
ExceedingOrderAmount: "Exceeding the discount limit",
Exchange: "Exchange",
Exchange_1: "Exchange",
ExchangeSerialNumber: "Exchange serial number",
ExchangeSuccessful: "Exchange successful",
ExcludingFullPriceAverageDiscount: "Excluding full-price average discount",
ExclusiveBrandPrivileges: "Exclusive Brand Privileges",
ExclusiveConsultant_x: "Exclusive consultant %a$",
ExistingBusinessTravelAccount: "Already an existing contracted business travel account",
ExistingStaff: "Existing staff",
ExistingStaff_x: "Existing staff (%a$)",
Exit: "Exit",
ExitWarning: "Exiting will unbind WeChat from this account. After unbinding, you will not receive WeChat message notifications for this account. Are you sure you want to exit?",
ExpandMore: "Unfold more",
ExpectToAt: "Expected at",
Expired: "Expired",
ExportOrder: "Export order",
Express: "Express",
ExpressDelivery: "Express delivery",
Ext: "Ext",
ExtendedInformation: "Extended information",
Face: "Face",
FaceIDIsNotEnabled: "Face ID is not enabled on the current device",
FaceIDIsOnlyValidForTheMachine: "Face ID is only valid for this machine",
FaceIDLogin: "Face ID login",
FacilitiesDetails: "Facilities/Details",
FacilityServices: "Facility services",
FailedToAccessTheCamera: "Failed to access the camera",
FailedToChangePassword: "Failed to change password",
FailedToGetPleaseTryAgainLater: "Failed to get, please try again later",
FailedToResetPassword: "Failed to reset password",
FalseAlarmAccountability: "False alarm, legal liability",
FalseAlarmAccountabilityDesc_1: "One-click alarm connects to the platform customer service. In case of emergency endangering personal and property safety, please call 110 directly.",
FalseAlarmAccountabilityDesc_2: "False alarm may result in detention for more than five days but less than ten days",
FeaturedIntroduction: "Featured introduction",
Feedback: "Feedback",
Feishu: "Feishu",
FeishuByAccount: "Feishu account",
FeishuByUser: "Feishu employee binding",
Female: "Female",
File: "File",
FileDownloadFailed: "File fetch failed",
FileSizeLimit_x: "The file supports sizes up to %a$Mb",
FillInAccordingToTheActualNumber: "Fill in according to the actual number",
FillInAccurateAndCompleteUnitInformation: "Please provide accurate and complete unit information to better serve you",
FillingGuide: "Filling guide",
FillInTheReason: "Fill in the reason",
FillInYourBoundEmailAddress: "Fill in your bound email address",
Filter: "Filter",
Filter_1: "Advanced filter",
Filters: "Filters",
FindANewQueryQuote: "Find a new query quote",
FingerprintDoItAgain: "Fingerprint verification failed, please try again",
FingerprintTouch: "Lightly touch the fingerprint sensor",
FingerprintVerification: "Set fingerprint",
FingerprintVerificationTooManyTimes: "Too many fingerprint verification attempts, please try again later",
Finish: "Finish",
Finished: "Finished",
First: "First",
FirstBusinessClass: "First/Business",
FirstClass: "First class",
FirstClassSeat: "First-class seat",
FirstNameFormatIsIncorrect: "Incorrect English name format!",
FirstStep: "First step",
FixedPrice: "Fixed price",
Flight_x: "%a$ flight",
FlightBookPermission: "Flight booking permission",
FlightCancellationDelayForMoreThan: "Flight cancellation/delay for more than 30 minutes, subject to airline review",
FlightConfirmationWarn: "Used for visa application and immigration review, can print confirmation forms and carry them with you when traveling",
FlightDate: "Flight date",
FlightDeparturesAreLocalTime: "Flight departures are local time",
FlightDetails: "Flight details",
FlightDynamicsAreForReferenceOnly: "Flight dynamics are for reference only, please refer to the airline's release",
FlightIndex_x: "Flight %a$",
FlightInformation: "Flight status",
FlightInformationDesc: "Itinerary reminders such as flight changes",
FlightNo: "Flight number",
FlightNo_x: "Flight number %a$",
FlightNumber: "By flight number",
FlightNumberHotelTrainNumber: "Flight number / Hotel / Train number",
FlightPreference: "Preference",
FlightPricesChangeFrequently: "Flight prices change frequently. Please complete the payment as soon as possible to avoid any inconvenience to your travel plans",
FlightRank: "Flight rank",
FlightRefundOrderConfirm: "Flight refund order confirmation",
FlightRefundTrip: "Refund itinerary",
FlightRescheduleOrderConfirm: "Reschedule order confirmation",
FlightReschedulingOrRefundTips: "Warm reminder：Passengers who have completed check-in procedures need to cancel their check-in before they can reschedule or refund their tickets",
FlightSeatInfoArrow: "Seat info >",
FlightsPassenger: "Passenger",
FlightsRefunds: "Flight's Refunds",
FlightTicket: "Flights",
FlightType: "Flight type",
Floor: "Floor",
Floor_1: "Floor",
Floor_x: "%a$ Floor",
FlyingTime_x: "%a$ of flight",
FollowTheSystem: "Follow the system",
Foods: "Foods",
FoodTotalPrice: "Commodity price",
ForDomesticTicketsPleaseReturnToTheHomePageAndSelectDomesticTickets: "For domestic tickets, please return to the home page and select Domestic Tickets.",
ForgetPassword: "Forgot",
ForgetPassword_1: "Forgot?",
FormatIsIncorrect: " format is incorrect",
Free: "Free",
FreeBoardband: "Free internet",
FreeParking: "Free parking",
FreePickUpService: "Free pick-up service",
FreeWiFi: "Free WiFi",
FrequentContacts: "Contacts",
FrequentContactsManagement: "Manage your contact ",
FrequentTraveler: "Frequent traveler",
FrequentTravelerCardInformation: "Frequent traveler card information",
FrequentTravelerManagement: "Frequent traveler management",
FriendsareWaiting: "Your friends are waiting for you at Hengshun Business Travel. Join us using this exclusive link!",
From: "",
Fuel: "Fuel",
FuelFee: "Fuel fee",
FullPrice: "Full price",
FullyBooked: "Sell out",
FullyCallingTheVehicle: "Fully calling the vehicle",
GBP: "GBP",
Gender: "Gender",
GeneralAmenities: "General amenities",
GeneralCarUsage: "General car usage",
GeneralConditionsOfTransportation: "General conditions of transportation",
GeneralTrainTicket: "General train ticket",
GetSMS: "Get SMS",
GettingBoardingLocation: "Getting boarding location",
GivenNames: "Given name",
GivenNamesEg: "e.g.MEIMEI",
GoAndReturn: "D/R",
GoApprovals: "Go for approval",
GoBook: "Book",
GoEnquiry: "To inquiry",
GoFlight: "Outbound flight",
Going: "Depart",
GoingAndReturnTicket_x_x: "%a$(Depart), %b$(Return)",
GoingDate: "Depart",
GoingTicket_x: "%a$(Depart)",
GoingTo: "Going to",
GoLogin: "Go to login",
GoTag: "Go",
GoToBind: "Go connecting",
GoToChange: "Go to change",
GoToHotel: "Go",
GoToMixedPay_x: "Go to mixed pay %a$",
GoToPay: "Go to pay",
GoToPay_1_x: "Pay %a$",
GoToPay_x: "Go to pay %a$",
GoToQuery: "Go to query page",
GoToRefund: "Go to refund",
GoToTheStoreBeforeOClock_x: "Arrive at the hotel before %a$ o'clock",
Guarantee: "Guarantee",
Guarantee_1: "Guarantee",
GuessYouWantToFindIt: "Guess you want to find",
GuestRoom_x: "Room %a$",
Guests: "Guests",
Gym: "Gym",
HasProblem: "Has problem with payment",
HasQuitHumanCustomerService: "Exited human customer service",
HaveAgreement: "Negotiated",
HaveQuestions: "Have questions? Click the button below to consult~",
HaveRefund: "Refund",
HaveRescheduled: "Rescheduled",
HelpCenter: "Help center",
HengshunTravelAPP: "Homsom Travel APP",
HightoLow: "High to Low",
History: "History",
HistoryOrHot: "History/Hot",
HistoryOrLocation: "Location/History",
HistoryRecord: "History (Recent 1 year)",
HKD: "HKD",
HoldToTalk: "Hold to talk",
Home: "Home",
Home_1: "Home",
Homsom: "HOMSOM",
HOMSOM: "HOMSOM",
HomsomEnterpriseCooperation: "Homsom enterprise cooperation",
HomsomEnterpriseCooperationDesc: "If your company is already a contracted enterprise of Homsom Travel, please contact your account manager to obtain an account. Do not register again.",
HomsomFWXY: "《Service Agreement》",
HomsomLoginPrivacyInfo: "I have read and agree to the 《Service Agreement》 and 《Privacy Policy》",
HomsomLoginPrivacyTitle: "Please read and agree to the following agreements",
HomsomLoginPrivacyValue: "In order to protect the security of your personal information, you need to read and agree to the Service Agreement and Privacy Policy before using the login function.",
HomsomLxd: "Homsom LXD",
HomsomPrivacyInfo: "《Service Agreement》 and 《Privacy Policy》",
HOMSOMScanningCodeLoginConfirmation: "Homsom Travel website scanning code login confirmation",
HomsomServiceFee: "Homsom Service Fee",
HomsomServiceFeeByChange: "Service fee for rescheduling",
HomsomServiceFeeByIssue: "Ticket issuance service fee",
HomsomSuccessDesc: "Homsom Travel has completed your booking service",
HomsomTools: "Utilities",
HomsomUpdateToExperience: "Homsom Travel has been updated again, come and experience it first",
HomsomYSZC: "《Privacy Policy》",
Hot: "Hot",
Hot_1: "Hot",
HotChina: "Hot(China)",
Hotel: "Hotel",
Hotel_x: "%a$ Hotel",
HotelAddress: "Hotel address",
HotelBedNeeds: "Bed needs",
HotelBookPermission: "Hotel booking permission",
HotelBookWarn: "Please read 《Hengshun Business Hotel Reservation Terms and Conditions》, 《Personal Information Authorization Statement》 and 《Supplier Reservation Terms and Conditions》 carefully before submitting your order.",
HotelBookWarn_1: "《Hengshun Business Hotel Reservation Terms and Conditions》《Personal Information Authorization Statement》《Supplier Reservation Terms and Conditions》",
HotelChargeback: "Hotel refund",
HotelCheckInInfo: "Check-in Information",
HotelCheckInPost_x: "Check in after %a$",
HotelCheckOutBefore_x: "Check out before %a$",
HotelConfirmEmailChineseOrEnglish: "·Confirmation email in Chinese/English (PDF attachment of Chinese/English check-in voucher)",
HotelConfirmNumber: "Hotel confirmation number",
HotelConfirmSMSChineseOrEnglish: "·Chinese/English confirmation SMS",
HotelConsumptionInEachCity: "Hotel consumption in each city",
HotelDetails: "Hotel details",
HotelFeature: "Feature",
HotelGeneral: "Hotel general",
HotelGroupMembership: "Hotel Group",
HotelGroupMembershipCard: "Membership Card",
HotelGroupMembershipCard_1: "Hotel Group Membership Card",
HotelGroupMembershipCardBind: "Binding",
HotelGroupMembershipCardDetails: "Membership Card Details",
HotelGroupMembershipCardInfo: "Hotel Group Membership Card Information",
HotelIntroduction: "Hotel introduction",
HotelInvoiceExplain: "Note: The room fee invoice is issued by the hotel, please go to the front desk to collect it",
HotelLatestRoomPrice: "The latest hotel price has been obtained for you",
HotelName: "Hotel name",
HotelNight: "Hotel nights",
HotelOtherNeeds: "Other needs",
HotelPayInStore: "Pay at hotel",
HotelPromptIsMorning: "If you need to check in before 6am this morning, please choose to check in early this morning",
HotelRank: "Hotel ranking",
HotelRating: "Hotel rating",
HotelRC: "Hotel RC",
HotelReasonCodeDistribution: "Hotel reason code distribution",
HotelReservationAuthority: "Domestic hotel ranking",
HotelRestaurant: "Hotel restaurant",
HotelRoomNumber: "Room number",
HotelRoomRemarkInputTip: "If you have any other requirements, Please fill in form, and the hotel will try its best to accommodate you",
Hotels: "Hotels",
HotelSelectChargebackGuest: "Select guest for cancellation",
HotelService: "Hotel service",
HotelServicePreferences: "Service·Preferences",
HotelServicePreferences_1: "Service preferences",
HotelSpecialNeeds: "Special needs",
HotelSpecialNeedsEditHint: "If you have other requirements, please fill in, the hotel will try to arrange for you.",
HotelSpecialNeedsExplain: "We will convey your request to the hotel, but there is no guarantee that the hotel will be able to fulfill it. If you have any special request, please contact the hotel by yourself.",
HotelTotalAmount: "Hotel total amount",
HsTravelerExplainContext: "You are aware that the frequent traveler identity information you have entered in HsTravel will be used for booking all products that require real-name registration, such as plane tickets and train tickets, and will be verified during usage. Please ensure the authenticity and validity of this information. HsTravel will protect this information through encryption and other means, and will only authorize its provision to relevant third parties in specific transactions.",
HsTravelerExplainTitle: "Read and agree to the following",
ID: "ID",
IDCard: "ID card",
IDCardFormatIsIncorrect: "ID card number format is incorrect!",
IDNumber: "ID number",
IDType: "ID type",
IfTheApproverIsInconsistent: "If the approver is inconsistent, please place separate orders.",
IfTheRemainingSeats: "If the remaining seats cannot meet the seat selection requirements, the system will automatically assign seats for you.",
IfTheTicketHasBeenIssuedTheLossMustBeBorneByTheOwner: "2. If the ticket has been issued, the loss incurred from refund or changes must be borne by the owner.",
IfYouCheckThisOption: "If you check this option, the upcoming itinerary will be treated as your personal trip.",
IfYouHaveAnyQuestions: "If you have any questions, please call the national service hotline of HsTravel: 4006-123-123",
IfYouHaveAnyQuestionsPleaseContactYourBusinessConsultant: "If you have any questions, please contact your business consultant.",
IfYouNeedToNarrowTheScopePleaseEnterMoreConditions: "If you need to narrow the scope, please enter more conditions.",
IGotIt: "OK",
IHaveReadAndAgree: "I have read and agree",
IHaveReadAndAgree_x: "I have read and agree %a$",
Import: "Import",
Import_1: "Import",
ImportBusiness: "Import (Business)",
ImportFromFrequentTraveler: "Import from staff",
In: "C/I",
IncludingHongKongMacauAndTaiwan: "Including Hong Kong, Macau and Taiwan",
Income: "Income",
IncomeExpenditure: "Income and Expenditure",
InconsistentPasswordEnteredTwice: "Inconsistent password entered twice",
IncorrectFormatOfContactMobileNumber: "Incorrect format of contact mobile number!",
Index_x: "%a$",
Indexs: "Indexes",
InformationIsIncomplete: "Basic information incomplete",
InformationVerification: "Information verification",
Infrastructure: "Amenities",
InitiativeEnthusiasmEfficientMeticulousSpecialty: "More proactive, enthusiastic, efficient, meticulous, and professional",
InnovativeService: "Innovative service",
InputInformation: "Input information",
InquiryInformationIsInvalid: "Inquiry information is invalid",
Install: "Install",
Instructions: "Notice",
InsufficientRemainingTicketsChooseOtherFlight: "Insufficient remaining tickets, please choose another flight",
Insurers: "Insurance",
Integral: "Integral",
Integral_1: "Integral",
IntegralByHomsom: "Homsom integral",
IntegralMall: "Integral exchange",
Intergral_x: "%a$ integral",
IntergralDeliveryNumber_x: "Delivery number: %a$",
IntergralDescription: "Points description",
IntergralRules: "Points rules",
IntergralTotalColon: "Total:",
InternalProcurement: "Internal procurement",
International: "Overseas",
InternationalHotel: "Overseas hotel",
InternationalHotel_1: "Overseas hotel(Excluding Hong Kong, Macau, and Taiwan regions)",
InternationalHotelOrderConfirmation: "Overseas hotel order confirmation",
InternationalHotelOrders: "Overseas hotel orders",
InternationalRegion: "International/Hongkong Macao Taiwan",
InternationalRegion_1: "International",
InternationalTicket: "International flights",
Internet: "Internet",
Interval_x: "Interval of %a$",
InTheLocation: "Locating",
InTheTicket: "In the ticket",
Intl_1: "International",
IntlDestination: "Overseas",
IntlFlightEnquiry: "Int'l flights demand list",
IntlFlightEnquiryService: "International Flight Inquiry Service",
IntlFlightEnquiryServiceDesc: "Personalized inquiry service, saving you time and effort",
IntlFlightOrder: "Int'l flights order",
IntlFlightRank: "Int'l flights ranking",
IntlFlights: "Int'l flights",
IntlFlights_1: "Int'l flights(Including Hong Kong, Macau, and Taiwan regions)",
IntlFlightsOrderConfirmation: "Int'l flights order confirmation",
IntlHotel: "Overseas hotel",
IntlHotelReservationAuthority: "Overseas hotel rank",
InTrainTransfer: "In car transfer",
InTravel: "In travel",
Invalid: "Invalid",
Inventory_x: "Inventory: %a$ items",
InvitationOtherType: "Other invitation method",
InvitationStaff: "Invite staff",
Invitee: "Invitee people",
InviteEmployeesToJoin: "Invite employees to join the team using any of the following methods. After the administrator verifies the employee's information, the employee will receive an SMS notification.",
Invoice: "FaPiao",
InvoiceAmount: "FaPiao amount",
InvoiceContent: "FaPiao content",
InvoiceDescription: "FaPiao description",
InvoiceDetais: "FaPiao details",
InvoiceFilling: "FaPiao filling",
InvoiceFinished: "FaPiao issued",
InvoiceHeader: "FaPiao header",
InvoiceHeaderName: "FaPiao name",
InvoiceHeaderType: "Header type",
InvoiceInformation: "FaPiao",
InvoiceInst: "FaPiao instructions",
InvoiceOperating: "In progress",
InvoiceParagraph: "FaPiao tax ID",
InvoiceRemarks: "FaPiao remarks",
InvoiceTitle: "FaPiao title",
InvoiceType: "FaPiao type",
InvoicingStatus: "FaPiao status",
InvoluntaryRefund: "Involuntary refund",
InvoluntaryRefundDesc: "Involuntary Refund (Flight cancellation/delay of 30 minutes or more, subject to airline verification)",
InvoluntaryRefundExplain: "Explanation of involuntary ticket refund",
InvoluntaryReschedule: "Involuntary reschedule",
InvoluntaryRescheduleDesc: "Involuntary reschedule (Flight cancellation/delay of 30 minutes or more, subject to airline verification)",
InvoluntaryRescheduleExplain: "Explanation of involuntary rescheduling",
IsLoggedIn: "Logged in",
IsNotAArrivalCity_x: "%a$ cannot be selected as the arrival city",
IsNotADepartureCity_x: "%a$ cannot be selected as the departure city",
IsSendReceiveEmail: "Receive email",
IsSendReceiveSms: "Receive SMS",
ItIsNowPastZeroOclock_x: "It is now past midnight. If you wish to check in before %a$ AM, please select 'Early Morning' for today's check-in.",
JobDescription: "Job description",
JPY: "Japanese Yen",
JudgeCertificateExpired_x: "The current validity period of %a$ is less than 6 months from the departure time, which may result in denied boarding. Do you want to continue?",
JustLookAtTheHighSpeedTrain: "High speed trains only",
JustTime: "just",
Keywords: "Keywords",
KeywordSearch: "Keyword search (Hotel name)",
KeywordSearch_1: "Location/Brand/Hotel Name",
Kilometers: "%a$ kilometers",
KPIAnalysis: "KPI analysis",
KRW: "South Korean Won",
LackOfIntegral: "Lack of integral",
Language: "Language",
LanguageBeingSet: "Setting language...",
LanguageCH: "Chinese",
LanguageDefault: "Default language",
LanguageEN: "English",
LanguageSetting: "Language setting",
LastNameFormatIsIncorrect: "Incorrect last name format!",
LatestArrivalDate: "Latest arrival date",
LateToEarly: "Late to Early",
Latitude: "Multi-Latitude analysis",
LearnAbout: "Tips",
LearnMore: "Learn more",
LengthMustNotExceedXDigits_x_x: "Length of %a$ must not exceed %b$ digits",
LessThan10People: "Less than 10 people",
LetYourFriendsAlsoDownloadHengshunTravelAPP: "Let Your Friends Also Download Hengshun Travel APP",
LevelXAapproval_x: "Level %a$ approval",
Light: "Light mode",
LimitedToXReservation_x: "Limited to %a$ reservation(s)",
LinkYourCellphone: "Link your cellphone",
List: "List",
Loading: "Loading",
Loading_1: "Loading...",
LoadMoreTransit: "Load more transit information",
LocacleHotHotelRecommend: "Local hot hotel recommendations",
LocalTax: "Local Tax",
LocalTime: "Local time",
Location: "Location",
Location_1: "Location",
Location_2: "Location",
LocationFailed: "Failed to get current location",
LocationNotTurnOn: "Location not turned on",
LocationOfTheUnit: "Location of the unit",
Login: "Login",
Login_1: "Login",
Login12306: "Login to 12306",
Login12306Account: "Log in to the 12306 account",
Login12306AccountDesc_1: "Real - name registration is required",
Login12306AccountDesc_2: "Purchase tickets, refund, change or rebook using the current 12306 account",
Login12306AccountEnjoyExtremeReversionMemberPoints: "Log in to your 12306 account and enjoy ultimate refund and change policies, as well as member points.",
Login12306AccountInPerson_x: "Please confirm that you are the account owner: %a$",
Login12306FaceToBeCompleted: "Face verification needs to be completed",
LoginByAccountPassword: "Account password login",
LoginByVerificationCode: "Verification code login",
LoginByVerificationCodeEmail: "Email Login",
LoginByVerificationCodeSMS: "SMS Login",
LoginConfirm: "Confirm",
LoginExpired: "Login expired, please login again!",
LoginName: "Username",
LoginOut: "Sign Out",
LoginSuccessful: "Login Successful",
LoginSuccessful12306: "12306 login successful",
LoginTo12306ToImproveTheSuccessRateOfTicketIssuance: "Log in to your 12306 account to improve the success rate of ticket issuance",
LogisticsCompany: "Logistics company",
Logout: "Logout",
LongPressSaveTheQRcode: "Long press to save the QR code",
LookAtThePriceExcludingTax: "Look at the price (Excluding tax)",
LookCertificate: "Check the proof",
LookChargebackOrder: "Chargeback order",
LooOriginOrder: "Original order",
Loss: "Loss",
LossAnalysis: "Loss analysis",
LossPercentage: "Loss percentage",
LowCarbonHomsomPlantingHomsomForestTogether: "Low Carbon Homsom, Planting Homsom Forest Together",
LowestPrice: "Lowest price",
LowestPrice_1: "Lowest price",
LowestPriceDetails: "Lowest price details",
LowestPriceHoursAndTicket_x_x: "Lowest price within %a$ hours %b$/ticket",
LowestPriceTicketBookingRatio: "Lowest price ticket booking ratio",
LowestTicketPrice: "Lowest ticket price",
LowtoHigh: "Low to High",
LuggageStorage: "Luggage storage",
Luxury: "Luxury",
LuxuryHotel: "Luxury hotel",
LuxuryHotel_1: "Luxury",
Lxd: "Lxd",
LxdReward: "Reward",
LxdReward_x: "%a$ Lxd",
LxdRewardCompleteTheOrderToObtain: "Obtain after completing the order",
LxdRules: "Lxd rules",
LxdToCompleteTheOrder_x: "Earn %a$ Lxd upon completing the order",
MailSent: "Email sent",
MainFlight: "Main flight",
MainRoomer: "Main guest",
MainRoomerRequired: "Main occupant (mandatory)",
MakeSureToClearCache: "Are you sure you want to clear the cache?",
MakeSureToFingerprintLoginClose: "Are you sure you want to disable fingerprint login?",
MakeSureToLeaveYourCurrentAccount: "Are you sure you want to log out of your current account?",
Male: "Male",
Manage: "Manage",
Manage_x: "%a$ Manage",
Management: "Management",
Manager: "Manager",
ManualApproval: "Manual approval",
ManuallyImport: "Manually import",
ManuallyPassed: "Manually pass",
Map: "Map",
MapNavigation: "Map",
MapPointSelection: "Map Point Selection",
MapSelection: "Map",
MapSurroundings: "Map/Periphery",
MarkAllMessagesAsRead: "Mark all messages as read?",
MatchingTheBestDriverForYou: "Matching the best driver for you",
MaximumFaceValue: "Maximum face value",
MayBeAt: "May be at",
Meals: "Meals",
MealsGoods: "Dining",
MealsOrder: "Dining order",
MealsOrderConfirm: "Dining order confirmation",
MealsRank: "Dining rank",
MealsRemarks: "Dining remarks",
MeetingRoom: "Conference room",
MembershipNumber: "Card number:",
Message: "Message",
MessageCenter: "Message center",
MessageFailedToSend: "Message failed to send. Please retry.",
MessageSendSuccessfully: "Message sent successfully",
MicrophoneIsRequiredForCurrentService: "Microphone is required for the current service. Please go to settings and enable it.",
Midscale: "Midscale",
Mileage: "Mileage",
Minute: "Minute",
Minutes_x: "%a$ minutes",
MissedSavingAnalysis: "Missed savings analysis",
MixedPayment: "Mixed payment",
MixedPaymentContent_x_x_x: "The total amount of the order you booked is %a$, and the highest travel standard is %b$, which has exceeded %c$. You can choose to pay for the excess amount yourself or choose to pay for all companies.",
MixedPaymentDesc: "Partial self-payment for exceeding the standard, premium hotel with overage payment",
MixedPaymentDetails_x_x: "The company will pay %a$, and you need to pay %b$ more than the travel standard",
MixedPaymentOpen: "Mixed payment is enabled for your company",
MixedPaymentOpenDesc: "You can enjoy a better room by paying for the exceeding amount yourself",
MixedPaymentSelfPayment: "Mixed payment",
MnlyOneMembershipCardCanBeAddedToTheSameHotel: "Sorry, only one membership card can be added to the same hotel.",
MobileCode: "Mobile area code",
MobileInvitationCode: "Mobile invitation code",
MobileInvitationCodeShare: "Share the image containing the QR code to your company community, and your colleagues can directly scan and apply",
MobileLinked: "Mobile number linked",
MobilePhoneNumberVerification: "Mobile phone number verification",
MobileUnlinked: "Mobile number unlinked",
ModifiedSuccess: "Alteration successful",
ModifiedSuccessfully: "Modification successful! The new mobile number is",
Modify: "Modify",
ModifyTheBoundPhone: "Modify the bound phone",
Moments: "Moments",
Month: "Month",
MonthApr: "Apr",
MonthAug: "Aug",
MonthDeb: "Feb",
MonthDec: "Dec",
MonthDetails: "Month details",
MonthJan: "Jan",
MonthJul: "Jul",
MonthJun: "Jun",
Monthly: "Monthly",
MonthlyExpense: "Monthly expense",
MonthlyKPI: "Monthly KPI",
MonthlySummary: "Monthly summary",
MonthMar: "Mar",
MonthMay: "May",
MonthNov: "Nov",
MonthOct: "Oct",
MonthOnMonth: "M-on-M",
MonthSep: "Sep",
More_1: "More",
More_2: "More",
More_3: "More",
MoreAvailable_x: "Available with a minimum spend of %a$",
MoreCabinsNoResult: "No more cabins available!",
MoreConsultation: "More consultation",
MoreDate: "More",
MoreDateB: "More",
MoreDiner: "More diners",
MoreFilterCriteria: "More filter criteria",
MorePassengerFlight: "More",
MorePassengerRoomer: "More guests",
MorePassengerVehicle: "More passengers",
MorePrice: "More prices",
MoreSorting: "More sorting",
MoreThan100People: "More than 100 people",
MoreThanThePurchaseLimitCanNotBuy: "Exceeds purchase limit, cannot buy",
MoreTraveler: "More travelers",
Morning: "Morning",
MorningCall: "Morning call service",
MultipleAccountsHaveBeenBound: "The phone number you entered is linked to multiple accounts. Please try using a different login method",
MultipleChoice: "Multiple",
MultipleChoiceSuccessRate: "Multiple",
MultiTierReschedulingNotSupported: "Multi-tier Rescheduling Not Supported",
MustBeAccompaniedByAnAdultOnBoard: "Children/infants must be accompanied by an adult on board",
MyAdvancePayment: "My prepaid",
MyApplyTravel: "My Application",
MyApprovals: "My approvals",
MyBalance: "My balance",
MyCollection: "Collection",
MyCustomerService: "Service",
MyInitiations: "My initiations",
MyIntergral: "My points",
MyLxd: "My Lxd",
MyTrip: "My trip",
Name: "Name",
Name_x: "%a$",
NameFillingInstructions: "Name instructions",
Nation: "Country/Region",
NationalityOfThePerson: "Country/Region",
NationalServicePhone: "National service hotline",
Near: "Near",
NearExpiration: "Near expiration",
NearlyOneYear: "Recent 1 year",
NetList: "Net list",
NetTransactionPrice: "Net transaction price",
Network: "Network",
NetworkRequestConnectionFailed: "Network request connection failed",
NetworkRequestFailed: "Network request failed",
New: "New",
New_1: "New",
New_x: "New %a$",
NewCertificate: "New certificate",
NewContactSuccessFailed: "Failed to add contact",
NewContactSuccessFul: "Contact added successfully",
NewDepartment: "New department",
NewDiner: "New diner",
NewFlightDate: "New date",
NewFrequentTravelCard: "Add frequent travel card",
NewHotelGroupMembershipCard: "Add hotel group membership card",
NewMobile: "New mobile",
NewPassenger: "New passenger",
NewPassengerFlight: "New passenger",
NewPassengerVehicle: "New passenger",
NewRC: "New RC",
NewRoomer: "New guest",
NewTraveler: "New traveler",
NewVersion: "New version",
NewVersionFound: "New version found",
NextStep: "Next",
NextStep_1: "Next",
NickName: "Nick name",
NightsTotalPrice_x: "Total price for %a$ night(s)",
NoApprovalRequired: "No approval required",
NoAvailableCabin: "There are currently no available booking seats available",
NoAvailableFlight: "No available flights for booking at the moment",
NoAvailableFlightDesc: "No flights were found that meet the criteria. You can try changing the filtering options~",
NoAvailableHotel: "There are currently no hotels available for reservation.",
NoAvailableHotelTip: "No hotels meeting the criteria were found. You can try changing the filter options~",
NoAvailableTrain: "There are currently no train numbers available for booking.",
NoAvailableTrainTip: "No train schedules meeting the criteria were found. You can try changing the filter options~",
NoAvailableVouchersForUse: "No available vouchers for use",
NoBreakfast: "No Breakfast",
NoChangeablePassengers: "No Changeable Passengers",
NoChildUnderDepartmentsAtPresent: "No child under departments at present",
NoCollection: "No collection",
NoData: "No results",
NoData_1: "No data",
NoDataBySearch: "Sorry, nothing was found.",
NoDataContent: "No content",
NoDataTravelStandard: "There is currently no relevant differential information available",
NoIncludedChangeFeeTag: "(No rescheduling fee is included)",
NoMeal: "No meal",
NoMore: "No more",
NoMoreCredential: "No more credentials available",
NonBookable: "Non-Bookable",
None: "None",
NoNet: "No network",
NoNewNotice: "No new notice",
NoNews: "No news",
NonStaff: "Outsiders",
NonStaffDetail: "Outsiders details",
NonStaffManagement: "Outsiders management",
Noon: "Noon",
NoOrder: "No order",
NoOrderEnquiry: "No demand orders",
NoPermissions: "No permissions",
NoPermissionToAccess: "No permission to access",
NoPlatformAvailablePleaseContactYourTravelConsultant: "No platform available, please contact your travel consultant",
NoRemainingTickets: "No remaining tickets",
NoReschedulingReeIsIncluded: "Rescheduling fee not included",
NoResult: "No result",
NormalSeat: "Normal seat",
NoStay: "Not checked in",
NotBook: "Not booking",
NotBooking_1: "Not booking",
NotCertificateInfo: "No certificate information",
NotesOnRefundAndChangeOfVisa: "Notes on Refund and Change of Visa",
Nothing: "Unavailable",
Notice: "Notice",
NoticeCanceled: "Notice canceled",
NoTicket: "Sold out",
NotInitiated: "Not initiated",
NotNecessaryChoose: "Optional",
NotNecessaryChoosePleaseChoose: "Please select",
NotNecessaryChooseUneditable: "Uneditable",
NotNecessaryFill: "Optional",
NotNecessaryFillPleaseFill: "Please fill",
NotNecessaryFillPleaseFillOrChoose: "Please fill in or select",
NotNecessaryFillUneditable: "Uneditable",
NotNeedDepartPassenger: "No Departing Passengers",
NoTravelCard: "No travel card information",
NoTravelStandard: "No travel standard available",
NotRefundable: "Non-Refundable",
NoTrip: "You currently have no trip information",
NotSelectable: "Not selectable",
NotSendEmptyInfo: "Cannot send empty message",
NotUsed: "Not used",
Now: "Now",
NoXSegment_1_x: "Select #%a$ Segment",
NoXSegment_2_x: "Select as segment %a$",
NoXSegment_3_x: "%a$",
NoXSegment_x: "#%a$ Segment",
Number: "People",
Number_1: "Number",
NumberOfAdult: "Adults",
NumberOfChild: "Children",
NumberOfCustomItems: "Number of custom items",
NumberOfFullPriceTickets: "Number of full price tickets",
NumberOfGuestsPerRoom: "Number of guests per room",
NumberOfRoom: "Rooms",
NumberOfRooms: "Number of rooms",
NumberOfRoomsAndOccupancy: "Number of rooms and occupancy",
NumberOfRoomsBooked: "Number of rooms booked",
NumberOfSheets: "Number of sheets",
NumberOfTrainTickets: "Number of train tickets",
NumberOfWorkers: "Number of workers",
NZD: "New zealand dollar",
OffAndBelow: "0 percent off and below",
OK: "OK",
OldAndNewPasswordsMustNotBeTheSame: "Old and new passwords must not be the same",
OldVersion: "Old version",
OneAirlineCanOnlyAddOneFrequentTravelCard: "Sorry, the same airline can only add one frequent travel card.",
OneWay: "One Way",
OnlineChangeNotSupported: "Online change not supported",
OnlineCheckIn: "Online check-In",
OnlineFlight: "Check-in",
OnlineFlight_1: "Check in",
OnlineFlightWarn: "Warm reminder：The online check-in service is provided by the airline. If you encounter any problems during check-in, please contact the airline directly.",
OnlinePayment: "Prepay",
OnlinePayment_1: "Prepay",
OnlineReport: "Online report",
OnlineSeatSelection: "Online Seat Selection",
OnlineService: "Online service",
OnlyAdultTicketBookingsAreSupported: "Adult reservations only",
OnlyAlternativeTrainsCanBeSelectedAtMost_x: "Only %a$ alternative trains can be selected at most",
OnlyChineseName_x: "Only Chinese name can be used for %a$",
OnlyDirectFly: "Only direct flights",
OnlyEnglishName_x: "Only English name can be used for %a$",
OnlySeeSelected: "Only see selected",
Open: "Open",
Open_1: "Open",
Open_2: "Open",
OpenHengshunTraveMobilelEditionForBetterExperience: "Open Hengshun Travel Mobile Edition for better experience",
Opening: "Opening",
OpenSafari: "Open in Safari",
OpenSuccess: "Open Success",
OpenYears: "Open",
OperatedBy: "Operated by",
Operating: "Processing",
Operation: "Operation",
Operator: "Processor",
Optional: "Available",
OptionalCities: "Available Cities",
Order: "Order",
Order_1: "Order",
Order_2: "Order",
Order_3: "Order",
OrderCancelFailed: "Order cancellation failed",
OrderCancelled: "Order cancelled",
OrderCompleted: "Order completed",
OrderDate: "Order date",
OrderDateBookTime: "Order time",
OrderDetails: "Order details",
OrderDetailsBusiness: "Order details (Business)",
OrderDetailsPersonal: "Order details (Personal)",
OrderFilling: "Order filling",
OrderInformation: "Order information",
OrderInformationForChange: "Rescheduling order information",
OrderInformationForRefund: "Refund order information",
OrderIntergral: "Order points earned",
OrderNo: "Order No.",
OrderNo_1: "Order No.",
OrderNoCannotBeEmpty: "Order No. cannot be empty",
OrderNotice: "Order notification",
OrderNoticeDesc: "Ticket issuance status, reservation status reminder",
OrderNumber: "Order No",
OrderNumber_1: "serial number",
OrderNumber_x: "Order No %a$",
OrderPaid: "Order placed",
OrderStatus: "Order status",
OrderStatusHasChangedPleaseReOperate: "Order status has changed. Please re-operate",
OrderSubmissionSuccessFailed: "Order submission failed",
OrderSubmissionSuccessful: "Order submission successful!",
OrderSynTime: "Synchronization time",
OrderTime: "Order time",
OrderType: "Order type",
OrganizationalStructure: "Structure",
Original: "Original ",
OriginalPrice: "Original price",
OriginalSchedule: "Original schedule",
OriginFlightOrderNo: "Origin No",
OriginFlights: "Original flight",
OriginOrderNumber: "Original order",
OriginTrip: "Original Trip",
OriginTripOrderNo: "Original Train Order Number",
Other: "Other",
Other_x: "Other: %a$",
OtherInfo: "Other info",
OtherInformation: "Other information",
OtherLoginMethods: "Other Login Methods",
OtherPolicy: "Other policy",
OtherReasons: "Other reasons",
OurAgreedAveragePrice: "Our agreed average price",
OurCompany: "Our company",
OurExclusiveConsultantWillContactYouAsSoonAsPossible: "Our exclusive consultant will contact you as soon as possible",
Out: "Out",
OverseasMultiCity: "Multi-Cities",
OverseasServicePhone: "Overseas service hotline",
Overview: "Overview",
OverviewOfTickets: "Overview of tickets",
P: "P",
PackUp: "Pack up",
Page: "Page",
PaidBoardband: "Paid internet",
PaidParking: "Paid parking",
PaidPickUpService: "Paid pick-up service",
PaidWiFi: "Paid WiFi",
PartialChange: "Partial change",
PartialRefund: "Partial refund",
Pass: "Pass",
Passenger: "Passenger",
PassengerDetails: "Passenger details",
PassengerFlight: "Passengers",
PassengerFlightDetails: "Passenger details",
PassengerFlightName: "Passenger name",
PassengerInformation: "Passenger information",
PassengerList12306: "12306 Passenger List",
PassengerName: "Passenger name",
PassengersWhoNeedToRefund: "Passengers who need to refund",
PassengersWhoNeedToReschedule: "Passengers who need to reschedule",
PassengersWithoutRefundableTickets: "Passengers without refundable tickets",
PassengerVehicle: "Passengers",
PassengerVehicleDetails: "Passenger details",
PassengerVehicleForChange: "Change passenger",
PassengerVehicleForRefund: "Refund passenger",
PassengerVehicleName: "Passenger name",
PassengerVehicleWhoNeedToRefund: "Select the itinerary for refund",
PassengerVehicleWhoNeedToReschedule: "Passengers who need to change tickets",
Password: "Password",
Password12306: "12306 password",
PasswordCanBeResetViaEmail: "Password can be reset via the registered email address",
PasswordCanBeResetViaPhone: "Password can be reset via the registered phone number with SMS verification code",
PasswordMustBeLettersAndNumbers: "Password must be 8-20 characters long and contain letters and numbers",
PasswordMustBeNoLessThanEightDigitsInLength: "Password must be at least 8 characters long",
PasswordMustBeNoLessThanSixDigitsInLength: "Password must be at least 6 characters long",
PasswordRuleDescription: "Recommended passwords consist of upper and lower case letters and numbers and are longer than 8 digits in length",
PasswordsMustContainLettersAndNumbers: "Passwords must contain letters and numbers",
PasswordUpdateSuccessful: "Password modification successful",
Pay: "Pay",
PayAgain: "Try again",
PayAtTheStore: "Pay at hotel",
PayAtTheStore_x: "Pay %a$ at the hotel",
PayAtTheStoreAbout: "Pay in hotel about",
PayAtTheStoreAbout_x: "Pay in hotel about %a$",
PayAtTheStoreTaxesFees_x: "Additional tax/fee Approx %a$",
PayBackTips: "The order has not been paid yet. Returning will abandon the payment. You can view and continue the payment in the order!",
PayInformation: "Payment information",
PayInformationDetails: "Payment details",
PaymentFailed: "Payment failed",
PaymentOnBehalfShareDesc: "Send someone else to help you make the payment~",
PaymentPermission: "Payment permission",
PaymentSerialNumber: "Payment serial number",
PaymentSuccessful: "Payment successful",
PaymentSuccessfulContinueToPayTheNextOrder: "Payment successful, continue to pay the next order",
PaymentTime: "Payment time",
PaymentWay: "Payment",
PayNow: "Pay now",
PayOrder: "Pay order",
PayTypeCompanyDesc: "Payment of fees from company account",
PayTypeMixedDesc: "Expenses exceeding the travel standards need to be paid by individuals",
PayTypePersonalDesc: "Individual payment required",
PdfDownload: "Download PDF",
PendForOperating: "Pending",
Pending: "Pending",
Pending_1: "Application submitted (Pending approval)",
Pending_2: "Reservation successful (Pending approval)",
Pending_3: "Application submitted",
Pending_4: "Pending review",
Pending_x: "Pending(%a$)",
People_x: "%a$",
People10: "10-20 people",
People20: "20-40 people",
People40: "40-100 people",
PeoplePerRoom: "People per room",
PeopleStaying_x: "Check in %a$ people",
PercentageOfPotentialSavings: "Percentage of potential savings",
PercentageOfRoomNights: "Percentage of room nights",
PercentageOfSavings: "Percentage of savings",
PercentageOfSheets: "Percentage of sheets",
PermissionsRange: "Permissions range",
Person: "Individual",
PersonAccount: "Member account",
Personal: "Personal",
Personal_x: "%a$ (personal)",
PersonalInformation: "Personal information",
PersonalPayment: "Personal payment",
PersonalPermissions: "Personal permissions",
PersonalTools: "Tool",
PersonalTravelInformation: "Personal travel information",
PersonalTrip: "Personal trip",
PersonalTrip_1: "Personal trip",
PersonalWallet: "Personal wallet",
PersonalWorkbench: "Personal",
PersonEntry: "Member entrance",
PhoneNumberFormatIsIncorrect: "Phone number format is incorrect!",
PhoneNumberFormatIsIncorrect_1: "Phone number format is incorrect!",
PhoneNumberFormatIsIncorrect_x: "Phone number format is incorrect for %a$",
PhoneNumberOrEmailFormatIsIncorrect: "The format of the phone number/email is incorrect",
Photo: "Photo",
PhotoOrFile: "Photo/file",
PickUpUseCarTimeTenMinute: "10 minutes after landing",
PickUpUserCarTimeFiftyMinute: "50 minutes after landing",
PickUpUserCarTimeFourtyMinute: "40 minutes after landing (recommended for checked baggage)",
PickUpUserCarTimeSixtyMinute: "60 minutes after landing",
PickUpUserCarTimeThirtyMinute: "30 minutes after landing (recommended for no checked baggage)",
PickUpUserCarTimeTwentyMinute: "20 minutes after landing",
Picture: "Picture",
PlaceOfDeparture: "Place of departure",
PlaceOfService: "Place of service",
Plan: "Plan",
Plan_x: "Plan %a$",
Plane: "Plane",
PlaneBookNoticeCopyTips: "The link has been copied to the clipboard. The mini program does not support preview for the time being. Please open it in the browser",
PlaneConstruction: "Airport construction",
PlaneConstructionFee: "Airport construction fee",
PlaneConstructionFuel: "Airport construction / Fuel",
PleaseAddARoomer: "Add guest",
PleaseAddContact: "Please add contact",
PleaseCheckInAccordingToTheActualFlight: "Please check in according to the actual flight.",
PleaseCheckTheBoxToAgreeAfterReadingTheRelevantTermsAndConditions: "Please check the box to agree after reading the relevant terms and conditions",
PleaseChoose: "Please choose",
PleaseChoose_1: "Please choose",
PleaseChooseAPlaceOfArrival: "Please choose a destination",
PleaseChooseAPlaceOfDeparture: "Please choose a place of departure",
PleaseChooseApprover: "Please choose an approver",
PleaseChooseASeat: "Please choose a seat",
PleaseChooseCarType: "Please choose a car type",
PleaseChooseContacts: "Please choose a contact",
PleaseChooseIDType: "Please choose an ID type",
PleaseChooseOrInput_x: "Please enter/select %a$",
PleaseChoosePayWay: "Please choose a payment method",
PleaseChooseRouteArrivalCity_x: "Please choose the arrival city for segment %a$",
PleaseChooseRouteDepartCity_x: "Please choose the departure city for segment %a$",
PleaseChooseRouteGoDate_x: "Please choose the departure date for segment %a$",
PleaseChoosesCabinLevel: "Please choose a cabin class",
PleaseChooseTheAirClub: "Please choose the airline club",
PleaseChooseTheDocumentSignatory: "Please choose the issuing country of the document",
PleaseChooseTheHotelGroupMembership: "Please select a hotel group",
PleaseChooseTheReason: "Please choose a reason",
PleaseChooseTheReason_1: "Please choose the reason:",
PleaseChooseTheValidityPeriodOfTheCertificate: "Please choose the validity period of the document",
PleaseChooseWhenToUseTheCar: "Please choose the car usage time",
PleaseChooseYourNationality: "Please choose country/region",
PleaseCompleteTheInformation_x: "Please complete the information for %a$",
PleaseCompleteTheReceiptInformation: "Please complete the receipt information",
PleaseCompleteTheVerificationAsSoonAsPossible: "Please complete the verification as soon as possible to avoid ticketing issues",
PleaseCompleteYourGivenNames: "Please complete your given names",
PleaseCompleteYourNameInformation: "Please complete your name information",
PleaseCompleteYourSegments: "Please complete your segments",
PleaseCompleteYourSurname: "Please complete your surname",
PleaseConfigureTheExaminerInTheBackground: "Please configure the approver in the background",
PleaseConfirmIfWeChatPaymentHasBeenCompleted: "Please confirm if WeChat payment has been completed",
PleaseConfirmTheNewPassword: "Please confirm the new password",
PleaseContactTheAccountNumber12306: "Please link your 12306 account",
PleaseDoNotRefreshThisPagePromptOperation: "Please do not refresh this page, follow the instructions on your phone",
PleaseEnter: "Please enter",
PleaseEnter_1: "Please enter the %a$",
PleaseEnter_1_x: "Please enter the %a$",
PleaseEnter_x: "Please enter %a$",
PleaseEnterAccurateAndCompleteUnitInformation: "Please fill in accurate and complete unit information",
PleaseEnterAComment: "Please enter a comment",
PleaseEnterACostCenterName: "Please enter business trip subordinate department",
PleaseEnterAddress: "Please enter the address",
PleaseEnterANewPassword: "Please enter a new password",
PleaseEnterASuggestionOfNoLessThanEightWords: "Please enter a suggestion of at least eight words",
PleaseEnterAUsername: "Please enter a username",
PleaseEnterAuthorizationCode: "Please enter the authorization code",
PleaseEnterBindingCredentialName: "Please enter the ID number to bind",
PleaseEnterBindingCredentialType: "Please select the credential type to bind",
PleaseEnterBindingMobile: "Please enter the mobile number to bind",
PleaseEnterBindingUserName: "Please enter the name to bind",
PleaseEnterCompnayInfo: "Please enter the enterprise name",
PleaseEnterContactName: "Please enter the contact name",
PleaseEnterCorrectEmail: "Please enter the correct email address",
PleaseEnterCorrectMobile: "Please enter a valid mobile number",
PleaseEnterEheCorrectFormatOfValidityPeriod: "Please enter the correct format of the credit card validity period",
PleaseEnterIDNumber_x: "Please enter the %a$ ID number",
PleaseEnterInformation: "Please enter the information",
PleaseEnterKey: "Please enter the keyword to search for",
PleaseEnterMobileVerification: "Please enter the mobile verification code",
PleaseEnterPhone: "Please enter the phone",
PleaseEnterPhoneNumber_x: "Please enter the %a$ mobile number",
PleaseEnterPhoneNumberOrEmail: "Please enter your phone number/email",
PleaseEnterRecipient: "Please enter the recipient's name",
PleaseEnterRegisterInfo: "Please enter the registration information",
PleaseEnterSMSCode: "Please enter the SMS verification code",
PleaseEnterThe_x: "Please enter the %a$",
PleaseEnterTheAccountForRetrievePassword: "Please enter the account you want to retrieve your password first",
PleaseEnterTheAccountInformationYouNeedToRetrieveYourLoginPassword: "Please enter the account information you need to retrieve your login password",
PleaseEnterTheAirport: "Please enter the airport",
PleaseEnterTheArriveAddress: "Please enter the drop-off location",
PleaseEnterTheBindingEmail: "Please enter the binding email",
PleaseEnterTheBindingPhone: "Please enter the binding phone number",
PleaseEnterTheBindingPhoneNumberEmail: "Please enter the binding phone number/email",
PleaseEnterTheBindingPhoneNumberEmail_1: "Please enter the binding phone number/email",
PleaseEnterTheCardNumber: "Please enter the card number",
PleaseEnterTheCardNumberOrUsername: "Please enter the card number or username",
PleaseEnterTheCarRank: "Please enter the car rank",
PleaseEnterTheCity: "Please enter the city",
PleaseEnterTheCityNameToSearch: "Please enter the city name to search",
PleaseEnterTheCompanyAddress: "Please enter the company address",
PleaseEnterTheCorrectAccountPassword: "Please enter the correct account password",
PleaseEnterTheCorrectCreditCardNumber: "Please enter the correct credit card number",
PleaseEnterTheCorrectMobilePhoneNumberEmail: "Please enter the correct mobile phone number/email",
PleaseEnterTheCorrectVerificationCode: "Please enter the correct verification code",
PleaseEnterTheCostCenter: "Please enter the cost center",
PleaseEnterTheCountry: "Please enter the country/region",
PleaseEnterTheCurrentPassword: "Please enter the current password",
PleaseEnterTheCustomItems: "Please enter the custom items",
PleaseEnterTheDepartAddress: "Please enter the pick-up location",
PleaseEnterTheDepartment: "Please enter the department",
PleaseEnterTheFlightBookPermission: "Please enter the flight booking permission",
PleaseEnterTheHomeAddress: "Please enter the home address",
PleaseEnterTheHotelBookPermission: "Please enter the hotel booking permission",
PleaseEnterTheHotelCity: "City/Landmark/Hotel Name",
PleaseEnterTheIntlHotelCity: "City/Hotel",
PleaseEnterTheLastThreeDigitsOfTheCVVSecurityCode: "Please enter the last three digits of the CVV security code",
PleaseEnterTheName: "Please enter the name",
PleaseEnterTheNameOfThePerson: "Please enter the name of the guest or order number",
PleaseEnterTheNameOfThePerson_1: "Please enter the name of the guest or order number or hotel name",
PleaseEnterTheNameOfTheRank: "Please enter the name of the rank",
PleaseEnterTheNameOfTheRole: "Please enter the name of the role",
PleaseEnterTheNewPassword: "Must be 8-20 letters and numbers",
PleaseEnterTheOldPassword: "Please enter the old password",
PleaseEnterThePassengerName: "Please enter the passenger name or order number",
PleaseEnterThePassword: "Please enter the login password",
PleaseEnterTheSystemRole: "Please enter the system role",
PleaseEnterTheTemplate: "Please enter the approval template name",
PleaseEnterTheTrainAccountNewPassword: "6-20 letters and numbers",
PleaseEnterTheTrainAccountNewPassword_1: "Please enter at least six characters",
PleaseEnterTheTrainBookPermission: "Please enter the train booking permission",
PleaseEnterTheTrainStation: "Please enter the train station",
PleaseEnterTheTraveler: "Please enter the name/email/phone number/employee number to search for",
PleaseEnterTheTraveler_1: "Search for name/email/phone number/employee ID/nickname",
PleaseEnterTheTraveler_2: "Search for name/email/phone number/ID Number",
PleaseEnterTheUnitCodeOrUnitShortName: "Please enter unit code or short name",
PleaseEnterTheValidityPeriodOfTheCreditCard: "Please enter the validity period of the credit card",
PleaseEnterYourAccountInformation: "Please enter your account information",
PleaseEnterYourExceedReason: "Please enter the reason for exceeding",
PleaseEnterYourNewPasswordAgain: "Please enter new password again",
PleaseEnterYourNewPasswordAgain_1: "Please confirm the password",
PleaseFill: "Please fill in",
PleaseFill_x: "Please fill in %a$",
PleaseFill_x_x: "Please fill in %a$ of %b$",
PleaseFillBudget: "Please fill in budget",
PleaseFillBudget_x: "Please fill in budget for %a$",
PleaseFillIn: "Please fill in new phone number.",
PleaseFillIn_x: "Please fill in %a$",
PleaseFillInCardNumber: "Please fill in the card number",
PleaseFillInCostCenterName: "Please fill in cost center name",
PleaseFillInDepartmentName: "Please fill in department name",
PleaseFillInDiner: "Please fill in diner's name",
PleaseFillInEmail_x: "Please fill in email for %a$",
PleaseFillInFirstName: "Please fill in first name",
PleaseFillInGuestName: "Please fill in guest's name",
PleaseFillInIDNumber: "Please fill in ID number",
PleaseFillInLastName: "Please fill in last name",
PleaseFillInMobile_x: "Please fill in mobile number for %a$",
PleaseFillInName: "Please fill in name",
PleaseFillInNewPhoneCurrentPhone: "Please fill in new phone number. Current phone number: ",
PleaseFillInOrderNumber: "Please fill in order number",
PleaseFillInPassengerName: "Please fill in passenger's name",
PleaseFillInPhoneNumber: "Please fill in phone number",
PleaseFillInRC: "Please fill in RC",
PleaseFillInTheContactMobilePhoneNumber: "Please fill in contact's mobile phone number",
PleaseFillInTheContactMobilePhoneNumber_x: "Please fill in contact's %a$ mobile phone number",
PleaseFillInTheReasonForRefund: "Please fill in reason for refund",
PleaseFillInTheReasonForRescheduling: "Please fill in reason for rescheduling",
PleaseFillInTheShippingAddress: "Please fill in shipping address",
PleaseFillInVerificationCode: "Please fill in verification code",
PleaseFillOrChoose_x: "Please fill in or select %a$",
PleaseFillTotalBudget: "Please fill in total budget",
PleaseGetTheVerificationCode: "Please get the verification code first",
PleaseHoldDownTheSliderAndDrag: "Please hold down the slider and drag it to the rightmost position",
PleaseHoldThePhoneVertically: "Please hold the phone vertically",
PleaseInputCreditCardNumber: "Please enter the credit card number",
PleaseInputYourDestination: "Please enter your destination",
PleaseMakeSureYouHaveEnoughTimeToCheckIn: "1. Please make sure to go to the check-in counter before purchasing the ticket to confirm that there is still enough time for check-in after ticket issuance.",
pleaseOpenScanOnAlipayScanQRCodeToPay: "Please open the Alipay app on your phone, go to the scan QR code function, and scan the QR code to make the payment",
pleaseOpenScanOnWeChatScanQRCodeToPay: "Please open the WeChat app on your phone, go to the scan QR code function, and scan the QR code to make the payment",
PleasePressBackOnceToExit: "Please press the back button once to exit",
PleaseReadAndAgreeTo: "Please read and agree to the Account Authorization Agreement",
PleaseReadAndAgreeTo_x: "Please read and agree to %a$",
PleaseReadAndTickTheAgreementFirst: "Please read and tick the agreement first",
PleaseReselectYourCheckInDate: "Please reselect your check-in date",
PleaseSelect_x: "Please select %a$",
PleaseSelect_x_x: "Please select %b$ of %a$",
PleaseSelectADepartureDate: "Please select a departure date",
PleaseSelectADestination: "Please select a destination",
PleaseSelectAirport: "Please select the airport",
PleaseSelectApprover: "Please select approver",
PleaseSelectArea: "Please select the area",
PleaseSelectArrival: "Please select the arrival",
PleaseSelectArrivalAirport: "Please select the arrival airport",
PleaseSelectArrivalCity: "Please select the arrival city",
PleaseSelectArrivalTrainStation: "Please select the arrival train station",
PleaseSelectAtLeastOneCityOverseas: "Please select at least one international city or regions / in HK, MC, TW",
PleaseSelectAtLeastOneOccupant: "Please select at least one occupant",
PleaseSelectBackDate: "Please select the return date",
PleaseSelectBusinessTravelScale: "Please select the monthly business travel scale",
PleaseSelectCertificate: "Please select the proof",
PleaseSelectCertificateNo: "Please enter the ID number",
PleaseSelectCostCenter: "Please select the cost center!",
PleaseSelectDepartment: "Please select the department!",
PleaseSelectDeparture: "Please select the departure",
PleaseSelectDepartureAirport: "Please select the airport",
PleaseSelectDepartureTrainStation: "Please select the train station",
PleaseSelectDestination: "Please select your destination",
PleaseSelectDiner: "Please select the diner",
PleaseSelectedTheCheckInDate: "Please select the check-in date",
PleaseSelectFlightChangeDate: "Please select a flight rescheduling date",
PleaseSelectFlightChangeDate_x: "Please select the %a$ flight rescheduling date",
PleaseSelectFlightDate: "Please select the flight date",
PleaseSelectFlightNumber: "Please enter the flight number",
PleaseSelectGuest: "Please select the guest",
PleaseSelectGuestLeastOne: "Please select the guest",
PleaseSelectHotelArrivalTime: "Please select the arrival time at the hotel",
PleaseSelectLoginTypeWitchAnytime: "Please select any of the following entry points to log in",
PleaseSelectOrFillInMealsRemarks: "Please select/fill in the dining remarks",
PleaseSelectOrFillInPurpose: "Please select/fill in the purpose",
PleaseSelectOrFillInTheReasonForCancelReason: "Please select/fill in the reason for cancellation",
PleaseSelectOrFillInTheReasonForExceedingTheStandard: "Please select/fill in the reason for exceeding the standard",
PleaseSelectOrFillInTheReasonForRefundReason: "Please select/fill in the reason for refund",
PleaseSelectOrFillInTheReasonForReschedulingReason: "Please select/fill in the reason for rescheduling",
PleaseSelectOrFillInTheReasonForViolatingTheTravelRank: "Please select/fill in the reason for violating the travel rank",
PleaseSelectOrFillInTheReasonOrPleaseSelectTheMixedPayment: "Please select/fill in the reason for exceeding the limit or choose to mixed payment",
PleaseSelectPassenger: "Please select the passenger",
PleaseSelectPassengersFirst: "Please select the passenger first",
PleaseSelectPaymentWay: "Select the payment method",
PleaseSelectPurpose: "Please select the purpose",
PleaseSelectSeats: "Please select the seats",
PleaseSelectTheCardExpirationMonth: "Select Month",
PleaseSelectTheCardExpirationYear: "Select year",
PleaseSelectTheDateOfBirth: "Please select the date of birth",
PleaseSelectTheDepartureCity: "Please select the departure city",
PleaseSelectTheFavoriteToDeleteFirst: "Please select the favorite to delete first",
PleaseSelectTheFirstSeat: "Please select the seat for the first leg",
PleaseSelectTheFollowingMethodsForVerification: "Please select the following methods for verification",
PleaseSelectTheItineraryToBeRescheduled: "Please select the itinerary to be rescheduled",
PleaseSelectTheMixedPayment: "Please choose to continue booking with mixed payment",
PleaseSelectThePassengerWhoNeedsToChange: "Please select the passenger who needs to change",
PleaseSelectThePassengerWhoNeedsToRefund: "Please select the passenger who needs to refund",
PleaseSelectThePassengerWhoNeedsToRefund_x: "Please select the passenger who needs to refund %a$",
PleaseSelectThePassengerWhoNeedsToReschedule: "Please select the passenger who needs to reschedule",
PleaseSelectTheRoomAndRoomer: "Please select the room and roomer",
PleaseSelectTheSecondSeat: "Please select the seat for the second leg",
PleaseSelectTheShippingAddress: "Please select the shipping address",
PleaseSelectTheTravelApplication: "Please select the travel application",
PleaseSelectTheTypeOfQuestion: "Please select the type of question",
PleaseSelectTrainStation: "Please select the train station",
PleaseSelectTraveler: "Please select the traveler",
PleaseSendTheAbovePaymentTo: "Please send the above payment to",
PleaseSure: "Please make sure",
PleaseUploadAttachFile_x: "Please upload %a$",
PleaseUseTheFollowingCreditCard: "Kind reminder: Please use the following credit card",
PleaseVerifyThatYouHaveAFaceIDForLogin: "Please verify that you have a Face ID for login",
PleaseVerifyThatYouHaveATouchIDForLogin: "Please verify that you have a Touch ID for login",
PointYourFaceAtTheFrontCameraOfYourPhone: "Point your face at the front camera of your phone",
PoliciesServices: "Policies & Services",
Policy_1: "Policy",
PolicyAddress: "Policy Address",
PolicyNoColon: "Policy number:",
PopularDestinations: "Popular destinations",
PopularFilters: "Popular filters",
PopularRoutes: "Popular routes",
Pptimization: "Optimal",
Precautions: "Caution",
Predict: "Predict",
Preferential: "Preferential",
Preferential_1: "This order can enjoy",
PremiumHotel: "Premium hotel",
PremiumHotel_1: "Premium",
Prepay: "Prepay",
Prepayments: "Prepay",
PreQuantifier: " per person",
PressVoice: "Hold the tape",
PreviewNotSupportedAndIsCopy: "Preview is not supported for the time being, it has been copied to the clipboard, please open it in the browser",
Price: "Price",
Price_1: "Price",
Price_2: "Star rating price",
Price150: "Under ¥150",
Price150_300: "¥150-300",
Price300_450: "¥300-450",
Price400: "Under ¥400",
Price400_700: "¥400-700",
Price450_600: "¥450-600",
Price600_1000: "¥600-1000",
Price700_1000: "¥700-1000",
Price1000_1300: "¥1000-1300",
Price1300_1800: "¥1300-1800",
PriceCeiling: "Highest price",
PriceDetails: "Price details",
PriceHighest: "Price(Highest)",
PriceHighToLow: "Price (High to low)",
PriceHotel: "Hotel rate",
PriceLowest: "Price(Lowest)",
PriceLowToHigh: "Price (Low to high)",
PriceOfPerson_x_x: "%a$ X %b$ person(s)",
PriceOver1000: "Over ¥1000",
PriceOver1800: "Over ¥1800",
PricePerPerson_x: "%a$ per person",
PriceRating: "Price / Rating",
PriceRating_1: "Price / Rating",
PriceSingleChoice: "Price (single)",
Print: "Print",
PriorityHighPrice: "High price priority",
PriorityHighRating: "High Star Priority",
PriorityLowPrice: "Low price priority",
PrivacyPolicy: "Privacy policy",
Processing: "Unprocessed",
ProcessingCompleteTime: "Processing completion time",
ProcessingTime: "Processing time",
ProduceDesc: "Product description",
ProduceName: "Product name",
Product: "Product",
ProductDesc: "Product description",
ProductExperience: "Product experience",
ProductIntroduction: "Product introduction",
ProductOverview: "Product overview",
ProductUseRuleDes: "Usage rules",
Progress: "Progress",
PromptDateHotel: "Your choice is the local date of the hotel",
PromptDatePrice: "The selected date is the departure date, and prices may fluctuate. Please refer to the actual price.",
ProportionOfContractedHotelStarLevelTransactions: "Proportion of contracted hotel star level transactions",
ProportionOfDomesticFullPriceTickets: "Proportion of domestic full-price tickets",
ProportionOfHotelRoomNightsInAgreement: "Proportion of hotel room nights in agreement",
ProportionOfNetTransactionPrice: "Proportion of net transaction price",
ProportionOfRCTimes: "Proportion of RC times",
PullToRefresh: "Pull to refresh",
PullUpLoadMore: "Swipe up for more",
Punctuality: "Punctuality",
Punctuality_x: "Punctuality %a$",
PurchaseXItemAtMost_x: "Purchase at most %a$ items",
Purpose: "Business trip purpose",
PutTheQRCodeIntoTheBox: "Put the QR code into the box to scan automatically",
QrCode: "QR code",
QRCodeHasExpired: "QR code has expired",
QuantifierHotel: " room(s)",
QuantifierNight: " night(s)",
QuantifierPeople: "",
Quantity: "Quantity",
Quarterly: "Quarterly",
QueryConditions: "Query conditions",
QueryNotJurisdiction: "You do not have query jurisdiction",
QueryQuoteErrors: "Query quote failed",
QueryRange: "Query range",
QueryResultOfTheCurrentCity: "Query result of the current city",
QueryType: "Query type",
QuickSelection: "Quick selection",
R: "Return",
RankDescription: "Rank description",
RankInformation: "Rank information",
RankManagement: "Rank",
RankName: "Ranking",
Rating: "Rating",
RatingHighToLow: "Rating (High to low)",
RatingLowToHigh: "Rating (Low to high)",
RatingMultipleChoice: "Rating (multiple)",
RCContent: "RC content",
RCManagement: "RC",
RCType: "Product",
Read: "Read",
Ready_x: "Predict %a$",
Reapply: "Reapply",
ReasonForNotSelectingTheCheapestFlight: "Reason for not selecting the cheapest flight",
ReasonForNotSelectingTheLowestPrice: "Please select",
ReasonForTheRefundTicket: "Refund type",
ReasonForTicketIssueFailure: "Reason for ticket issue failure",
ReasonForTravelRankingViolation: "Reason for travel ranking violation",
ReasonsForBusiness: "Business trip",
ReasonsForBusiness_x: "Business trip：%a$",
ReBook: "Rebook",
ReceiveMode: "Receive mode",
RecentSearches: "Recent searches",
Recharge: "Recharge",
RechargeAmount: "Recharge amount",
Recipient: "Recipient",
Recommend: "Recommend",
Recommend_1: "Recommend",
RecommendCabinPrice: "Recommend preferred prices for the same flight to you",
Recommended: "Recommended",
RecommendedFlights: "Recommended flights",
RecommendedTransferFlights: "Recommended Transit Flights",
RecordsExchange: "Exchange records",
ReElection: "Re-select cabin",
RefreshFlight: "Refresh flights",
RefreshPageTip_1: "The order approval status has changed. Refresh the page to get the latest updates.",
RefreshPrices: "Refresh",
RefreshRetry: "Please refresh the page and try again~",
RefreshSucceed: "Refresh successful",
Refund: "Refund",
Refund_1: "Refund",
RefundAmount: "Refund amount",
RefundAmountPendingApproval: "Refund amount to be reviewed",
RefundCertificate: "Refund proof",
RefundChangeAndBaggageInstructions: "Refund, change and baggage instructions",
RefundDetails: "Refund details",
RefundFailed: "Refund failed",
RefundFee: "Refund fee",
Refunding: "Refunding",
RefundInstructions: "Refund instructions",
RefundOrChangeExplain: "Refund/Change description",
RefundOrderDate: "Refund order date",
RefundOrderDetails: "Refund details",
RefundOrderNumber: "Refund Order No",
RefundOrderNumber_x: "Refund Order No %a$",
RefundOrderTime: "Refund order time",
RefundPassenger: "Refund passenger",
RefundPolicy: "Refund policy",
RefundRate: "Refund rate",
RefundReason: "Reason",
RefundSucceed: "Refund successful",
RefundTrip: "Refund itinerary",
Regard: "Follow",
RegardEmployees: "People I follow",
RegardFailed: "Follow failed",
RegardSuccess: "Follow successful",
Register: "Register",
Register_1: "Registration information",
Register_2: "Mobile verification",
Register_3: "Waiting for review",
RegisterSuccess: "We will contact you as soon as possible. Please keep your phone line open. Thank you!",
Reject: "Reject",
RelationAccount: "Bind account",
RelationLogin: "Bind and login",
RelationToHOMSOM: "Please bind your login account",
ReleaseImmediateLoad: "Release to load immediately",
ReleaseImmediateRefresh: "Release to refresh immediately",
ReleaseVoice: "Release to end",
Relevance: "Relevance",
Remaining_1_x: "Remaining %a$",
Remaining_x: "%a$ remains",
RemainingRoom_x: "%a$ room",
RemainingUseLxd_x: "surplus:%a$ (1 travel bean=1 CNY)",
Remarks: "Remarks",
ReminderYouCanImportFrequentTravelerInFrequentTravelerManagement: "Reminder: Frequent flyer can also add personnel information by importing it in frequent flyer management.",
RemovalFailedPleaseTryAgainLater: "Removal failed. Please try again later.",
RemoveFrequentTraveller: "Remove this frequent traveler",
RemoveFromTheTiedList: "Remove from the tied list",
RemoveSuccessful: "Removal successful",
RemoveThe_x: "Remove this %a$",
RemovetheCostCenter: "Remove this cost center",
RemoveTheDepartment: "Remove this department",
RemoveTheEmployee: "Remove this employee",
RemoveTheNonEmployee: "Remove this Outsiders",
RemoveTheRC: "Remove this RC",
RemoveThisFrequentContact: "Remove this frequent contact",
RenovationYears: "Renovation",
ReportTotal: "Total",
ReQuery: "look up again",
RequiredChoose: "Required",
RequiredChoose_x: "%a$ person needs to be selected",
RequiredChooseArrivalCityAddressDesc: "(Required) Add transit city",
RequiredChoosePleaseChoose: "(Required) Please choose",
RequiredChooseUneditable: "(Required) Uneditable",
RequiredFill: "Required",
RequiredFill_x: "%a$ Required",
RequiredFillPleaseFill: "(Required) Please fill in",
RequiredFillPleaseFillOrChoose: "(Required field) Please fill in or select",
RequiredFillUneditable: "(Required) Uneditable",
RequiredReadingForBook: "Must read for travel",
RequiredReadingForChange: "Rescheduling Notice",
RequiredReadingForHotel: "Must-read for booking",
RequiredReadingForRefund: "Refund Notice",
RequiredReadingForTrainChange: "Notice",
ReScan: "ReScan",
Reschedule: "Request reschedule",
Rescheduled: "Rescheduled",
Rescheduling: "Reschedule",
ReschedulingFee: "Reschedule fee",
ReschedulingReason: "Reschedule reason",
Reselect: "Reselect",
Reselect_1: "Reselect",
ReselectFlight: "Re select flight",
ReSelectPrice: "Re select",
Resend: "Resend",
Resend_x: "resend %a$s",
ResendAfterXSeconds_x: "%a$s",
ResendConfirmationMessage: "Resend confirmation message",
ResendPleaseWait: "Resend, please wait",
ResendPleaseWait_x: "Resend, please wait %a$",
Reset: "Reset",
ResetFilter: "Reset",
ResetPassword: "Reset password",
ResetUsingBoundEmail: "Reset with a bound email address",
ResetUsingBoundPhoneNumber: "Reset with a bonded mobile phone number",
Rest: "Rest",
Result: "Search results",
RetrieveByEmail: "Retrieve by email",
RetrieveByMobile: "Retrieve by mobile",
RetrievePassword: "Retrieve password",
RetrievePasswordByMobile: "Mobile phone recovery",
Retry: "Retry",
Return: "Return",
Return_1: "Return:",
ReturnAndChangeInstructions: "Refund & Cancellation instructions",
ReturnColon: "Return:",
ReturnDate: "Return date",
ReturnTicket_x: "%a$ (Return)",
ReVetting: "Reissue",
RevisedFlightInformation: "Updated flight information:",
ReviseTheDifference: "Revise price difference",
RideInfo: "Ride information",
RMB: "CNY",
RMB_x: "%a$ CNY",
RMBNight: "CNY/night",
RMBPrice_x_x: "¥%a$-%b$",
RMBPriceOver_x: "Over ¥%a$",
RMBPriceUnder_x: "Under ¥%a$",
RoleDescription: "Role description",
RoleDetail: "Role details",
RoleManagement: "Role",
RoleName: "Role name",
Room: "Room",
Room_x: "%a$ room(s)",
RoomAdult_x_x: "%a$ room(s) %b$ adult(s)",
RoomAdult_x_x_x: "%a$ room(s) %b$ adult(s) %c$ child",
RoomAndNight_x_x: "%a$ room(s) %b$ night(s)",
Roomer: "Guest",
RoomerDetails: "Guest details",
RoomerName: "Guest",
RoomFees: "Room fees",
RoomNight: "Room night",
RoomNightPrice_x_x: "%a$ room(s)·%b$ night(s)",
RoomNoXAtLeastChooseOneRoomer_x: "Room %a$ requires at least one guest to be selected",
RoomsAndGuests: "Rooms and guests",
RoomType: "Room type",
RoomTypeDetails: "Details",
RoomTypeRemarks: "Remarks",
RoundTrip: "Round-Trip",
Route: "Route",
RouteDepartDateCannotBeLessThanLastRoute_x_x: "Departure date of route %a$ cannot be earlier than the departure date of route %b$",
RUB: "RUB",
Rules: "Rules",
SafetyAlarmRequired: "Safety alarm required",
SafetyManage: "Safety",
SameLevelApprover: "Same-level approver",
SameStationTransfer: "Same-station transfer",
Save: "Save",
Save_1: "Save",
Save_2: "Save",
Save_3: "Save",
SaveFailed: "Save failed",
SaveFailedFromCode: "Failed to save QR code",
SaveOrderError: "Error saving order. Please try returning to the flight search page and select again!",
SaveQRCode: "Save QR code",
SaveSucceed: "Save successful",
SaveSucceedToPhotoLibary: "Saved to photo library",
SavingAnalysisofBookinginAdvance: "Savings analysis for booking in advance",
SavingAndMissedSaving: "Savings analysis",
Savings: "Savings",
SavingsLosses: "Savings & losses",
ScaleLevel_1: "10k - 30k",
ScaleLevel_2: "30k - 100k",
ScaleLevel_3: "100k - 300k",
ScaleLevel_4: "300k - 1M",
ScaleLevel_5: "More than 1 million",
ScanAndLogin: "Scan and login",
ScanCodeLogin: "Scan code login",
ScanSuccessfully: "Scan successful",
ScanTheQRCode: "Scan the QR code",
Schedule: "Schedule",
ScheduledItinerary: "Scheduled itinerary",
ScheduleTip: "Tip: It depends on the actual operation of the train. Please pay attention to the station broadcast in time.",
SchemIndex_x: "Scheme %a$",
SchemIndexSelect_x: "Scheme %a$ (selected)",
ScoreByComments: "",
Search: "Search",
Search_1: "Search",
Search_2: "Search",
Search_3: "Search",
SearchCanBeRescheduled: "Search for rescheduled flights",
SearchFlight: "Search",
SearchForMoreCities: "Search for more cities",
SearchHistory: "Search history",
SearchHotel: "Search",
SearchThisArea: "Search this area",
SearchTrain: "Search",
Seat: "Seat",
SeatInfo: "Seat info",
SeatNumber: "Seat number",
SeatSelectionDepartingTrip: "Seat selection (Depart)",
SeatSelectionReturnTrip: "Seat selection (Return trip)",
SeatSelectionSucceed: "Seat selection successful",
SecondAfter_x: "%a$s",
SecondClass: "Second class",
SecondStep: "Second step",
SecurityAlarmDesc: "If you are in danger, please call 110 immediately",
SecurityAssistant: "Security assistant",
SecurityCenter: "Security center",
SecurityCode: "Security code",
See: "See",
SeeAll: "See all",
SeeAll_x: "See all %a$ price(s)",
SeeDetails: "Details",
Segment: "Segment",
SegmentRecommend: "Recommend",
Select: "Selected",
Select_1_x: "Selected: %a$",
Select_x: "Select %a$",
SelectAll: "Selected",
SelectApprover: "Select approver",
SelectCabin: "Select class",
SelectCarPlatform: "Select car platform",
SelectCarScene: "Select car scene",
SelectCity: "Select city",
SelectConfirmationOrder: "Select Confirmation Form",
SelectContact: "Select contact",
SelectCustomItems: "Select custom items",
SelectDate: "Select date",
SelectDeparting: "Select depart",
Selected: "Selected",
Selected_1: "Selected",
Selected_Train_codes_x: "%a$ trains have already been selected",
Selected_x_x: "%a$ selected %b$",
SelectedAddress: "Select location",
SelectedAsGoing: "Selected as going",
SelectedFlights: "Selected flights",
SelectedPassengers: "Selected passengers",
SelectedPassengerVehicle: "Passenger has been selected",
SelectedTraveler_x: "Selected: %a$",
SelectEmployees: "Select employees",
SelectFlight: "Select flight",
SelectFlightChangeInfo: "Select rescheduled flight information",
SelectFlightChangeStep1: "01.Passengers who choose to reschedule their flights",
SelectFlightChangeStep2: "02.Choose a rescheduled itinerary",
SelectFlightChangeStep3: "03.Choose a rescheduled date",
SelectFlightOnline: "Select itinerary",
SelectFlightRefund: "Choose a refund itinerary",
SelectFlightRefundInfo: "Select flight information for ticket refund",
SelectFlightRefundStep1: "01.Passengers who choose to refund their tickets",
SelectFlightRefundStep2: "02.Select the itinerary for ticket refund",
SelectFlightRescheduling: "Choose to reschedule your itinerary",
SelectFromTheAlbum: "Select from the album",
SelectInvoicingType: "Select invoicing type",
Selection: "Seat selection",
SelectionResult: "Seat selection result",
SelectLanguage: "Select language",
SelectOneWay: "Select one-way",
SelectPassengers: "Select passengers",
SelectReturn: "Select return",
SelectTheCardExpirationMonth: "Select Month",
SelectTheCardExpirationYear: "Select year",
SelectTheNumberOfRooms: "Select the number of rooms",
SelectThePassenger: "Select the passenger",
SelectThePassengerVehicle: "Select passenger",
SelectTheReasonForTheRefundTicket: "Select the refund type",
SelectTheRescheduleType: "Select the reschedule type",
SelectTrain: "Select train",
SelectTrainChangeInfo: "Select change information",
SelectTrainChangeStep1: "Select the passenger for rescheduling",
SelectTrainChangeStep2: "Select the rescheduled itinerary",
SelectTrainRefund: "Select the refund itinerary",
SelectTrainRefundInfo: "Select refund information",
SelectTrainRefundStep1: "Select the passenger for refund",
SelectTrainRefundStep2: "Select the itinerary for refund",
SelectTrainRescheduling: "Select the rescheduled itinerary",
SelectYourCabin: "Select your cabin",
SelectYourSeat: "Select your seat",
SelectYourSeatDeparting: "Select your seat (depart)",
SelectYourSeatReturn: "Select your seat (return)",
Self: "Self",
SelfPay: "Self payment",
Send: "Send",
SendATo12306: "Send to 12306",
SendDistressMessage: "Send distress message",
SendEmailOrPhone: "Send to phone/email",
SendFailure: "Send failed",
SendingLXD: "Gift LXD",
SendMail: "Send mail",
SendMailSuccessfullyPleaseCheckTheMailboxToResetThePassword: "Email sent successfully. Please check your mailbox to reset the password.",
SendMoneyToTheFollowingAccount: "Send money to the following account",
SendSMS: "We have sent an SMS to",
SendSMS_1: ", please enter the verification code you received.",
SendSMS_2: "Send SMS",
SendSMS_x: "We have sent a verification SMS to %a$. Please enter the verification code you received.",
SendSucceed: "Send succeeded",
SendTo12306_x: "Send %a$ to 12306",
SendVerificationCode: "Send verification code",
SendVerificationCode12306: "Send 666 to 12306",
SendVerificationCodeTo12306ByMobilePhoneUpToTimesPerDay_x_x: "Send verification code %b$ to 12306 using mobile phone %a$, up to 3 times per day",
SerialNumber: "Number",
SeniorApprover: "Direct leader",
Service: "Customer service",
Service_1: "Service",
ServiceAgreement: "Service agreement",
ServiceFee: "Service fee",
ServiceFeePerPerson_x: "Service fee: %a$ per person",
ServiceLife: "Service life",
SetAsDefaultContact: "Set as default contact",
SetAsStaff: "Set as staff",
SetEmergencyContact: "Set emergency contact",
SetNewPassword: "Setting a new password",
SetSuccessfully: "Set successfully",
Setting: "Setting",
SettlementMethod: "Settlement method",
SettlementPeriod: "Settlement period",
SettleNow: "Now closed",
SGD: "Singapore Dollar",
ShareDescPaymentOnBehalf_x: "I booked a %a$ at Homsom，お支払いをお願いしますよ~",
SharedFlight: "Shared flight",
SharePDF: "Share PDF",
ShareTitlePaymentOnBehalf_x: "%a$ hopes you can help him make the payment",
ShareWithFriends: "Share with friends",
ShippingAddress: "Shipping address",
ShowAvailableTicketsOnly: "Show available tickets only",
ShowMoreSuppliers: "Show more suppliers",
ShowTheFrontOfYourFace: "Show the front of your face",
SigningPolicy: "Signing policy",
SignOut: "Sign out",
SignOut_1: "Sign out",
Skip: "Skip",
SkipLogin: "Skip the login",
SlideUpToSeeMore: "Swipe up for more",
SlideYourFingerUp: "Slide your finger up to cancel sending",
Smoke: "Smoking",
SMS: "SMS",
SMSVerification: "SMS verification",
Snatch: "Rob",
SnatchCodeWarn_1: "The more train numbers you select, the higher the success rate of ticket grabbing will be.",
SnatchCodeWarn_2: "Pre - collect at the highest price",
SnatchDeadline_x: "%a$ before departure",
SnatchDeadlineHour_x: "%a$ hour before departure",
SnatchDeadlineMinute_x: "%a$ minute before departure",
SnatchInfo: "Ticket Snatching Info",
SnatchSeatsWarn_1: "·Select multiple seats, and the success rate of ticket grabbing will be higher",
SnatchSeatsWarn_2: "·The highest ticket price will be temporarily charged after selection, and the difference will be refunded according to the actual ticket price after successful ticket grabbing.",
SnatchTicket: "Scalping",
SnaTchTicketDetails: "Grab ticket details",
SoldOut: "Sold out",
SorryCantFind: "Sorry, can't find",
SorryCouldNotFindIt_x: "Sorry, nothing related to “%a$” was found.",
SorryNoCabinAvailable: "Sorry, no cabin available",
SorryNoMatchingFlight: "Sorry, no matching flights!",
SorryNoMatchingHotel: "Sorry, no matching hotels!",
SorryNoMatchingRoom: "Sorry, no matching room types!",
SorryNoMatchingTrain: "Sorry, no matching trains!",
SorryThePageYouVisitedDoesNotExist: "Sorry, the page you visited does not exist.",
SorryThereAreNoSearchResultsFor_x: "Sorry, there are no search results for %a$.",
Sort: "Sorting",
SortRecommend: "Recommend",
SpecialLineForTravelConsultants: "Special line for travel consultants",
SpecialOffer: "Special offer",
SpecificRequirements: "Specific requirements",
Spending: "Spending",
Staff: "Staff",
StaffAdd: "New",
StaffNo: "Staff number",
StarComfort: "3",
StarComfort_1: "3",
StarEconomic: "2",
StarEconomic_1: "2",
StarLuxury: "5",
StarLuxury_1: "5",
StarPremium: "4",
StarPremium_1: "4",
StarRating: "Star rating",
Start: "Start",
StartDate: "Start date",
StartVerification: "Start verification",
StationDropOff: "Station drop-off",
StationDropOff_x: "Drop off at %a$",
StationName: "Station",
StationPickup: "Station pickup",
StationPickup_x: "%a$ station pickup",
Stay: "Stay",
Stay_1_x_x: "%a$ stop for %b$",
Stay_2_x_x: "Transit %a$, stay %b$",
Stay_3_x_x: "Stop %a$, stay %b$",
Stay_x: "Stay %a$",
Stayed: "Stayed",
Stop: "",
Stop_1: "S",
Stop_1_x: "%a$S",
Stop_2: "Stop",
Stop_2_x: "%a$ stops",
Stop_x: "Stop %a$",
StopBy_x: "%a$",
StopInfo: "Stop info",
StopsFor_x: "Stop for %a$",
StowMoreSuppliers: "Stow more suppliers",
SubmissionTime: "Submission time",
Submit: "Submit",
Submit_2: "Submit",
SubmitAndPay: "Submit and pay",
SubmitOrder: "Submit",
SubmitOrderForChange: "Submit",
SubmitOrderForChange_1: "Submit",
SubmitSucceed: "Submission successful",
Submitted: "Submitted",
SubmittedRefund: "Submit refund",
SubmitVetting: "Submit",
Subsidiary: "Subsidiary",
SubwayStation: "Subway station",
SuccessfullySaved: "Save successful",
SuccessfulPasswordModification: "Password modification successful",
SuggestMultipleChoices: "Multiple selection is recommended",
SuperEconomyClass: "Super economy class",
Superior_x: "Superior %a$",
SuperiorCostCenter: "Superior cost center",
SuperiorDepartment: "Superior department",
SupportingMixedPayment: "Supporting mixed payment",
Surname: "Surname",
SurnameEgLI: "e.g.HAN",
Surplus: "Surplus",
SwimmingPool: "Swimming pool",
SwipeVerification: "Swipe Verification",
SwitchAccount: "Switch",
SwitchAndAccess: "Switch and access",
SwitchLogin: "Login",
SwitchPerson: "Switch members",
SwitchPersonAccount: "Switch member account",
SwitchUnit: "Switching units",
SwitchUnitAccount: "Switch company account",
SyncDate: "Sync date",
SystemApproval: "System approval",
SystemPermissionCamera: "Camera permission not granted. Please enable it in the settings.",
SystemPermissionDevice: "Device read/write permission not granted. Please enable it in the settings.",
SystemPermissionInstall: "Manual authorization is required to install this application. Please go to settings and enable the permission.",
SystemPermissionLocation: "Location permission not granted. Please enable it in the settings.",
SystemRecommendedPrice: "System recommended price",
SystemRole: "System role",
TakeAPicture: "Take a picture",
TakeOffTimeAnalysis: "Takeoff time analysis",
TakesOff: " takes off",
TakesOff_x: "Take off at %a$",
TakesOffArrival_x_x: "%a$ arrives, %b$ takes off",
TalkAboutYourSuggestionOrProblem: "Talk about your suggestion or problem",
Tax: "Tax",
Tax_1: "Tax",
Taxi: "Taxi",
TaxpayerIDNumber: "Taxpayer ID number",
TaxPrice_x: "Tax: %a$",
TaxRegisterAddress: "Tax register address",
TeamLink: "Team link",
TeamQRcode: "Team QR code",
Telephone: "Telephone",
TemplateBound: "Template bound to another",
TemplateName: "Template name",
TemplateNotes: "Template notes",
ThankYouForYourFeedback: "Thank you for your feedback",
THB: "THB",
TheAgeOfTheChild_x: "The age of the child %a$",
TheApplicationFormYouHaveSelectedNotSupportRoundTripSwitching: "The application form you have selected does not support round trip switching",
TheCarMayChangePleaseCheckAgain: "The car information may have changed, please check again",
TheCarryingRegulationsFor: "The carrying regulations for lithium batteries and power banks,",
TheCityHasBeenSelected: "The city has been selected",
TheCodeValueIsEmpty: "Code value empty",
TheCurDatCanExcTheNexTriDatPleGoBacToTheHomPagToModify: "The current date cannot exceed the next trip date, please go back to the homepage to modify",
TheCurrentDeviceIsLockedOrNotBiometricEnabled: "The current device is locked or does not have biometric authentication enabled",
TheCurrentDeviceSupportsFaceID: "The current device supports Face ID. Would you like to enable it?",
TheCurrentDeviceSupportsTouchID: "The current device supports Touch ID. Would you like to enable it?",
TheCurrentLogin: "The current login is",
TheCurrentNetworkIsNotAvailable: "The current network is not available",
TheCurrentNetworkIsNotAvailable_1: "The current network is not available. Please check the connection status.",
TheCurrentOrderHasBeenSplitIntoMultipleOrdersPleaseGoToTheOrderListToView: "The current order has been split into multiple orders. Please go to the order list to view.",
TheCurrentOrderIsAPersonalPaymentOrder: "The current order is a personal payment order. Please make the payment as soon as the approval is granted to proceed with ticketing.",
TheCurrentOrderRequiresPersonalPayment1: "The current order requires personal payment. Ticketing can only be done once the payment is completed.",
TheCurrentOrderRequiresPersonalPayment2: "The current order requires personal payment. Confirmation can only be done once the payment is completed.",
TheCurrentPointsAreNotEnoughToExchangeThisItem: "The current points are not enough to exchange for this item.",
TheCurrentServiceNeedsToUseLocation: "The current service needs to use location. Please go to settings and enable the location.",
TheCurrentTimeIsNotAvailableForAppointments: "The current time is not available for appointments.",
TheDateOfDepartureCannotBeEarlierThanThePreviousProcess_x: "The departure date of %a$ cannot be earlier than the previous process.",
TheDriverIsGoingToTheDestination: "The driver is going to the destination.",
TheDriverWillContactThePassenger: "The driver will contact the passenger.",
TheEmailNotificationHasBeenTurnedOn: "Email notification has been turned on. Email address cannot be empty!",
TheFlightCombinationYouSelectedCannotBeReserved: "The flight combination you selected cannot be reserved. Please place separate orders.",
TheFlightHasInsufficientTickets: "The flight has insufficient tickets. Please select another flight.",
TheFollowingTimesAreLocalTime: "The following times are local time.",
TheHotelPicturesAreForReferenceOnly: "Hotel pictures are for reference only. Please refer to the actual accommodation",
TheIdentityOfPassengerXHasNotBeenVerified_x: "The identity of passenger %a$ has not been verified.",
TheItineraryYouSelectedNotMatchTheApplicationForm: "The itinerary you selected does not match the application form.",
TheLengthOfTheViolationOfTheTravelRankCannotExceedXWords: "The length of the violation of the travel rank cannot exceed 50 words.",
TheLoadingFailedTryAgain: "Loading failed. Please try again.",
TheLowestLogicalFareIsSuggestedAccordingToTheTravelPolicyOfYourCompany: "According to the travel policy of your company, it is recommended to book the lowest logical fare.",
TheLowestPriceForDomesticAirTicketIsNotSelected: "The lowest price for the domestic air ticket is not selected. Reasons for the selection are required.",
Theme: "Featuring",
TheNetworkSignalIsPoor: "The network signal is poor. Please try again later.",
TheNewPassword: "New password",
TheNewVersionIsOnlinePleaseUpdateToTheLatestVersion: "The new version is online. Please update to the latest version.",
TheNextPageIsNotUnderOuControl_x: "The next page is not under our control. Please use it with caution. %a$",
TheNumberOfTheTicket: "Ticket number",
TheOrderConfirmedAndProofOfSuccessfulBookingFollowingMethods: "The order has been confirmed and a proof of booking success can be obtained in the following ways.",
TheOrderIncludesAirfare: "By clicking Next, you acknowledge and agree to the following regarding",
TheOrderIncludesRescheduledPassengers: "There are passengers in this order who have rescheduled.",
TheOrderIsForIndividualPaymentOrders: "This order is for individual payment. For rescheduling, please contact your travel consultant or call: 4006-123-123.",
TheOrderIssuedAndProofOfSuccessfulBookingFollowingMethods: "The order has been issued and the ticket confirmation can be obtained through the following methods.",
TheOrderIssuedAndProofOfSuccessfulBookingFollowingMethodsForTrain: "The order has been issued, and the train ticket confirmation can be obtained through the following methods.",
ThePeriodOfValidity: "Period of validity",
ThePeriodOfValidityTips: "Example: June 2025, please enter 0625",
ThePhoneNumberIsTheSameAsTheCurrentlyBoundMobilePhoneNumber: "The phone number is the same as the currently bound mobile phone number.",
TheRailwayBureauStipulatesThatTicketsMustBeSoldByRealName: "The railway bureau stipulates that tickets must be sold with real names.",
ThereAreOtherFlightsAtTheSameTime: "There are other flights at the same time. Do you want to continue?",
TheReasonForExceedingTheStandardCannotExceedXWords: "The reason for exceeding the standard cannot exceed 50 words.",
TheRefundFeeIsBeingApprovalByTheFlightCompany: "The refund fee is being approved by the flight company.",
TheRefundFeeIsSubjectToTheReviewOfTheAirlineCompany: "The refund fee is subject to the review of the airline company.",
ThereMeals: "Meal(s)",
ThereMustBeNoContactWithTheSamePhoneNumber: "There must be no contacts with the same phone number.",
ThereNoAvailableRoomTypesForBooking: "No available room types",
ThereNoAvailableRoomTypesForBookingForChangeFilter: "No room types found that meet the criteria, you can try changing the filter~",
TheRescheduleType: "Reschedule type",
TheReschedulingFeeIsBeingApprovalByTheFlightCompany: "The rescheduling fee is being approved by the flight company.",
TheReschedulingFeeIsSubjectToTheReviewOfTheAirline: "The rescheduling fee is subject to the review of the airline.",
TheRoomerIDNumberCannotBeRepeated: "Check in ID number can't be repeated",
TheRoomIsReservedAllNight: "Room reserved all night, may need to wait for room availability if arriving before 14:00",
TheSpecificPriceIsSubjectToTheReview: "*The specific price is subject to review by 12306",
TheSpecificRefundAndChangeRulesShallBeSubjectToTheDateOfTheTicketIssue: "Specific refund and change rules are subject to airline review!",
TheSubmissionFailedPleaseTryAgainLater: "Submission failed, please try again later!",
TheSysteIsBusyPleaseReOperate: "The system is busy, please re-operate!",
TheSystemIsBusyPleaseTryAgainLater: "The system is busy, please try again later!",
TheTakeoffAndLandingLocalTime: "The takeoff and landing times of flights are all in local time",
TheTicketYouChooseIsVeryCloseToTheDepartureTime: "The train you have chosen is very close to the departure time. Please ensure that you have enough time to complete safety checks, real name verification, and ticket checking procedures. As the departure is approaching, there is a risk of ticket failure. Please pay attention to the confirmation information to avoid delaying your journey.",
TheTicketYouChooseIsVeryCloseToTheDepartureTimeChange: "The train you have chosen is very close to the departure time. Please ensure that you have enough time to complete safety checks, real name verification, and ticket checking procedures. As the departure is approaching, there is a risk of failed rescheduling. Please pay attention to the confirmation information to avoid delaying your journey.",
TheValidityPeriodHasExpired: "The validity period has expired!",
TheValidityPeriodHasExpiredByReplace: "Your document has expired. Please replace it with another document or make a new reservation after an extension",
TheVerificationCodeHasBeenSentTo_1_x: "The verification code has been sent to %a$",
TheVerificationCodeHasBeenSentTo_x: "The verification code has been sent to %a$",
ThirdPartyBinding: "Third-party Binding",
ThirdPartyRelation: "Account association",
ThirdStep: "Third step",
ThisInformationIsForReferenceOnlyIfThereIsAnyChangePleaseReferToTheStationAnnouncement: "*This information is for reference only. In case of any changes, please refer to the station announcement.",
ThisOrderUseLxd_x: "You can use %a$ Lxd for this order",
ThJourneyIsXAndIsExpectedToTakeXMinutes_x_x: "The journey is %a$ kilometers and is expected to take %b$ minutes",
TicketAmount: "Ticket amount",
TicketBarrier: "Ticket check",
TicketBarrierWaitingPlace: "Ticket Barrier/Waiting Area",
TicketBookingAuthority: "Domestic ticket rank",
TicketChangeRefund: "Change/Refund",
TicketFare: "Ticket price",
TicketingMail: "Ticketing email",
TicketingSMS: "Ticketing SMS",
TicketingSucceed: "Ticketing successful",
TicketNumber: "Ticket number",
TicketPricesHaveChanged: "Price change",
TicketRestrictions: "Refund/Change policy",
TicketRestrictions_x: "Refund/Change %a$",
TicketRestrictions_x_x: "Refund/Change %a$%b$",
TicketRestrictionsArrow: "Refund/Change policy >",
TicketRestrictionsCancellationBaggage: "Refund/Change policy baggage",
TicketRestrictionsCancellationBaggageArrow: "Refund/Change policy baggage >",
TicketRestrictionsRule: "Refund/Change rules",
Tickets: " ticket(s)",
Tickets_1: "/ticket",
Tickets_x: "%a$ticket(s)",
TigerRobot: "Tiger robot",
Time: "Redemption Time",
Time_1: "time",
TimeArrival: "Arrival",
TimeConsuming: "Time",
TimeConsumingLeast: "Time(Least)",
TimeConsumingMost: "Time(Most)",
TimeDeparture: "Depart",
TimeHighToLow: "Night-Morning",
TimeLowToHigh: "Morning-Night",
TimeRange: "Time range",
Tips: "Tips",
Tips_1: "[Tip]",
Tips_2: "Helpful Tips",
TipsColon: "Warm reminder：",
TMS: "Hengshun Hotel TMS",
TMSOnline: "Hengshun Hotel TMS Online",
To: "-",
ToApply: "Apply",
ToBeConfirmed: "To be confirmed",
ToBetterYourBusinessTripExperience: "To Enhance Your Business Trip Experience with Better Hotels",
ToChoose: "Choose",
Today: "Today",
ToEnsureThatThePassengerInformationIsCorrect: "To Ensure That the Passenger Information Is Correct, Please Verify as Instructed",
ToEnsureTheSecurityOfTheAccount: "To Ensure the Security of Your Account, Please Confirm that It's You",
ToGuarantee: "To guarantee",
ToGuaranteeAmount: "Guarantee amount",
Tomorrow: "Tomorrow",
Tomorrow_1: "The day after",
Tomorrow_x: "Tmr 0%a$:00",
Top5Cities: "Top 5 cities",
Top5CostCenterExpense: "Top 5 cost center expenses",
Top5DeptsExpenses: "Top 5 department expenses analysis",
Top5DestinationsbyExpense: "Top 5 destinations by expenses",
TopFiveModels: "Top five models",
ToSetUp: "Setting",
Total: "Total",
Total_1: "Total:",
Total_2: "Total: ",
Total_3: "Total price",
Total_x: "Total %a$",
TotalAmount: "Total amount",
TotalAmount_1: "Total amount",
TotalAmount_x: "Total amount %a$",
TotalAmountOfCarUsed: "Total amount of car used",
TotalAmountOfTrainTicket: "Total amount of train tickets",
TotalAmountPendingApproval: "Total amount to be reviewed",
TotalBudget: "General budget",
TotalBudgetAmount: "Total budget amount",
TotalBudgetRMB: "Total budget (CNY)",
TotalCabinInfo_x: "%a$ flight(s) in total",
TotalDuration: "Duration",
TotalDuration_x: "Total %a$",
TotalMileage: "Total mileage",
TotalMissedSaving: "Total missed savings",
TotalNights: "%a$ night(s)",
TotalNights_1: "%a$ night(s)",
TotalNumberOfPeople: "Number",
TotalOfDay_x: "%a$ day(s)",
TotalOrderAmount: "Total order amount",
TotalPiece_x: "%a$ Pieces",
TotalPrice: "Total",
TotalPrice_x: "Total %a$",
TotalPriceIncludingTax: "Including tax",
TotalPriceNoTax: "Total(No tax)",
TotalPriceNoTax_1: "Total(No tax)",
TotalPriceNoTax_2: "Total(No tax)",
TotalPriceTax: "Total(Tax)",
TotalPriceTax_1: "Total(Tax)",
TotalPriceTax_2: "Total(Tax)",
TotalSaving: "Total savings",
TotalTrip_x: "(Total %a$ trips)",
ToTravel: "To travel",
TouchIDIsNotEnabled: "Touch ID is not enabled on the current device",
TouchIDIsOnlyValidForTheMachine: "Touch ID is only valid for the current machine",
TouchIDLogin: "Touch ID login",
TourismConference: "Tourism/Conference",
TrackingNo: "Tracking No.",
TrackingNumber: "Tracking number",
Traffic: "Transportation",
Train: "Trains",
TrainAccount: "12306 Account",
TrainAccountLogin12306: "Login 12306",
TrainAccountName: "Account",
TrainAccountPwd: "Account password",
TrainAccountPwd12306: "12306 password",
TrainAccountSubmitCheck: "Submit verification",
TrainApplication: "Train application",
TrainAvailable: "Available",
TrainBookPermission: "Train booking permission",
TrainChanged: "Train information may have changed, please re-query",
TrainDetails: "Train Schedule Details",
TrainNumber: "Train number",
TrainOrders: "Train ticket orders",
TrainPassenger: "Passenger",
TrainRank: "Train rank",
TrainSnatchCode: "Alternate train",
TrainSnatchDate: "Alternate date",
TrainSnatchDeadline: "Deadline",
TrainSnatchSeats: "Alternate seats",
TrainStation: "Train station",
TrainTicket: "Trains",
TrainTicketConfirmation: "Train ticket order confirmation",
TrainTicketFare: "Train ticket fare",
TrainTicketsBooking: "Train tickets reservation",
TrainTicketSmsVerification_x_x_x: "Please inform the passenger (%a$) to send an SMS %c$ to 12306 using the phone number %b$ for verification within 30 minutes.",
TrainTicketsYouSearchedFor: "Train tickets you searched for",
TrainType: "Train type",
TrainUnavailable: "Unavailable",
Transfer: "",
Transfer_1: "T",
Transfer_1_x: "%a$T",
Transfer_2: "Transfer",
Transfer_2_x: "%a$ transfer",
Transfer_3_x: "Transfer %a$",
Transfer_Stop_x_x: "%a$ transfer %a$ stops",
Transfer_x: "Transfer %a$",
TransferToAnotherStation: "Diff Stations",
TransportationAccommodation: "Transportation and accommodation",
TransportTime: "Transportation time",
TravelApplication: "Travel application",
TravelApplicationCode: "Travel application No",
TravelBooking: "Travel booking",
TravelCard: "Frequent traveler card",
TravelControl: "Travel control",
Traveler: "Traveler",
Traveler_1: "Traveler",
Traveler_x: "Travelers: %a$",
TravelerDetails: "Traveler details",
TravelerType: "Traveler type",
TravelPolicy: "Policy",
TravelPositioning: "Travel positioning",
TravelPurpose: "Purpose",
TravelReport: "Travel report",
TravelSecurity: "Travel guarantee",
TravelStandard: "Policy",
TravelStandard_1: "My Policy",
TravelTime: "Travel time",
TravelType: "Travel type",
Trip: "Trip",
TripType: "Type of trip",
TryAgain: "Try again",
TryAgain_1: "Try again",
TWD: "TWD",
TwinBed: "Double beds",
Type: "Type",
Type_1: "Type",
TypeOfQuestionRequired: "Type of question (Required)",
TypeOfTrip: "Type of trip",
UnableToBookTheCurrentAgreementPriceProduct: "Sorry, the passenger in your order is not on the airline's whitelist, so we are unable to book products at the current agreement price.",
UnableToLocateCurrentCity: "Unable to locate current city",
Unbind: "Unbind",
UnbindSuccessfully: "Unbind successfully",
Unbound: "Unbound",
UnboundApprover: "Unbound approver",
UnboundCustomization: "Unbound customization",
UnboundEmployees: "Unbound employees",
UnboundTemplate: "Unbound template",
UnderOneYearOld: "Under 1 year old",
UnderTheCurrentCostCenterNoCostCenter: "No Sub-Cost Centers Under the Current Cost Center",
Uneditable: "Uneditable",
Unfilled: "Unfilled",
Unfinished: "Unfinished",
Unfold: "Unfold",
UnHandleAuthorization_x: "You have %a$ pending authorization(s)",
UnhookYourFingersAndCancelSend: "Unhook your fingers and cancel send",
Unit: "Unit",
Unit_1: "Unit",
Unit_1_x: "%a$",
Unit_x: " x %a$",
Unit_x_x: "%a$ x %b$",
UnitAbbreviation: "Unit abbreviation",
UnitAccount: "Unit account",
UnitEntry: "Unit entrance",
UnitLogo: "Unit logo",
UnitManagement: "Unit",
UnitName: "Unit name",
UnitNumber: "Unit Number",
UnitNumber_x: "Unit number: %a$",
UnitNumberOrUnitName: "Unit code or short name",
Unknown: "Unknown",
Unselected: "Unselected",
UnStaffAdd: "New",
Unsubmitted: "Unsubmitted",
Untie: "Untie",
UnusedTicketTitle_x: "Passenger: %a$ have unused tickets for the following itinerary, you can book the current itinerary by rescheduling, if you need to book you can click the [Go Reschedule] to do so.",
Update: "Update",
Update_1: "Update",
UpdateSearchWhenPullingMap: "Update search when pulling map",
Upgrading: "Upgrading",
UploadCertificate: "Upload the proof",
UploadedCertificate: "Uploaded proof",
UploadFile: "Upload file",
Upper: "U",
Upscale: "Upscale",
UrbanCivision: "Urban division",
USD: "USD",
UseCar: "Use car",
UsedForCheckingInAtTheHotelFrontDeskOrForProcessingDestinationVisas: "Used for checking in at the hotel front desk or for processing destination visas",
UseLxd: "Use",
UseLxd_x: "Up to %a$ Lxd can be used",
UserCanceled: "User canceled",
UserName: "user ID",
UserNameEmailMobile: "12306 Username/Email/Mobile",
UseRules: "Usage rules",
UseTheCar_x: "%a$ use car",
UseTheCarImmediately: "Use the car immediately",
UsingTheLastestVersion: "You are already using the latest version",
ValidationCertificateInformationNotPassed_x: "%a$ certificate information validation failed",
ValidPeriod: "Valid until",
VehicleDisinfection: "Vehicle disinfection",
VehicleTrack: "Vehicle track",
VerificationCode: "Verification code",
VerificationCodeAgain: "Again",
VerificationCodeAgain_1: "Retrieve again",
VerificationCodeAgain_1_x: "The verification code has been sent. Please check your SMS. If you don't receive it, you can %a$.",
VerificationCodeAgain_x: "The verification code has been sent. Please check your SMS. If you don't receive it, you can %a$ to obtain it again.",
VerificationCodeCheck: "Enter SMS verification code to complete verification",
VerificationCodeCheckFail: "Verification code check failed",
VerificationCodeCoped: "Verification code copied",
VerificationCodeCopiedSuccessful: "Verification code copied successfully",
VerificationCodeFailedToSend: "Failed to send verification code",
VerificationCodeGet: "Get verification code",
VerificationCodeHasBeenSent: "Verification code has been sent",
VerificationSucceeded: "Verification succeeded, passed!",
VerificationSuccessful: "Verification Successful",
VerifiedPhoneNumber: "Verified mobile number",
VerifyUsingMail: "Verify with Email",
VerifyUsingPhoneNumber: "Verification using mobile phone number",
VersionNumber: "version",
VersionUpdate: "Version update",
ViewAllStaff: "Can view all employees",
ViewBookPermissions: "View/Book Permissions",
ViewComments: "Comments",
ViewConfirmNumber: "View Confirmation",
ViewDesignated_x: "Can view specified %a$",
ViewDesignatedDepart: "Can view specified departments",
ViewDesignatedStaff: "Can view designated employees",
ViewDirectTicket: "View Direct Tickets",
ViewElectronicPolicy: "View policy",
ViewItineraryTrack: "View itinerary track",
ViewMoreTransferSchemes: "View More Transfer Schemes",
ViewOnlySelf: "Only view myself",
ViewOrder: "View",
ViewOtherPrices_x: "View %a$ other prices",
ViewPermissions: "View permissions",
ViewRoomType: "View room type",
ViewTheBigMap: "View big map",
ViewTheLowestPrice: "View lowest price flights",
ViewTravelApplicationForm: "View Travel Application Form",
ViolationOfBusTravelRank: "Reasons for violation of bus ticket travel standards",
ViolationOfCarTravelRank: "Violation of car travel standards, reasons to choose.",
ViolationOfHomsomTravelStandardsNotSelectable: "Violation of your company's travel standards, not selectable",
ViolationOfHomsomTravelStandardsSelectedReason: "Violation of your company's travel standards, reasons need to be selected:",
ViolationOfHotelTravelRank: "Violation of hotel travel standards, reasons to choose.",
ViolationOfMealsTravelRank: "Violation of meals travel standards, reasons to choose.",
ViolationOfRank: "Violation of rank",
ViolationOfRankDetail: "Violation of rank details",
ViolationOfRankReason_x: "Violation of standards reason: %a$",
ViolationOfTrainTravelRank: "Violation of train travel standards, reasons to choose.",
ViolationOfTravelRank: "Violation of travel standards",
VoidApplicationForm: "Void Application Form",
VoidedFailed: "Void failed",
VoidedSuccessfully: "Voided successfully",
VoluntaryRefund: "Voluntary refund",
VoluntaryRefundDesc: "Voluntary refund (subject to airline's refund and change rules)",
VoluntaryRefundExplain: "Voluntary ticket refund instructions",
VoluntaryReschedule: "Voluntary reschedule",
VoluntaryRescheduleDesc: "Voluntary reschedule (subject to airline's refund and change rules)",
VoluntaryRescheduleExplain: "Voluntary rescheduling explanation",
Voucher: "Voucher",
WaitingForConfirmation: "Waiting for confirmation",
WarmTipsWhitelistedEmployees: "Warm Tips: No approval is required for whitelisted employees.",
WarmTipTheModificationIsNotSupportedForTheTimeBeing: "Warm Tip: Modification is not supported at the moment. Please contact your dedicated consultant for any modifications.",
Wechat: "WeChat",
WeChat_Limit_1: "Due to mini program restrictions, please perform this operation on the Hengshun Business Travel APP or website",
WechatByAccount: "Enterprise WeChat Account",
WechatByUser: "Enterprise WeChat Employee Binding",
WechatIsNotInstalled: "Wechat is not installed",
WechatNotSupportDDPleaseUseAppOrH5: "WeChat mini program does not support Didi car, please use app or h5 to book",
WechatNotSupportOnlineCheckInPleaseOpenOnPcOrApp: "WeChat mini program does not support online check-in, please use pc, app or h5 to open",
WechatPay: "WeChat Pay",
WeekFri: "Fri",
WeekFri_1: "Fri",
WeekMon: "Mon",
WeekMon_1: "Mon",
WeekSat: "Sat",
WeekSat_1: "Sat",
WeekSun: "Sun",
WeekSun_1: "Sun",
WeekThur: "Thu",
WeekThur_1: "Thur",
WeekTues: "Tue",
WeekTues_1: "Tues",
WeekWed: "Wed",
WeekWed_1: "Wed",
Welcome: "Hello",
Welcome_x: "%a$，Hello！",
WelcomeToHOMSOM: "Welcome to HOMSOM",
WelcomeToHumanCustomerService: "Welcome to human customer service",
WelcomeToLogin: "Hello, welcome to login",
WelcomeToLoginHomsom: "Welcome to log in to Homsom",
WhetherToAcceptTheSeatlessTicket: "Whether to accept a seatless ticket when the selected seat is unavailable?",
WhetherToConfirmTheDeletionOfThisFavoriteContact: "Confirm the deletion of this favorite contact?",
WhetherToConfirmThePasswordChange: "Confirm the password change?",
WhetherToConfirmTheRefund: "Confirm the refund?",
WhetherToSendARefundEmail: "Whether to send a refund email",
WhetherToSendARefundEmail_1: "Send a refund email",
WhetherToSendARefundSuccessfulMail: "Whether to send a refund successful email",
WhetherToSendARefundSuccessfulMail_1: "Send a refund successful email",
WhetherToSendARefundSuccessfulSMS: "Whether to send a refund successful SMS",
WhetherToSendARefundSuccessfulSMS_1: "Send a refund successful SMS",
WholeRoute_x: "Whole %a$",
Window: "Window",
Window_1: "Window",
WindowType: "Window",
Withdrawal: "Withdrawal",
Work: "work",
WriteReasonCode: "Write ReasonCode",
XFormatIsIncorrect_x: "Incorrect format for %a$",
XHoursBeforeAndAfterTheLowestPrice_x: "Lowest price %a$ hours before and after",
XIDIsWrong_x: "ID verification failed (%a$)",
XIsUnderXyearsOld_x: "%a$ is under 12 years old",
XOfFlight_x: "%a$ of flight",
XPeoplePerRoom_x: "room capacity %a$ people",
XTrips_x: "%a$ trains in total",
Year: "Year",
Year_x: "%a$",
YearOnYear: "Y-on-Y",
Yesterday: "Day before",
You: "You",
YouAlsoHaveUnselectedPassengers: "You also have unselected passengers, please select seats before submitting the order!",
YouCanDoTheFollowing: "You can do the following",
YouCannotSelectContactsWithTheSamePhoneNumberAtTheSameTime: "You cannot select contacts with the same phone number at the same time",
YouCanOnlyGetSMSVerificationCodesFor3MobilePhoneChecksPerDay: "*Each user can only receive 3 mobile verification SMS verification codes per day",
YouCanOpenItInMySettingSecurityManager: "You can open it in My-Settings-Security Manager",
YouCanUseFaceIDVerification: "You can use Face ID verification to quickly complete login",
YouCanUseTouchIDVerification: "You can use Touch ID verification to quickly complete login",
YouHaveACarOrderInProgress: "You have a car order in progress",
YouHaveToDoFaceRecognitionAlone: "You have to do face recognition alone",
YouHaveUnselectedPassengersPleaseSubmitAfterSeatSelection: "You have unselected passengers, please submit after seat selection",
YourCompanyAllowsYouToPayTheExtraAmountForAnOutPolicyHotelByCreditCardWeChatOrAlipay: "Your company allows you to pay the extra amount for an out-policy hotel by credit card, WeChat, or Alipay",
YourCurrentLoginAccountNeedsMobilePhoneNumberVerification: "Your current login account needs mobile phone number verification",
YourDateOfBirthGenderNationalityAndIDCardDoNotMatch: "Your date of birth, gender, nationality, and ID card do not match, and they have been corrected for you",
YourExclusiveTravelConsultant: "Your exclusive travel consultant",
YourHotelGroupMembershipCard: "Your hotel group membership card",
YourOrderHasNotBeenCompletedOut: "Your order has not been completed. Are you sure you want to leave the current page?",
YourOrderHasNotBeenFilledOut: "Your order has not been filled out. Are you sure you want to leave the current page?",
YourPassengersAreFullToDelete12306_x: "Your 12306 passengers are full. Deleting %a$ passengers can complete this ticket purchase",
YourPendingApplicationForm_x: "You have a pending application form (%a$)",
YourPhoneNumberHasNotBeenRegisteredYet: "Your phone number has not been registered yet",
YourStayIsTooLongTheFlightAndPriceInformationMayChangeAndTheLatestPrice: "Your stay is too long. Flight and price information may change. We are currently checking the latest price for you",
YourTravelCard: "Your frequent travel card",
YouWill: "You will depart from",
YuanStart_x: "From %a$ ",
}